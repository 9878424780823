import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as PhoneLogo } from './icons/phone.svg';
import { ReactComponent as UserLogo } from './icons/user.svg';
import { ReactComponent as AddressLogo } from './icons/address.svg';
import { ReactComponent as EditIcon } from './icons/edit_icon.svg';

const PaymentMethod = ({ paymentMethod }) => {
  
  const address = paymentMethod.billing_details.address;

  return (
    <Container>
      <RowOne>
        <CreditCardNumber>{`${paymentMethod.card.brand} ${paymentMethod.card.last4}`}</CreditCardNumber>
        {/* <StripeLink href={'https://connect.stripe.com/express_login'} target={'_blank'}>
          Edit in Stripe
          <EditIconHolder>
            <EditIcon/>
          </EditIconHolder>
        </StripeLink> */}
      </RowOne>
      <RowTwo>
        <IconTextWrapper>
          <UserLogo />
          <IconText>{paymentMethod.billing_details.name}</IconText>
        </IconTextWrapper>
        <IconTextWrapper>
          <PhoneLogo />
          <IconText>{paymentMethod.billing_details.phone }</IconText>
        </IconTextWrapper>
        <IconTextWrapper>
          <AddressLogo />
          <IconText>{`${address.line1}, ${address.city}, ${address.postal_code}, ${address.country }`}</IconText>
        </IconTextWrapper>
      </RowTwo>
    </Container>
  );
};


const StripeLink = styled.a`
  justify-self: right;
  color: var(--purple-purple, #beaeff);
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: auto;
  display: flex;
  padding-right: 1rem;
  text-decoration: none;
  @media (max-width: 720px) {
   display: none;
  }
`;

const EditIconHolder = styled.div`
  color: white;
  display: flex;
  margin-left: 1rem;
  @media (max-width: 720px) {
    
  }
`;


const Container = styled.div`
  border-radius: 5px;
  background: #2b2a38;
  height: 168px;
  margin-top: 2rem;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 1rem;
  @media (max-width: 720px) {
   height: fit-content;
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  padding-left: 2rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 720px) {
   padding-left: 0px;
  }
`;

const IconText = styled.p`
  color: var(--text-light-grey, #ced1d9);
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1rem;
  @media (max-width: 720px) {
   font-size: 1.375rem;
  }
`;

const CreditCardNumber = styled.p`
  color: var(--text-primary, #fbfcff);
  font-family: Barlow;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding-left: 2rem;
  
  @media (max-width: 720px) {
   padding-left: 0rem;
  }
`;

const RowOne = styled.div`
  display: flex;
  align-items: center;
  /* background: red; */
  height: 50%;
  box-sizing: border-box;
  @media (max-width: 720px) {
   /* background-color: red; */
   justify-content: space-between;
  }
`;

const RowTwo = styled.div`
  display: flex;
  align-items: center;
  /* background: red; */
  height: 50%;
  box-sizing: border-box;
  @media (max-width: 720px) {
   flex-direction: column;
   align-items: flex-start;
  }
`;

export default PaymentMethod;
