import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import LandingHeader from './LandingHeader';
import HeroLogoImage from './IntraverseHomeImages/HeroLogoIntraverse.png';
import scrollDownArrow from './IntraverseHomeImages/scrollDownArrow.png';
import DefineFutureText from './IntraverseHomeImages/DefineFutureText.png';
import JumpInCTA from './IntraverseHomeImages/JumpInCTA.png';

const IntraverseHeroBanner = () => {
  const navigate = useNavigate();

  return (
    <>
      <LandingHeader />
      <VideoContainer>
        <video
          style={{
            zIndex: '-500',
            height: '100vh',
            width: '100vw',
            objectFit: 'cover',
            position: 'absolute',
            top: '0',
            left: '0',
          }}
          controls={false}
          muted
          autoPlay
          loop
        >
          <source src={'https://intraverse-web-client-assets.s3.amazonaws.com/Hero_Placeholder.mp4'} type="video/mp4" />
        </video>
        <OverlayMask />
        <HeroBackgroundOverlay />
        <HeroContent>
          <HeroLogo src={HeroLogoImage} alt="Hero Logo Intraverse" />
          <HeroSubtitle>Explore, create, and connect in a digital world without boundaries.</HeroSubtitle>
          <JumpInButtonImg onClick={() => navigate('/login')}>
            <JumpInButton src={JumpInCTA} alt="Button" />
          </JumpInButtonImg>
          <ScrollText>
            <p>SCROLL</p>
            <ScrollDownArrowImg src={scrollDownArrow} alt={'Scroll down arrow'} />
          </ScrollText>
        </HeroContent>
        <DefineFuture src={DefineFutureText} alt="DefineFuture" />
      </VideoContainer>
    </>
  );
};
export default IntraverseHeroBanner;

const HeroBackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -200;
  background-color: #482b9b;
  mix-blend-mode: multiply;
  pointer-events: none;
`;
const OverlayMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -250;
  opacity: 0.9;
  background: linear-gradient(
    270deg,
    rgba(217, 217, 217, 0.25) 0%,
    rgba(217, 217, 217, 0.75) 54.65%,
    rgba(217, 217, 217, 0.25) 99.97%
  );
  mix-blend-mode: multiply;
  pointer-events: none;
`;

const VideoContainer = styled.div`
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* z-index: -60; */
`;

const JumpInButtonImg = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  margin: 3rem;
  /* z-index: 4000; */
`;

const HeroContent = styled.div`
  /* z-index: 7100; */
  display: flex;
  width: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 95%;
  }
`;
const HeroLogo = styled.img`
  height: 110px;

  @media (max-width: 768px) {
    height: 65px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 65px;
    margin-top: 3rem;
  }
`;
const DefineFuture = styled.img`
  width: 95vw;
  position: absolute;
  bottom: 0px;
  z-index: 1;
`;
const HeroSubtitle = styled.h3`
  color: #fbfcff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 1.7rem;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.3rem;
  }
`;
const ScrollDownArrowImg = styled.img`
  padding-top: 0.5rem;

  @media (max-width: 768px) {
    height: 40px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 30px;
  }
`;
const ScrollText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 10%;
  & > p {
    color: #beaeff;
    font-family: 'Barlow', sans-serif;
    font-size: 1.1rem;
    font-weight: bolder;
    letter-spacing: 2px;
    text-align: center;
  }

  @media (max-width: 768px) {
    bottom: 7%;
    & > p {
      font-size: 0.8rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    bottom: 6%;
    & > p {
      font-size: 0.9rem;
    }
  }
`;
const JumpInButton = styled.img`
  height: 60px;
  /* z-index: 6000; */
  pointer-events: none;

  @media (max-width: 1024px) {
    margin: 2rem;
    height: 55px;
  }
`;
