import { API_GET } from './API_FETCH';

export async function fetchVanityLinkData(pathId) {
  try {
    const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/vanity_link_data/${pathId}`);

    if (response) {
      return response;
    }
  } catch (error) {
    console.error('Error grabbing vanity link:', error);
    return null;
  }
}

export async function fetchVanityLinkByRoomId(roomId) {
  try {
    const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/vanity_links/room/${roomId}`);

    if (response.status === 200 && response) {
      return response;
    }

    return null;
  } catch (error) {
    console.error('Error grabbing vanity link:', error);
    return null;
  }
}
