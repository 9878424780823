import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import DeclineBtn from '../../assets/images/Decline.png';
import AcceptBtn from '../../assets/images/Accept.png';
import { clearAllLocalStorage, setToLocalStorage } from '../../hooks/useLocalStorage';

function GenericTCModal({ vanityLinkData, setAcceptedTerms, setCheckboxStatus }) {
  const [checked, setChecked] = useState(true);
  const onTermsAccepted = () => {
    setAcceptedTerms(true);
    setCheckboxStatus(checked);
    setToLocalStorage('fullscreen', checked);
  };
  if (vanityLinkData === undefined) {
    return null;
  }
  const renderHTML = (htmlString) => ({ __html: htmlString });
  return (
    <TermsModalWrapper>
      <TermsModalContainer>
        <TermsModalTitle>Terms & Conditions</TermsModalTitle>
        <TermsModalText dangerouslySetInnerHTML={{ __html: vanityLinkData.termsAndConditionsText1 }} />
        <TermsModalText>
          {vanityLinkData.termsAndConditionsText2}
          <YellowText href={vanityLinkData.termsAndConditionsLinkTZLink} target="_blank" rel="noopener noreferrer">
            {vanityLinkData.tzName}{' '}
          </YellowText>
          and{' '}
          <YellowText href={vanityLinkData.termsAndConditionsClientLink} target="_blank" rel="noopener noreferrer">
            {' '}
            {vanityLinkData.clientName}.
          </YellowText>
        </TermsModalText>
        <TermsModalButtons>
          <TermsModalButton
            alt="Accept"
            src={AcceptBtn}
            onClick={() => {
              onTermsAccepted(checked);
            }}
          />
          <TermsModalButton
            alt="Decline"
            src={DeclineBtn}
            onClick={async () => {
              await clearAllLocalStorage();
              window.location.reload();
            }}
          />
        </TermsModalButtons>
        <TermsModalCheckbox>
          <CheckboxFullscreen type="checkbox" onChange={() => setChecked((value) => !value)} checked={checked} />
          <TermsCheckboxText>Start in full-screen mode</TermsCheckboxText>
        </TermsModalCheckbox>
      </TermsModalContainer>
    </TermsModalWrapper>
  );
}

export default GenericTCModal;

const TermsModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
`;

const TermsModalContainer = styled.div`
  background-color: #1b1b23bf;
  padding: 40px;
  text-align: left;
  max-width: 400px;
  border-radius: 4px;
  border: 4px solid;
  border-color: #beaeff33;
  margin: 20px;

  @media (max-width: 425px) {
    max-width: 300px;
    padding: 20px;
  }

  @media (max-height: 425px) and (orientation: landscape) {
    max-width: 400px;
    padding: 10px;
  }
`;

const TermsModalTitle = styled.h2`
  font-size: 22px;
  margin-bottom: 30px;
  color: #ffffff;
  text-align: center;
  font-family: 'Signika Negative', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  @media (max-width: 425px) {
    font-size: 18px;
    margin-bottom: 20px;
  }

  @media (max-height: 425px) and (orientation: landscape) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const TermsModalText = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
  color: #ffffff;
  font-family: 'Barlow-Regular', Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;

  & > strong {
    color: #ffe664;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-height: 425px) and (orientation: landscape) {
    font-size: 13px;
    margin-bottom: 12px;
  }
`;

const TermsCheckboxText = styled.p`
  font-size: 14px;
  margin: 0px 10px;
  padding-top: 2px;
  color: #ffffff;
  font-family: 'Barlow-Regular', Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;

  & > strong {
    color: #ffe664;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-height: 425px) and (orientation: landscape) {
    font-size: 13px;
    margin-bottom: 12px;
  }
`;

const CheckboxFullscreen = styled.input`
  margin-top: 3px;
  background-color: green;
  accent-color: #c0b3f6;
`;

const TermsModalButtons = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
`;

const TermsModalCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  color: white;
  margin-top: 0px;
  padding-top: 5px;
`;

const TermsModalButton = styled.img`
  height: 50px;
  cursor: pointer;
`;

const YellowText = styled.a`
  color: #ffe663;
  font-weight: 700;
  text-decoration: none;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
