import styled from '@emotion/styled';
import { stripeCountries } from './stripeCountries';


const BillingDetailsFields = () => {
 return (
   <BillingDetailsContainer>
     <Subtitle>Billing Address</Subtitle>
     <Label>Name</Label>
     <Input
       name="name"
       label="Name"
       type="text"
       placeholder="Jane Doe"
       required
     />
     <Label>Email</Label>
     <Input
       name="email"
       label="Email"
       type="email"
       placeholder="jane.doe@example.com"
       required
     />
     <Label>Phone</Label>
     <Input
       name="phone"
       label="Phone"
       type="phone"
       placeholder="+14444444444"
       required
     />
     <Label>Address</Label>
     <Input
       name="address"
       label="Address"
       type="text"
       placeholder="185 Berry St. Suite 550"
       required
     />
     <Label>City</Label>
     <Input
       name="city"
       label="City"
       type="text"
       placeholder="San Francisco"
       required
     />
     <Label>State</Label>
     <Input
       name="state"
       label="State"
       type="text"
       placeholder="California"
       required
     />
     <Label>Country</Label>
     <Select
       name="country"
       label="Country"
       type="text"
       placeholder="USA"
       required
     >
      {
      stripeCountries.map(country => {
       return <option key={`${country.code}-${country.country}`} value={country.code}>{country.country}</option>
      }
      )}
     </Select>
     <Label>Zip Code</Label>
     <Input
       name="zip"
       label="ZIP"
       type="text"
       placeholder="94103"
       required
     />
   </BillingDetailsContainer>
 );
};

const BillingDetailsContainer = styled.div`
 max-width: 100vw;
`

const Input = styled.input`
  flex: 1;
  background: #1b1b23;
  border: 2px solid #504c5c;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0rem;
  font-size: 18px;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  width: 100%;
  height: 70px;

  ::placeholder {
    color: #9da0a7;
    opacity: 50%;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
     width: 90%;
  }
`;

const Subtitle = styled.p`
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--text-light-grey, #CED1D9);
  text-align: start;
  font-family: Barlow;
  font-size: 22px;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const Select = styled.select`
  flex: 1;
  background: #1b1b23;
  border: 2px solid #504c5c;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0rem;
  font-size: 18px;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  width: 100%;
  height: 70px;

  ::placeholder {
    color: #9da0a7;
    opacity: 50%;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
     width: 90%;
  }
`;

const Label = styled.label`
  flex: 1;
  display: block;
  background: #1b1b23;
  outline: none;
  padding-left: 0.5rem;
  margin: 0.5rem 0rem;
  font-size: 18px;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  width: 90%;
  background-color: transparent;
`;

export default BillingDetailsFields;