import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { UserContext, InitializeFiltersContext } from './context/UserContext';
import '@fontsource/barlow';

const queryClient = new QueryClient();

export default function App() {
  return (
    <UserContext.Provider value={InitializeFiltersContext()}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </ThemeProvider>
      </QueryClientProvider>
    </UserContext.Provider>
  );
}
