import { useState } from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
import StatusModal from '../../../components/common/Modal/StatusModal';
import { deleteUser, blockUser, unblockUser } from '../../../api/UsersController';
import Alert from "../../../components/common/Alert/Alert";
import Iconify from '../../../components/Iconify';  

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleAction: PropTypes.func,
};

export default function UserListToolbar({ selected, numSelected, filterName, onFilterName, role, handleAction }) {
  const userRole = useSelector((state) => state?.user?.role);
  const token = useSelector((state) => state?.user?.token);
  const [statusModal, setStatusModal] = useState(false);
  const [actionType, setActionType] = useState('')
  const [blockAlertMsg, setBlockAlertMsg] = useState(null);
  const [blockAlertStatus, setBlockAlertStatus] = useState(null);
  const [unblockAlertMsg, setUnblockAlertMsg] = useState(null);
  const [unblockAlertStatus, setUnblockAlertStatus] = useState(null);
  const [deleteUserMsg, setDeleteUserMsg] = useState(null);
  const [deleteUserStatus, setDeleteUserStatus] = useState(null);
  const [tempMsg, setTempMsg] = useState('');
  const [blockStatus, setBlockStatus] = useState(false);

  async function myAsyncOperation(userId, status, reason, firstName) {
    setBlockStatus(status);
    if (actionType === 'block') {
      if(status !== true){
        const responseBlock = await blockUser(userId, token, reason);
        setBlockAlertStatus(responseBlock.status);
        if(responseBlock.status === 201){
          let arrName = [];
          selected.forEach(element => {
            if(element.status !== true){
              arrName = selected.map(element => element.firstName);
            }
          });
          setBlockAlertMsg(`${arrName} blocked successfully.`);          
        }else{
          setBlockAlertMsg(responseBlock.message);
        }
      }
    }else if (actionType === 'unblock') {
      if(status !== false){
        const respUnblock = await unblockUser(userId, token);
        setUnblockAlertStatus(respUnblock.status);
        if(respUnblock.status === 200){
          let arrName1 = [];
          selected.forEach(element => {
            if(element.status !== false){
              arrName1 = selected.map(element => element.firstName);
            }
          });
          setUnblockAlertMsg(`${arrName1} unblocked successfully.`);
        }else{
          setUnblockAlertMsg(respUnblock.message);
        }
      }
    }else{
      const result = await deleteUser(token, userId);
      setDeleteUserStatus(result.status);
      if(result.status === 200){
        let arrNameDel = [];
        selected.forEach(element => {
            arrNameDel = selected.map(element => element.firstName);
        });
        setDeleteUserMsg(`${arrNameDel} deleted successfully.`);
      }else{
        setDeleteUserMsg(result.message);
      }
    }
  }

  async function handleAsyncClick() {
    setActionType('delete');
    try {
      const promises = [];
      for (let i = 0; i < selected.length; i += 1) {
        promises.push(myAsyncOperation(selected[i].id, selected[i].status, '', selected[i].firstName));
      }
      await Promise.all(promises);
    } catch (errorBlock) {
      console.error(errorBlock);
    }
  }

  if (blockAlertStatus === 400 || blockAlertStatus === 201) {
    setTimeout(() => {
      setBlockAlertStatus(null);
      setBlockAlertMsg(null);
      window.location.reload();
    }, 3000);
  }
  if (unblockAlertStatus === 400 || unblockAlertStatus === 200) {
    setTimeout(() => {
      setUnblockAlertStatus(null);
      setUnblockAlertMsg(null);
      window.location.reload();
    }, 3000);
  }
  if (deleteUserStatus === 404 || deleteUserStatus === 200 || 
    deleteUserStatus === 500 || deleteUserStatus === 400) {
    setTimeout(() => {
      setDeleteUserStatus(null);
      setDeleteUserMsg(null);
      window.location.reload();
    }, 3000);
  }

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search user..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {
        numSelected > 0 && (
          <div>
            {(userRole === 'admin' || userRole === 'superadmin') && (
              <Tooltip title="Block">
                <IconButton
                  onClick={() => {
                    selected.forEach(element => {
                      if(element.status !== false){
                        const arrName = selected.map(element => element.firstName);
                        setTempMsg(`${arrName} already blocked.`);
                        setStatusModal(false);
                        setActionType('block');
                      }else{
                        setTempMsg('');
                        setStatusModal(true);
                        setActionType('block');
                      }
                      setBlockStatus(element.status);
                    });
                  }}
                >
                <Iconify icon="bx:block" />
                </IconButton>
              </Tooltip>
            )}
            {(userRole === 'admin' || userRole === 'superadmin') && (
              <Tooltip title="Unblock">
                <IconButton
                  onClick={() => {
                    selected.forEach(element => {
                      setBlockStatus(element.status);
                      if(element.status === false){
                        const arrName = selected.map(element => element.firstName);
                        setTempMsg(`${arrName} not blocked.`);
                        setStatusModal(false);
                        setActionType('unblock');
                      }else{
                        setStatusModal(true);
                        setActionType('unblock');
                        setTempMsg('');
                      }
                    });
                  }}
                >
                  <Iconify icon="gg:unblock" />
                </IconButton>
              </Tooltip>
            )}
            
            {(userRole === 'superadmin' &&
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setActionType('delete');
                  handleAsyncClick();
                }}
              >
                <Iconify icon="eva:trash-2-fill" />
              </IconButton>
            </Tooltip>
            )}
            {/* <StatusModal
              open={statusModal}
              handleClose={() => {
                setStatusModal(false);
              }}
              action={`${actionType}`}
              confirmed={() => bulkAction()}
            /> */}
            
            <StatusModal
              open={statusModal}
              handleClose={() => {
                setStatusModal(false);
              }}
              blockStatus={blockStatus}
              confirmed={async (reason) => {
                try {
                  const promises = [];
                  for (let i = 0; i < selected.length; i += 1) {
                    promises.push(myAsyncOperation(selected[i].id, selected[i].status, reason, selected[i].firstName));
                  }
                  await Promise.all(promises);
                } catch (errorBlock) {
                  console.error(errorBlock);
                }
              }}
            />
            {tempMsg !== '' && (
              <Alert type="info" message={tempMsg}/>
            )}
            {blockAlertStatus === 400 && (
              <Alert type="error" message={blockAlertMsg}/>
            )}
            {blockAlertStatus === 201 && (
              <Alert type="success" message={blockAlertMsg}/>
            )}
            {unblockAlertStatus === 400 && (
              <Alert type="error" message={unblockAlertMsg}/>
            )}
            {unblockAlertStatus === 200 && (
              <Alert type="success" message={unblockAlertMsg}/>
            )}
            {deleteUserStatus === 404 && (
              <Alert type="error" message='No data found for user or already deleted.'/>
            )}
            {deleteUserStatus === 200 && (
              <Alert type="success" message={deleteUserMsg} />
            )}
            {deleteUserStatus === 400 || deleteUserStatus === 500 && (
              <Alert type="error" message={deleteUserMsg}/>
            )}
          </div>
        )
      }
    </RootStyle>
  );
}
