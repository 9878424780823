import { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Card,
  TextField,
  Box,
  Button,
  Avatar,
  Stack,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Link,
  Alert,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Page from '../components/Page';
import { FormProvider } from '../components/hook-form';
import { PrimaryButton } from '../components/common/Buttons/CustomizedButton';
import StatusModal from '../components/common/Modal/StatusModal';
import Iconify from '../components/Iconify';
import { getUser, getRoles, updateUser, blockUser, unblockUser, sendResetEmail } from '../api/UsersController';
import {
  SUPERADMIN_ROLE,
  ADMIN_ROLE,
  USER_ROLE,
  SUPERADMIN_DISPLAY_ROLE,
  ADMIN_DISPLAY_ROLE,
  USER_DISPLAY_ROLE,
} from '../constants';
import axiosInstance from '../config/axiosConfig';

const APP_URL = `${window.location.protocol}//${window.location.host}`;
const MAXIMUM_BYTES_IMAGE = 200000;

export default function UserProfile() {
  const token = useSelector((state) => state?.user?.token);
  // const userRole =  useSelector((state) => state?.user?.role);
  const [allRoles, setAllRoles] = useState([]);

  const { data, refetch } = useQuery(
    ['user'],
    async () => {
      const respData = await getUser(paramsId.id);
      const respRoles = await getRoles(token);
      setAllRoles(respRoles.roles);
      return respData.data;
    },
    {
      enabled: false,
      retry: 1,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);


  const [loading, setLoading] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true);
  const [statusModal, setStatusModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const paramsId = useParams();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [image, setImage] = useState(null);
  const [status, setStatus] = useState(null);
  const [icon, setIcon] = useState(null);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [modalError, setModalError] = useState();
  const [disPlayTextError, setDisplayTextError] = useState(false);
  const [blockAlertMsg, setBlockAlertMsg] = useState(null);
  const [blockAlertStatus, setBlockAlertStatus] = useState(null);
  const [unblockAlertMsg, setUnblockAlertMsg] = useState(null);
  const [unblockAlertStatus, setUnblockAlertStatus] = useState(null);
  const [resetPwdAlertMsg, setResetPwdAlertMsg] = useState(null);
  const [resetPwdAlertStatus, setResetPwdAlertStatus] = useState(null);
  const [updateAlertMsg, setUpdateAlertMsg] = useState(null);
  const [updateAlertStatus, setUpdateAlertStatus] = useState(null);
  const isStatusColor = status === true ? '#BD3044' : 'green';

  useEffect(() => {
    if (data) {
      setDisplayName(data?.displayName);
      setFirstName(data?.firstName);
      setLastName(data?.lastName);
      setEmail(data?.email);
      const normalizedValue = data?.role.replace(/[\s_]/g, '').toLowerCase();
      if (normalizedValue === USER_ROLE || normalizedValue === '') {
        setRole(USER_ROLE);
      } else if (normalizedValue === SUPERADMIN_ROLE) {
        setRole(SUPERADMIN_ROLE);
      } else if (normalizedValue === ADMIN_ROLE) {
        setRole(ADMIN_ROLE);
      }
      // set image icon
      setImage(data.profileIconBase64 || `/static/images/avatar/${data.userId}.jpg`);
      setStatus(data?.blockStatus);
      const dateCreated = new Date(data.createdAt);
      const formattedDateCreated = dateCreated.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      setCreatedAt(formattedDateCreated);
      const dateUpdated = new Date(data.lastUpdated);
      const formattedDateUpdated = dateUpdated.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      setUpdatedAt(formattedDateUpdated);
    }
  }, [data]);
  if (blockAlertStatus === 400 || blockAlertStatus === 201) {
    setTimeout(() => {
      setBlockAlertStatus(null);
      setBlockAlertMsg(null);
      window.location.reload();
    }, 3000);
  }
  if (unblockAlertStatus === 400 || unblockAlertStatus === 200) {
    setTimeout(() => {
      setUnblockAlertStatus(null);
      setUnblockAlertMsg(null);
      window.location.reload();
    }, 3000);
  }
  if (resetPwdAlertStatus === 400 || resetPwdAlertStatus === 200) {
    setTimeout(() => {
      setResetPwdAlertMsg(null);
      setResetPwdAlertStatus(null);
      window.location.reload();
    }, 3000);
  }
  if (updateAlertStatus === 400 || updateAlertStatus === 200) {
    setTimeout(() => {
      setUpdateAlertMsg(null);
      setUpdateAlertStatus(null);
      window.location.reload();
    }, 3000);
  }

  const changeFirstNameHandler = (e) => {
    if (data?.firstName !== e.target.value) {
      setFirstName(e.target.value);
      setDisabledSubmitBtn(false);
    }
  };
  const changeLastNameHandler = (e) => {
    setLastName(e.target.value);
    if (data?.lastName !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };
  const changeDisplayNameHandler = (e) => {
    setDisplayName(e.target.value);
    // if (data?.displayName !== e.target.value) {
    //   setDisabledSubmitBtn(false);
    // }
  };
  const changeEmailHandler = (e) => {
    setEmail(e.target.value);
    if (data?.email !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };
  const changeRoleHandler = (e) => {
    setRole(e.target.value);
    if (data?.role !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };

  const validateInput = () => {
    const errorList = {
      email: '',
      firstName: '',
      lastName: '',
      displayName: '',
      phoneNumber: '',
      company: '',
    };
    let errors = false;

    if (!firstName || firstName.length === 0) {
      errors = true;
      errorList.name = 'Please provide a first name.';
    }
    if (!lastName || lastName.length === 0) {
      errors = true;
      errorList.name = 'Please provide a last name.';
    }
    if (!displayName || displayName.length === 0) {
      errors = true;
      errorList.name = 'Please provide a display name.';
    }

    const reg = /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,})+$/;
    if (!email || email === 0 || reg.test(email) === false) {
      errors = true;
      errorList.email = 'Please provide a valid email.';
    }

    setModalError(errorList);
    setDisplayTextError(errors);
    return errors;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);
    const dataUpdate = {
      userId: data?.userId,
      email,
      firstName,
      lastName,
      displayName,
      role,
    };

    if (icon) {
      await uploadImage();
    }

    if (!validateInput()) {
      const respUser = await updateUser(token, dataUpdate);
      if (respUser.status === 200) {
        setUpdateAlertStatus(respUser.status);
        setUpdateAlertMsg(respUser.message);
      } else if (respUser.status === 400) {
        if (respUser.error) {
          setUpdateAlertStatus(respUser.status);
          setUpdateAlertMsg(respUser.error[0].msg);
        } else {
          setUpdateAlertMsg('An error occured. Please try again.');
          setUpdateAlertStatus(respUser.status);
        }
      } else {
        setUpdateAlertMsg('An error occured. Please try again.');
        setUpdateAlertStatus(respUser.status);
      }
    } else {
      setSuccess(false);
      setError(true);
      setMessage('Please correct errors');
    }
    setLoading(false);
  };

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append('file', icon);
    formData.append('usageType', 'profileIcon');
    formData.append('targetUserId', data.userId);
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_CORE_API_URI}/web/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data; // You can return any necessary data or handle it as you wish
    } catch (error) {
      console.error('Error uploading the image:', error);
      throw error; // Or handle error accordingly
    }
  };

  const handleImageChange = (event) => {
    if (!event.target.files[0].type === 'image/jpeg') {
      setUploadErrorMessage('Please upload an image type');
    } else if (event.target.files[0].size >= MAXIMUM_BYTES_IMAGE) {
      setUploadErrorMessage('The uploaded Image file is too large');
    } else {
      const imageURL = URL.createObjectURL(new File(event.target.files, 'image'));
      setImage(imageURL);
      setIcon(event.target.files[0]);
      setDisabledSubmitBtn(false);
    }
  };

  const sendEmail = async () => {
    const result = await sendResetEmail(email, `${APP_URL}/reset-password`);
    if (result.status === 200 || result.status === 400) {
      setResetPwdAlertMsg(result.message);
      setResetPwdAlertStatus(result.status);
    } else {
      setSuccess(false);
      setError(true);
      setMessage('Failed to send email');
    }
  };

  return (
    <Page title="User Profile">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {`User : ${data?.firstName} ${data?.lastName}`}
          <Link
            variant="h5"
            component={RouterLink}
            to="/dashboard/user"
            sx={{ textDecoration: 'none', color: '#000', float: 'right', mr: '12%' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Iconify icon="bx:arrow-back" fontSize="20px" /> Back
            </div>
          </Link>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Card
              sx={{
                alignContent: 'center',
                p: 1,
                height: 'auto',
              }}
            >
              <FormProvider methods={null} onSubmit={submitHandler}>
                <Box display="flex" width="100%" height={80} alignItems="center" justifyContent="left">
                  <TextField
                    id="profileName"
                    label="Display Name"
                    variant="outlined"
                    style={{ width: '47%', marginRight: '20px' }}
                    size="small"
                    value={displayName || ''}
                    onChange={changeDisplayNameHandler}
                    helperText={disPlayTextError && modalError.displayName}
                    error={disPlayTextError && modalError.displayName.length > 0 && true}
                  />
                  <Stack direction="row" spacing={2} sx={{ float: 'left', marginLeft: '20px' }}>
                    <Avatar alt="Remy Sharp" src={image} sx={{ width: 92, height: 92 }} />
                    <Typography variant="p" component="p">
                      {uploadErrorMessage}
                    </Typography>
                    <Button
                      color="chip"
                      size="large"
                      variant="contained"
                      sx={{ boxShadow: 12, padding: '0 40px', alignSelf: 'center' }}
                      component="label"
                    >
                      Change Icon
                      <input type="file" hidden onChange={handleImageChange} accept="image/*" />
                    </Button>
                  </Stack>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  height={80}
                  alignItems="center"
                  justifyContent="right"
                  sx={{ float: 'right' }}
                >
                  <TextField
                    id="firstName"
                    label="First Name"
                    variant="outlined"
                    style={{ width: '50%', marginRight: '20px' }}
                    size="small"
                    value={firstName || ''}
                    placeholder={data?.firstName}
                    onChange={changeFirstNameHandler}
                    helperText={disPlayTextError && modalError.firstName}
                    error={disPlayTextError && modalError.firstName.length > 0 && true}
                  />
                  <TextField
                    value={lastName || ''}
                    placeholder={data?.lastName}
                    onChange={changeLastNameHandler}
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                    style={{ width: '50%', marginLeft: '0px' }}
                    size="small"
                    helperText={disPlayTextError && modalError.lastName}
                    error={disPlayTextError && modalError.lastName.length > 0 && true}
                  />
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  height={80}
                  alignItems="center"
                  justifyContent="right"
                  sx={{ float: 'right' }}
                >
                  <TextField
                    id="userEmail"
                    label="Email"
                    variant="outlined"
                    style={{ width: '50%', marginRight: '20px' }}
                    size="small"
                    value={email || ''}
                    // placeholder={data?.email}
                    onChange={changeEmailHandler}
                    helperText={disPlayTextError && modalError.email}
                    error={disPlayTextError && modalError.email.length > 0 && true}
                  />
                  <FormControl size="small" sx={{ width: '50%', marginRight: '0px' }} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Role"
                      value={role || ''}
                      onChange={changeRoleHandler}
                    >
                      {allRoles.map((option) => {
                        let dropdownValue;
                        if (option === ADMIN_ROLE) {
                          dropdownValue = ADMIN_DISPLAY_ROLE;
                        } else if (option === SUPERADMIN_ROLE) {
                          dropdownValue = SUPERADMIN_DISPLAY_ROLE;
                        } else if (option === USER_ROLE) {
                          dropdownValue = USER_DISPLAY_ROLE;
                        }
                        return (
                          <MenuItem key={option} value={option}>
                            {dropdownValue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {/* <TextField
                    value={number || ''}
                    onChange={changeNumberHanlder}
                    id="profileCurrentPassword"
                    label="Phone Number"
                    variant="outlined"
                    style={{ width: '50%', marginLeft: '20px' }}
                    size="small"
                    helperText={disPlayTextError && modalError.phoneNumber}
                    error={disPlayTextError && modalError.phoneNumber.length > 0 && true}
                  /> */}
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  height={60}
                  alignItems="center"
                  justifyContent="right"
                  sx={{ float: 'right' }}
                >
                  {/* {role === 'corporate' && (
                    <TextField
                      id="userCompany"
                      label="Company"
                      variant="outlined"
                      style={{ width: '50%', marginLeft: '20px' }}
                      size="small"
                      value={company || ''}
                      onChange={changeCompanyHandler}
                      helperText={disPlayTextError && modalError.company}
                      error={disPlayTextError && modalError.company.length > 0 && true}
                    />
                  )} */}
                  <div>
                    {updateAlertStatus === 400 && <Alert severity="error">{updateAlertMsg}</Alert>}
                    {updateAlertStatus === 200 && <Alert severity="success">{updateAlertMsg}</Alert>}
                    {blockAlertStatus === 400 && <Alert severity="error">{blockAlertMsg}</Alert>}
                    {blockAlertStatus === 201 && <Alert severity="success">{blockAlertMsg}</Alert>}
                    {unblockAlertStatus === 400 && <Alert severity="error">{unblockAlertMsg}</Alert>}
                    {unblockAlertStatus === 200 && <Alert severity="success">User successfully unblocked.</Alert>}
                    {resetPwdAlertStatus === 200 && <Alert severity="success">{resetPwdAlertMsg}</Alert>}
                    {resetPwdAlertStatus === 400 && <Alert severity="error">{resetPwdAlertMsg}</Alert>}
                  </div>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  alignItems="flex-end"
                  justifyContent="left"
                  sx={{ ml: 0, float: 'left', marginTop: 2.5 }}
                >
                  <PrimaryButton
                    loading={loading}
                    backgroundColor="#0084F4"
                    disabled={disabledSubmitBtn}
                    showMessage={message}
                    error={error}
                    success={success}
                  >
                    Save Changes
                  </PrimaryButton>
                  <Link variant="subtitle2" component={RouterLink} to="/dashboard/user" sx={{ textDecoration: 'none' }}>
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ mr: 4, padding: '0 40px' }}
                      color="cancel"
                      fontFamily="tomorrow"
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Box
                    display="flex"
                    width="50%"
                    alignItems="flex-end"
                    justifyContent="right"
                    sx={{ float: 'right', marginTop: 2.5 }}
                  >
                    <div style={{ cursor: 'pointer' }} onClick={() => sendEmail()}>
                      <Typography variant="subtitle2" sx={{ mt: 2, color: '#BD3044' }}>
                        <u>Update Password</u>
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </FormProvider>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card
              sx={{
                alignContent: 'center',
                p: 1,
                width: '50%',
                height: 'auto',
                ml: 8,
              }}
            >
              <Typography variant="subtitle2" sx={{ mb: 0 }}>
                Created At
              </Typography>
              <Typography variant="body2." sx={{ mb: 9 }}>
                {createdAt}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Last Update
              </Typography>
              <Typography variant="body2." sx={{ mb: 5 }}>
                {updatedAt}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Status
              </Typography>
              <Typography variant="body2." sx={{ mb: 5, fontWeight: 'bold' }} color={isStatusColor}>
                {status === true ? 'Blocked' : 'Active'}
              </Typography>
            </Card>

            <Button
              size="large"
              variant="contained"
              sx={{
                mt: 3,
                ml: 8,
                padding: '0 50px',
                backgroundColor: '#BD3044',
                boxShadow: '5px 8px 10px -9px rgba(189,48,68, 0.6)',
                '&:hover': {
                  background: '#AC1011',
                },
              }}
              fontFamily="tomorrow"
              // disabled={userRole === 'corporate'}
              onClick={() => setStatusModal(true)}
            >
              {status === true ? 'Unblock User' : 'Block User'}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <StatusModal
        open={statusModal}
        handleClose={() => {
          setStatusModal(false);
        }}
        blockStatus={status}
        confirmed={async (reason) => {
          try {
            if (status === false) {
              const responseBlock = await blockUser(paramsId.id, token, reason);
              setBlockAlertMsg(responseBlock.message);
              setBlockAlertStatus(responseBlock.status);
            }
            if (status === true) {
              const respUnblock = await unblockUser(paramsId.id, token);
              setUnblockAlertMsg(respUnblock.message);
              setUnblockAlertStatus(respUnblock.status);
            }
          } catch (errorBlock) {
            console.error(errorBlock);
          }
        }}
      />
    </Page>
  );
}
