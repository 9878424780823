import { DeleteUser } from '../redux/actions/UserActions';

export const API_POST = async(action, body, token, method) => {
    try {
        let headers = {
            'Content-Type': 'application/json',
        };
        if(token !== ""){
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        } 
        const response = await fetch(action, {
            method,
            headers,
            body: JSON.stringify(body),
        });
        if(response.status === 401){
            DeleteUser();
        }
        const data = await response.json();
        data.status = response.status;
        return data;
    } catch (error) {
        console.error('error: ', error);
      throw error;
    }
}

export const API_GET = async (action, token) => {
    try{
        let headers = {
            'Content-Type': 'application/json',
            
        };
        if(token !== ""){
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token}`,
            }
        } 
        const response = await fetch(action, {
            method: 'GET',
            headers,
        });
        if(response.status === 401){
            DeleteUser();
        }
        const data = await response.json();
        data.status = response.status;
        return data;
    } catch (error) {
        console.error(error);
      throw error;
    }
}

export const API_DELETE = async (action, token) => {
    try{
        const response = await fetch(action, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token}`,
            }
        });        
        if(response.status === 401){
            DeleteUser();
        }
        if(response.status === 204) {
            return {'status': 200, 'message': 'User deleted successfully.'};
        }
        if(response.status === 404 || response.status === 400) {
            const data = await response.json();            
            data.status = response.status;
            return data; 
        }
               
    } catch (error) {
        console.error(error);
      throw error;
    }
}

