import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Button from '../../../components/intraverse/ui/Button';

const Footer = (props) => {
  const text = props.broadCaster ? 'Stop Broadcasting' : 'Start Broadcasting';

  return (
    <FooterContainer>
      <Button text={text} func={props.buttonFunction} greyBoxText={text} value={props.value} />
    </FooterContainer>
  );
};
export default Footer;

Footer.propTypes = {
  buttonFunction: PropTypes.any,
  value: PropTypes.any,
};

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  padding: 25px;
  display: flex;
  justify-content: end;
  align-items: center;

  @media (max-width: 960px) {
    padding: 15px;
  }
`;
