import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import IntraverseLogoIcon from './IntraverseHomeImages/IntraverseLogoIcon.png';
import MenuIcon from './IntraverseHomeImages/HamburgerIconSVG.svg';
import IntraverseLogoFull from './IntraverseHomeImages/IntraverseLogoFull.png';
import DiamondBackground from './IntraverseHomeImages/Diamond_Checkered_Background.png';
import CloseMenu from './IntraverseHomeImages/CloseMenu.png';

const LandingHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    const aspectRatio = innerWidth / innerHeight;
    return { innerWidth, innerHeight, aspectRatio };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <HeaderContainer>
      {windowSize.innerWidth > 769 && (
        <>
          <Menu isOpen={isOpen}>
            <Logo src={IntraverseLogoIcon} alt="Intraverse Logo" />
            <MenuItems>
              <a style={{ textDecoration: 'none' }} href="https://terrazero.com/" target="_blank" rel="noreferrer">
                <MenuItem>About</MenuItem>
              </a>
              <a
                style={{ textDecoration: 'none' }}
                href="https://terrazero.com/clients"
                target="_blank"
                rel="noreferrer"
              >
                <MenuItem>Activations</MenuItem>
              </a>
              <MenuItem>Subscribe</MenuItem>
            </MenuItems>
          </Menu>
          <MenuButtons>
            <SignInButton onClick={() => routeChange('/login')}>Sign In</SignInButton>
            <PlayForFreeButton onClick={() => routeChange('/register')}>Play For Free</PlayForFreeButton>
          </MenuButtons>
        </>
      )}
      {windowSize.innerWidth < 769 && (
        <MobileHeader>
          {!isOpen && <MobileInnerLogo src={IntraverseLogoIcon} alt="Intraverse Logo" />}
          {isOpen ? (
            <CloseButton onClick={toggleMenu} src={CloseMenu} alt="Close Menu" />
          ) : (
            <MenuIconContainer onClick={toggleMenu} src={MenuIcon} alt="Menu Icon" />
          )}
        </MobileHeader>
      )}
      {isOpen && (
        <MobileMenu>
          <MobileLogo src={IntraverseLogoFull} alt="Intraverse Full Logo" />
          <MobileMenuItems>
            <MobileMenuItem>About</MobileMenuItem>
            <MobileMenuItem>Activations</MobileMenuItem>
            <MobileMenuItem>Subscribe</MobileMenuItem>
          </MobileMenuItems>
          <MobileMenuButtons>
            <MobileSignInButton onClick={() => routeChange('/login')}>Sign In</MobileSignInButton>
            <MobilePlayForFreeButton>Play For Free</MobilePlayForFreeButton>
          </MobileMenuButtons>
        </MobileMenu>
      )}
    </HeaderContainer>
  );
};

export default LandingHeader;

const HeaderContainer = styled.div`
  position: fixed;
  top: 2.6%;
  left: 0;
  right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: none;
  z-index: 1000;
  padding: 15px 40px;

  @media (max-width: 768px) {
    padding: 15px 25px;
    right: 0.5rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 15px 25px;
    right: 0.5rem;
  }
`;

const Logo = styled.img`
  max-width: 100px;
  margin-right: 3rem;
  margin-left: 1rem;

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 80px;
    margin-right: 2rem;
    margin-left: 0rem;
  }
`;
const Menu = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItems = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
`;

const MenuItem = styled.div`
  color: #fbfcff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  justify-items: center;
  margin-right: 35px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 1rem;
    letter-spacing: 1px;
    margin-right: 25px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1rem;
    letter-spacing: 1px;
    margin-right: 25px;
  }
`;

const MenuButtons = styled.div`
  display: flex;
  align-items: center;
`;

const SignInButton = styled.button`
  border-radius: 5px;
  border: 2px solid #beaeff;
  background: rgba(37, 24, 89, 0.5);
  padding: 15px 35px;
  margin-right: 20px;
  cursor: pointer;

  color: #beaeff;
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding: 15px 20px;
    font-size: 0.6rem;
    letter-spacing: 1px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 15px 20px;
    font-size: 0.6rem;
    letter-spacing: 1px;
  }
`;

const PlayForFreeButton = styled.button`
  border-radius: 5px;
  background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
  padding: 16px 35px;
  border: none;
  cursor: pointer;

  color: #fbfcff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding: 15px 15px;
    font-size: 0.6rem;
    letter-spacing: 1px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 15px 15px;
    font-size: 0.6rem;
    letter-spacing: 1px;
  }
`;

// MOBILE MENU

const MenuIconContainer = styled.img`
  width: 50px;
  height: 50px;
  z-index: 1000;

  position: fixed;
  top: 2rem;
  right: 1.5rem;
  display: flex;
`;
const MobileInnerLogo = styled.img`
  width: 50px;
  height: 50px;
  z-index: 1000;

  position: fixed;
  top: 2rem;
  left: 1.5rem;
  display: flex;
`;

const MobileMenu = styled.div`
  display: flex;
  position: absolute;
  top: -1.1rem;
  left: 1.5%;
  align-items: center;
  flex-direction: column;
  width: 97vw;
  height: fit-content;
  padding-bottom: 1.5rem;
  background: rgba(27, 27, 35, 1);
  background-image: url(${DiamondBackground});
  background-size: 350px auto;
`;

const MobileHeader = styled.div`
  display: flex;
  align-items: center;
`;
const CloseButton = styled.img`
  width: 60px;
  height: 60px;
  z-index: 1000;

  position: fixed;
  top: 3.7%;
  right: 2rem;
  display: flex;

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;
const MobileLogo = styled.img`
  position: relative;
  top: -4.5rem;
  left: -7rem;
  width: 50vw;
  margin-top: 6rem;

  @media (max-width: 600px) {
    top: -3.7rem;
    left: -3rem;
    width: 50vw;
  }
`;

const MobileMenuItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  flex-direction: column;
  width: 80%;
`;
const MobileMenuItem = styled.div`
  color: #fbfcff;
  text-align: left;
  font-family: 'Barlow', sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 2.7rem;
`;

const MobileMenuButtons = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
`;

const MobileSignInButton = styled.button`
  border-radius: 5px;
  border: 2px solid #beaeff;
  background: rgba(37, 24, 89, 0.5);
  padding: 20px 35px;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  color: #beaeff;
  font-family: 'Barlow', sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const MobilePlayForFreeButton = styled.button`
  border-radius: 5px;
  background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
  padding: 20px 35px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;

  color: #fbfcff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
