import { useState, useRef, useEffect } from 'react';
import { TextField, Box, Link, CircularProgress } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormProvider } from './hook-form';
import { PrimaryButton, CancelButton } from './common/Buttons/CustomizedButton';
import { updateUser } from '../api/UsersController';
import { UpdateData } from '../redux/actions/UserActions';
import Alert from './common/Alert/Alert';

export default function ProfileForm({ user }) {
  const token = useSelector((state) => state?.user?.token);
  const [profileButtonDisable, setProfileButtonDisable] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [disPlayTextError, setDisplayTextError] = useState(false);
  const [profileError, setProfileError] = useState();
  const [updateAlertMsg, setUpdateAlertMsg] = useState(null);
  const [updateAlertStatus, setUpdateAlertStatus] = useState(null);
  const userId = useSelector((state) => state?.user?.userId);
  const name = useRef();
  const email = useRef();
  const lastName = useRef();
  const displayName = useRef();
  const validateInput = () => {
    const errorList = {
      name: '',
      email: '',
      lastName: '',
      displayName: ''
    };
    let errors = false;
    if (!name.current.value || name.current.value.length === 0) {
      errors = true;
      errorList.name = 'Please provide your first name.';
    }
    if (!lastName.current.value || lastName.current.value.length === 0) {
      errors = true;
      errorList.lastName = 'Please provide your last name.';
    }
    if (!displayName.current.value || displayName.current.value.length === 0) {
      errors = true;
      errorList.displayName = 'Please provide your user name.';
    }
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w\w+)+$/;
    if (!email.current.value || email.current.value.length === 0 || reg.test(email.current.value) === false) {
      errors = true;
      errorList.email = 'Please provide a valid email.';
    }
    // const regExp = /[a-zA-Z]/g;
    // if (
    //   !phoneNumber.current.value ||
    //   phoneNumber.current.value.length < 10 ||
    //   regExp.test(phoneNumber.current.value) === true
    // ) {
    //   errorList.phoneNumber = 'Your phone number must include your area code without any symbols.';
    //   errors = true;
    // }
    setProfileError(errorList);
    setDisplayTextError(errors);
    return errors;
  };

  const checkForNewValues = (newValue, oldValue) => {
    if (newValue !== oldValue) {
      setProfileButtonDisable(false);
    } else {
      setProfileButtonDisable(true);
    }
  };
  if (updateAlertStatus === 400 || updateAlertStatus === 200) {
    setTimeout(() => {
      setUpdateAlertMsg(null);
      setUpdateAlertStatus(null);
      window.location.reload();
    }, 3000);
  }
  const updateProfileHandler = async (e) => {
    e.preventDefault();
    if (!validateInput()) {
      const udpatedData = {
        firstName: name.current.value,
        email: email.current.value,
        displayName: displayName.current.value,
        lastName: lastName.current.value,
        userId
      } 
      const respUser = await updateUser(
        token, 
        udpatedData
      );
      if(respUser.status === 200){
        UpdateData(displayName.current.value, '');
        setUpdateAlertStatus(respUser.status);
        setUpdateAlertMsg(respUser.message);
      }else if(respUser.status === 400){
        if(respUser.error){
          setUpdateAlertStatus(respUser.status);
          setUpdateAlertMsg(respUser.error[0].msg);
        }else{
          setUpdateAlertMsg('An error occured. Please try again.');
          setUpdateAlertStatus(respUser.status);
        }
      }else{
        setUpdateAlertMsg('An error occured. Please try again.');
        setUpdateAlertStatus(respUser.status);
      }
    } else {
      setSuccess(false);
      setError(true);
      setMessage('Profile faiiled to update');
    }
  };
  return (
    <FormProvider methods={undefined} onSubmit={updateProfileHandler}>
      {user.name !== null && user.email !== null && user.displayName !== null && user.role !== null ? (
      <>
      <Box display="flex" width="100%" height={80} alignItems="center" justifyContent="center">
        <TextField
          id="FirstName"
          label='First Name'
          variant="outlined"
          style={{ width: '50%', marginRight: '60px' }}
          size="small"
          defaultValue={user.name}
          helperText={disPlayTextError && profileError.name}
          inputRef={name}
          error={disPlayTextError && profileError.name.length > 0 && true}
          onChange={() =>
            checkForNewValues(name.current.value, user.name)
          }
        />
        <TextField
          id="LastName"
          label='Last Name'
          variant="outlined"
          style={{ width: '50%', marginRight: '60px' }}
          size="small"
          defaultValue={user.lastName}
          helperText={disPlayTextError && profileError.name}
          inputRef={lastName}
          error={disPlayTextError && profileError.lastName.length > 0 && true}
          onChange={() =>
            checkForNewValues(lastName.current.value, user.lastName)
          }
        />
        
      </Box>
      <Box display="flex" width="100%" height={80} alignItems="center" justifyContent="center">
        <TextField
          id="profileEmail"
          label="Email"
          variant="outlined"
          style={{ width: '50%', marginRight: '60px'}}
          size="small"
          defaultValue={user.email}
          inputRef={email}
          helperText={disPlayTextError && profileError.email}
          error={disPlayTextError && profileError.email.length > 0 && true}
          onChange={() => checkForNewValues(email.current.value, user.email)}
        />
        <TextField
          id="profiledisplayName"
          label="Display Name"
          variant="outlined"
          style={{ width: '50%', marginRight: '60px' }}
          size="small"
          defaultValue={user.displayName}
          inputRef={displayName}
          helperText={disPlayTextError && profileError.displayName}
          error={disPlayTextError && profileError.displayName.length > 0 && true}
          onChange={() => checkForNewValues(displayName.current.value, user.displayName)}
        />
        {/* <TextField
          disabled
          id="profileRole"
          label="Role"
          variant="outlined"
          style={{ width: '50%' }}
          size="small"
          inputProps={{ readOnly: true }}
          defaultValue={user.role}
        /> */}
      </Box>
      <Box display="flex" width="100%" alignItems="flex-end" justifyContent="left" sx={{ ml: 0 }}>
        {updateAlertStatus === 400 && (
          <Alert type="error" message={updateAlertMsg}/>
        )}
        {updateAlertStatus === 200 && (
          <Alert type="success" message={updateAlertMsg}/>
        )}
        <PrimaryButton
          backgroundColor="#0084F4"
          showMessage={message}
          error={error}
          success={success}
          disabled={profileButtonDisable}
        >
          Save Changes
        </PrimaryButton>
        <Link variant="subtitle2" component={RouterLink} to="/dashboard/app" sx={{ textDecoration: 'none' }}>
          <CancelButton />
        </Link>
      </Box>
      </>
      ) : (
        <CircularProgress />
      )}
    </FormProvider>
  );
}

ProfileForm.propTypes = {
  user: PropTypes.any,
};
