import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import NewUserModal from '../modal/NewUserModal';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import SearchNotFound from '../SearchNotFound';
import { MerchantListHead, MerchantMoreMenu, MerchantListToolbar } from '../../sections/@dashboard/merchant';
import StatusModal from '../common/Modal/StatusModal';
import Alert from "../common/Alert/Alert";
import { getClients, getUsers } from '../../api/UsersController';
import AddCollaboratorsModal from '../modal/AddCollaboratorsModal';

const TABLE_HEAD = [
  { id: 'displayName', label: 'Display Name', alignRight: false },
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'active', label: 'Status', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function ConnectedMerchantUsersTable({dataUsers}) {
  const [filteredData, setFilteredData] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setModalOpen] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [clients, setClients] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [deleteUserMsg, setDeleteUserMsg] = useState(null);

  const handleButtonClick = () => {
    setShowAlert(true);
  };

  useEffect(() => {
    if(dataUsers){
      const filteredUsers = applySortFilter(dataUsers, getComparator(order, orderBy), filterName);
      setFilteredData(filteredUsers);
    }   
  }, [dataUsers, filterName, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, status, firstName) => {
    const selectedIndex = selected.findIndex((item) => item.id === name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { id: name, status, firstName });
    } else if (selectedIndex === 0) {
      selected[0] = { ...selected[0], status, firstName };
      newSelected = selected.slice(1);
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleRemove = (event, action) => {
    setStatusModal(true);
    setModalAction(action);
  };

  const handleConfirmModal = (event) => {
    console.log('modal confirmed', event);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataUsers?.length) : 0;

  return (
    <>
    <Container>
        <Stack pt={5} direction="row" alignItems="center" justifyContent="space-between" mb={2} mr={15}>
          <Typography variant="h4" gutterBottom>
            Connected Users
          </Typography>
          <AddCollaboratorsModal/>
        </Stack>
        <Card sx={{ marginRight: '120px' }}>
          <MerchantListToolbar
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleAction={(e, action) => {
              handleRemove(e, action);
            }}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              {
              filteredData && (
                <Table>
                  <MerchantListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    // rowCount={dataUsers.length}
                    // numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    // onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { userId, firstName, lastName, email, displayName, blockStatus, createdAt, icon, bgColor } = row;
                      const date = new Date(createdAt);
                      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                      const isItemSelected = selected.findIndex(obj => obj.id === userId) !== -1;
                      return (
                        <TableRow
                          hover
                          key={userId}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, userId, blockStatus, firstName)} />
                          </TableCell>

                          <TableCell>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              component={RouterLink}
                              to={`/dashboard/user-profile/${userId}`}
                              sx={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
                            >
                              <Avatar alt={displayName} sx={{ bgcolor: `${bgColor}` }} src={`/static/images/avatar/${userId}.jpg`} />
                              <Typography variant="subtitle2" noWrap>
                                {displayName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>{firstName}</TableCell>
                          <TableCell>{lastName}</TableCell>
                          <TableCell>{email}</TableCell>
                          <TableCell>
                            <Label variant="ghost" color={(blockStatus === true && 'error') || 'success'}>
                              {blockStatus === true ? 'Blocked' : 'Active'}
                            </Label>
                          </TableCell>
                          <TableCell>
                            {formattedDate}
                          </TableCell>
                          {/* <TableCell align="right">
                            <MerchantMoreMenu
                              handleAction={(e, action) => {
                                handleRemove(e, action);
                              }}
                              merchant={row}
                            />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {filteredData && dataUsers?.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          {dataUsers && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataUsers?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
      <StatusModal
        open={statusModal}
        handleClose={() => {
          setStatusModal(false);
        }}
        confirmed={handleConfirmModal}
      />
    </>
  );
}