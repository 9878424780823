import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import InputField from '../../../components/intraverse/form/InputField';
import Title from '../../../components/intraverse/text/Title';
import Description from '../../../components/intraverse/text/Description';
import Banner from '../../../components/intraverse/ui/Banner';

const Body = (props) => (
  <BodyContainer>
    <TextContainer>
      <Title text="Broadcast your thoughts" />
      <Description text="Enter the password to start or stop broadcasting to all guests in this session." />
    </TextContainer>
    <InputField
      placeHolder="Enter Password"
      value={props.password}
      setValue={props.setPassword}
      handleInputKeyDown={props.handleInputKeyDown}
    />
    <Divider />
    <Banner
      message={
        props.openBanner === 'ERROR'
          ? 'The password you entered is incorrect. Please try again.'
          : 'You are now broadcasting to all guests in this session.'
      }
      type={props.openBanner}
      openBanner={props.openBanner}
      setOpenBanner={props.setOpenBanner}
      topDisplay={false}
      showCloseButton={false}
    />
  </BodyContainer>
);
export default Body;

Body.propTypes = {
  openBanner: PropTypes.any,
  setOpenBanner: PropTypes.any,
};

const Divider = styled.div`
  margin-bottom: 25px;
  @media (max-width: 960px) {
    margin-bottom: 15px;
  }
`;

const BodyContainer = styled.div`
    width: 100%
    height: auto;
    @media (max-width: 960px) {
        padding: 15px;
      }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  @media (max-width: 960px) {
    margin-bottom: 30px;
  }
`;
