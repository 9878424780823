import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as UserLogo } from './icons/user_mobile_nav.svg';
import { ReactComponent as CardLogo } from './icons/card_mobile_nav.svg';
import { ReactComponent as OrderLogo } from './icons/order_mobile_nav.svg';

const MobileNav = () => {
  return (
    <StickyContainer>
      <ALink href='/user-console'>  
        <IconContainer>
          <UserLogo />
          <Text>
          Profile
          </Text>
        </IconContainer>
      </ALink>
      <ALink href='/wallet'>
        <IconContainer>
          <CardLogo />
          <Text>
            Wallet
          </Text>
        </IconContainer>
      </ALink>
      <ALink href='/orders'>
        <IconContainer>
          <OrderLogo />
          <Text>
          Orders
          </Text>
        </IconContainer>
      </ALink>
    </StickyContainer>
  );
};

const Text = styled.p`
  margin-top: 5px;
`;

const ALink   = styled.a`
  text-decoration: auto;
`;

const StickyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: 1rem;
  padding-right: 1rem;
  align-content: center;
  background-color: white;
  position: sticky;
  flex: 1;
  bottom: 0;
  background-color: #38344a;
  height: 75px;
  
  @media (min-width: 768px) {
   display:none;
  }

`;

const IconContainer = styled.div`
  height: 75px;
  color: #9DA0A7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
`;

export default MobileNav;
