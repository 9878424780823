/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
// @mui & Image Imports
import { Container, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as GreenFeatureCheckmark } from './intraverseLandingPage/IntraverseHomeImages/GreenFeatureCheckmark.svg';
import { ReactComponent as RedCross } from './intraverseLandingPage/IntraverseHomeImages/redCross.svg';
import IntraverseLogoFull from './intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
import CheckmarkIcon from './passwordResetPage/passwordResetImages/Checkmark.png';
import DiamondBackground from './intraverseLandingPage/IntraverseHomeImages/Diamond_Checkered_Background.png';
// API & Utils
import { ConfirmPasswordReset, RegisterUser } from '../sections/auth/api/Controller';
// import AuthSocial from '../sections/auth/AuthSocial';
import { validateUsername } from '../utils/isValidEmail';
import FooterContainer from '../components/FooterContainer';


export default function PasswordUpdate() {
  const { token } = useParams();

  // Page Section state handling
  const [section, setSection] = useState('password-reset');
  const [isLoading, setIsLoading] = useState(false);

  // Error states
  
  // Password checker states
  const [lengthError, setLengthError] = useState(true);
  const [message, setMessage] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatches, setPasswordMatches] = useState(false);
  const [uppercaseError, setUppercaseError] = useState(true);
  const [numberError, setNumbersError] = useState(true);
  const [specialCharError, setSpecialCharError] = useState(true);

  const countryOptions = ['USA', 'Canada'];

  const navigate = useNavigate();

  useEffect(() => {
    setPasswordMatches(false)
    handlePasswordChange()
  }, [password, confirmPassword]);


  const handleUpdatePassword = async () => {
    console.log("updating password")
    if (password === confirmPassword && !lengthError && !uppercaseError && !numberError && !specialCharError) {
      try{
        const res = await ConfirmPasswordReset(token, password);
        console.log(res)
        if(res.status === 200){
            setSection("success")
            setTimeout(() => {
              navigate(`/login`)
            }, 4000);
        }
        if(res.status === 401) {
          console.log(res.message)
          setMessage("Reset password link expired. Please reset your password again.");
          // setTimeout(() => {
          //   navigate(`/login`)
          // }, 3000);
        }
        else if(res.status === 400){
            if(res.error){
                setMessage(res.error[0].msg);
            }
        } else {
          setMessage('An error occurred. Please try again.');
        }
      } catch (err) {
        console.log(err)
        setMessage('Error resetting password');
      }      
    }
  }


  const validatePassword = (password) => {
    const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/; // allows special chars
    return passwordReg.test(password);
  };

  const handlePasswordChange = (event) => {
    const minLengthRegex = /^.{8,}$/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    // eslint-disable-next-line no-useless-escape
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    
    if (minLengthRegex.test(password)) {
      setLengthError(false);
    } else {
      setLengthError(true);
    }
    if (uppercaseRegex.test(password)) {
      setUppercaseError(false);
    } else {
      setUppercaseError(true);
    }
    if (numberRegex.test(password)) {
      setNumbersError(false);
    } else {
      setNumbersError(true);
    }
    if (specialCharacterRegex.test(password)) {
      setSpecialCharError(false);
    } else {
      setSpecialCharError(true);
    }
    if (password === confirmPassword && !lengthError && !uppercaseError && !numberError && !specialCharError) {
      setPasswordMatches(true)
    }
  };

  return (
    <LoginContainer>
      <BorderOutlineStroke>
        <HeaderDiv>
          <Link to="/">
            <LoginLogo src={IntraverseLogoFull} alt="Company Logo" />
          </Link>
          <Link to="/register">
            <CreateAccountButton> Create Account</CreateAccountButton>
          </Link>
        </HeaderDiv>
        <Container maxWidth="sm">
          {/* NAME AND COUNTRY PAGE */}

          {/* EMAIL AND PASSWORD PAGE */}

          {section === 'password-reset' && (
            <>
              {/* <StepText>STEP 2 OF 4</StepText> */}
              <LoginTitle>Create new password</LoginTitle>
              <LoginSubtitle>
                Enter a new password associated with this account.
              </LoginSubtitle>
              {message && <Typography sx={{ color: '#FF8484', mb: 3, textAlign: "center" }}>{message}</Typography>}
              <InputContainer>
                <NameInput type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                {/* PASSWORD CHECK BOX */}

                <PasswordValidBox>
                  <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      {lengthError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      8 characters long
                    </p>
                    <p>
                      {uppercaseError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One uppercase
                    </p>
                  </PasswordValidText>
                  <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      {' '}
                      {numberError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One number
                    </p>
                    <p>
                      {specialCharError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One special character
                    </p>
                  </PasswordValidText>
                </PasswordValidBox>
                <NameInput type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" />
                {/* PASSWORD CHECK BOX */}

                <PasswordValidBox>
                  <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      { !passwordMatches ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      Password matches
                    </p>
                  </PasswordValidText>
                </PasswordValidBox>
              </InputContainer>
              <ContinueButton
                style={{
                  background:
                    passwordMatches && validatePassword(password)
                      ? 'linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%)'
                      : '#504C5C',
                  color: passwordMatches && validatePassword(password) ? 'white' : '#9DA0A7',
                }}
                onClick={() => {
                  handleUpdatePassword();
                }}
              >
                Change Password
              </ContinueButton>
            </>
          )}

          {section === 'success' && (
            <SuccessContainer>
              {/* <StepText style={{ marginTop: '-1rem' }}>STEP 4 OF 4</StepText> */}
              <SuccessImg src={CheckmarkIcon} alt="Success checkmark" />
              <LoginTitle>Password Updated!</LoginTitle>
              <LoginSubtitle>You can now use your new password to sign in! Redirecting you to sign in...</LoginSubtitle>
            </SuccessContainer>
          )}

        </Container>
      </BorderOutlineStroke>
    </LoginContainer>
  );
}

// ----------------------------------------------------------------------

const BorderOutlineStroke = styled.div`
  z-index: 3000;
  border: 3px solid rgba(217, 217, 217, 0.2);
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    padding: 5px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 5px;
  }
`;

const LoginContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: rgba(27, 27, 35, 1);
  background-image: url(${DiamondBackground});
  background-size: 350px auto;
  background-position: center;
  height: 100vh;

  @media (min-width: 768px) {
    /* height: 100vh; */
    height: 100%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 100vh;
  }
`;

const LoginLogo = styled.img`
  display: flex;
  align-self: flex-start;
  width: 250px;
  height: auto;
  margin-bottom: 30px;
  margin-top: 10px;
  margin-left: 10px;

  @media (max-width: 768px) {
    align-self: center;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;

const SuccessImg = styled.img`
  display: flex;
  width: 250px;
  height: auto;
  margin-bottom: 30px;
  margin-top: 10px;
  margin-left: 10px;

  @media (max-width: 768px) {
    align-self: center;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
  margin-right: 10px;
  margin-left: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-top: 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LoginTitle = styled.h3`
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 960px) {
    font-size: 30px;
    margin-top: 10px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 40px;
  }
`;

const LoginSubtitle = styled.p`
  color: #ced1d9;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin: 1rem 0;
  @media (max-width: 960px) {
    margin: 1.5rem -10px;
  }
`;
const CreateAccountButton = styled.button`
  border-radius: 5px;
  border: 2px solid #beaeff;
  display: inline-flex;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-right: 1rem;
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-right: 0rem;
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    width: fit-content;
    margin-top: 10px;
  }
`;

const ContinueButton = styled.button`
  border-radius: 5px;
  width: 100%;
  border: none;
  display: inline-flex;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  background-color: #504c5c;
  color: #9da0a7;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-bottom: 1rem;
    font-size: 14px;
    padding: 15px 10px;
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const NameInput = styled.input`
  flex: 1;
  background: #1b1b23;
  border: 2px solid #504c5c;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0rem;
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  font-size: 18px;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  width: 100%;
  height: 70px;

  ::placeholder {
    color: #9da0a7;
    opacity: 50%;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PasswordValidBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  background: #2b2a38;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  padding: 0.7rem;
  margin-top: 10px;
  @media (max-width: 320px) {
    width: 100vw;
    padding: 5px;
    border-radius: 0px;
    margin-top: 5px;
  }
`;
const PasswordValidText = styled.div`
  flex-basis: 100%;
  & > p {
    color: #fbfcff;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
    margin-left: 5px;
  }
  @media (max-width: 320px) {
    width: 120%;
    font-size: 14px;
  }
`;

const SuccessContainer =  styled.div`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`