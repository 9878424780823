import { useState, useRef, useEffect } from 'react';
import { Container, Typography, Card, TextField, Box, Divider, IconButton, InputAdornment } from '@mui/material';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import Page from '../components/Page';
import { currentPasswordChecker, Logout } from '../sections/auth/api/Controller';
import { getUser, updateUser } from '../api/UsersController';
import profileMock from '../_mock/profile';
import { FormProvider } from '../components/hook-form';
import ProfileForm from '../components/ProfileForm';
import Iconify from '../components/Iconify';
import { PrimaryButton } from '../components/common/Buttons/CustomizedButton';

export default function Profile() {
  const token = useSelector((state) => state?.user?.token);
  const userId = useSelector((state) => state?.user?.userId);

  const { data, refetch } = useQuery(
    ['user'],
    async () => {
      const respData = await getUser(userId);
      return respData.data;
    },
    {
      enabled: false,
      retry: 1,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const currentPassword = useRef();
  const newPassword = useRef();
  const confirmNewPassword = useRef();
  const [passwordButton, setPasswordButton] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (data) {
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
      setDisplayName(data.displayName);
      let role = '';
      if (data.role === 'Superadmin') {
        role = 'Super Admin';
      } else {
        role = data.role.charAt(0).toUpperCase() + data.role.slice(1);
      }
      setRole(role);
    }
  }, [data]);

  const validateInput = () => {
    let errors = false;

    if (newPassword.current.value === currentPassword.current.value) {
      errors = true;
      setMessage('Your current password cannot be used as new password.');
    }
    if (newPassword.current.value.length === 0 || confirmNewPassword.current.value.length === 0) {
      errors = true;
      setMessage('Your new password cannot be empty.');
    }
    if (newPassword.current.value !== confirmNewPassword.current.value) {
      errors = true;
      setMessage('Your confirm new password is incorrect.');
    }
    if (profileMock.password === newPassword.current.value) {
      errors = true;
      setMessage('Your new password cannot be your current one.');
    }
    return errors;
  };

  const updateProfilePasswordHandler = async (e) => {
    e.preventDefault();

    if (!validateInput()) {
      const responsePassword = await currentPasswordChecker(currentPassword.current.value, token);
      if (responsePassword.status === 400) {
        setError(false);
        setSuccess(true);
        setMessage(responsePassword.message);
      }
      if (responsePassword.status === 200) {
        const udpatedData = {
          userId,
          password: newPassword.current.value,
        };
        const resUpdateData = await updateUser(token, udpatedData);
        if (resUpdateData.status === 200) {
          setError(false);
          setSuccess(true);
          setMessage('Profile password successfully updated. Please login with new password.');
          newPassword.current.value = '';
          confirmNewPassword.current.value = '';
          currentPassword.current.value = '';
          setTimeout(() => {
            Logout();
          }, 3000);
        } else {
          setError(true);
          setSuccess(false);
          setMessage('Profile password not updated. Please try again!');
          newPassword.current.value = '';
          confirmNewPassword.current.value = '';
          currentPassword.current.value = '';
        }
      }
    } else {
      setSuccess(false);
      setError(true);
    }
  };

  let sendProfileData = {};

  if (firstName !== undefined) {
    sendProfileData = {
      name: firstName,
      lastName,
      displayName,
      email,
      role,
    };
  }

  return (
    <Page title="Profile">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {role} Profile
        </Typography>
        <Card sx={{ alignContent: 'center', p: 1 }}>
          <ProfileForm user={sendProfileData} />

          <Divider sx={{ m: 'auto', my: 4, width: '100%' }} color="#1E1E1E" variant="middle" />

          <FormProvider methods={null} onSubmit={updateProfilePasswordHandler}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Reset Password
            </Typography>
            <Box width="100%" height={80} alignItems="center" marginBottom="100px">
              <TextField
                id="profileCurrentPassword"
                label="Current Password"
                variant="outlined"
                style={{ width: '40%', marginRight: '60px', marginBottom: '20px' }}
                size="small"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={currentPassword}
              />{' '}
              <br />
              <TextField
                type={showNewPassword ? 'text' : 'password'}
                id="profileNewPassword1"
                label="New Password"
                variant="outlined"
                style={{ width: '40%', marginRight: '60px', marginBottom: '20px' }}
                size="small"
                inputRef={newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                        <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              <TextField
                type={'password'}
                id="profileNewPassword2"
                label="Confirm New Password"
                variant="outlined"
                style={{ width: '40%', marginRight: '60px' }}
                size="small"
                inputRef={confirmNewPassword}
                onChange={() => setPasswordButton(false)}
              />
              <br />
            </Box>
            <Box sx={{ mb: 1.5 }}>
              <PrimaryButton showMessage={message} error={error} success={success} disabled={passwordButton}>
                Confirm
              </PrimaryButton>
            </Box>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}
