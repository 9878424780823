import { API_GET, API_POST, API_DELETE } from './API_FETCH';
import { COREAPI_WEB_USERS_URL } from '../constants';
import { getFromLocalStorage } from '../hooks/useLocalStorage';
import * as spotifyConstants from '../constants';
import axiosInstance from '../config/axiosConfig';

const COREAPIURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_USERS_URL}`;

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export async function RegisterUser(data) {
  try {
    return await API_POST(`${COREAPIURL}/admin`, data, '', 'POST');
  } catch (err) {
    return 500;
  }
}

export async function getUsers(page = 0, rowsPerPage = 5, filterName = '', orderBy = '', order = 'asc') {
  try {
    const endpoint = `${COREAPIURL}`;
    const params = {
      page: page + 1,
      perPage: rowsPerPage,
    };

    if (filterName) {
      params.search = filterName;
    }

    if (orderBy) {
      params.orderBy = orderBy;
      params.order = order;
    }

    const response = await axiosInstance.get(endpoint, {
      params,
    });

    if (response.status === 200) {
      const respData = response.data;
      for (let index = 0; index < respData.length; index += 1) {
        const element = respData[index];
        element.bgColor = getRandomColor();
      }
      return respData;
    }
    return null;
  } catch (err) {
    throw new Error(err);
  }
}

export async function getClients(token) {
  try {
    return await API_GET(`${COREAPIURL}/clients/customers`, token);
  } catch (err) {
    return 500;
  }
}

export async function getRoles(token) {
  try {
    return await API_GET(`${COREAPIURL}/roles`, token);
  } catch (err) {
    return 500;
  }
}

export async function getUser(userId) {
  try {
    const response = await axiosInstance.get(`${COREAPIURL}/${userId}`);
    return response.data;
  } catch (err) {
    return err.status;
  }
}

export async function updateUser(token, data) {
  try {
    return await API_POST(`${COREAPIURL}/admin`, data, token, 'PUT');
  } catch (err) {
    return 500;
  }
}

export async function blockUser(userId, token, reason) {
  try {
    const data = {
      type: 'block',
      reason,
      blockedUserId: userId,
    };
    const dataResp = await API_POST(`${COREAPIURL}/block`, data, token, 'PUT');
    if (dataResp.status !== 201) {
      if (dataResp.message === 'Key already exists.') {
        const data = { message: 'Already prohibited user.', status: 400 };
        return data;
      }
      if (dataResp.message === 'Missing parameter type.') {
        const data = { message: 'Please provide a reason for blocking.', status: 400 };
        return data;
      }
    }
    if (dataResp.status === 201) {
      const data = { message: 'User successfully blocked.', status: 201 };
      return data;
    }
    return { message: 'Unexpected response from the server.', status: dataResp.status };
  } catch (err) {
    return 500;
  }
}

export async function unblockUser(userId, token) {
  try {
    const data = {
      type: 'unblock',
      reason: '',
      blockedUserId: userId,
    };
    const dataResp = await API_POST(`${COREAPIURL}/block`, data, token, 'PUT');
    return dataResp;
  } catch (error) {
    return 500;
  }
}

export async function deleteUser(token, userId) {
  try {
    const dataResp = await API_DELETE(`${COREAPIURL}/${userId}`, token);
    return dataResp;
  } catch (error) {
    return 500;
  }
}

export async function sendResetEmail(email, passwordResetURL) {
  try {
    const data = { email, passwordResetURL };
    return await API_POST(`${COREAPIURL}/password`, data, '', 'POST');
  } catch (err) {
    console.log(err);
    return 500;
  }
}

export async function getMerchants(token) {
  try {
    return await API_GET(`${COREAPIURL}/`, token);
  } catch (err) {
    console.log(err);
    return 500;
  }
}

// SPOTIFY CONTROLLERS
export async function refreshUserToken(userId) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId }),
    };

    const response = await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/users/auth-session`, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error('Error during refresh token:', error);
    return null;
  }
}

export async function signUpUser(displayName, email) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ displayName, email }),
    };

    const response = await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/users/login`, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return data;
    }

    return null;
  } catch (error) {
    console.error('Error during singn up:', error);
    return null;
  }
}

export const logStreaming = async (song, uri) => {
  const userId = getFromLocalStorage('userId');
  const userSession = getFromLocalStorage('userToken');
  if (!userId) {
    return;
  }

  await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/spotify/misc/teddys-lounge-spotify-tracking`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userSession}`,
    },
    body: JSON.stringify({
      analyticsData: {
        trackId: uri,
        sessionId: userSession,
        userId,
        datetimeStarted: new Date(),
        song,
        durationInSeconds: spotifyConstants.STREAM_SECONDS,
      },
    }),
  });
};

export async function sendTeddyNoteToDb(name, message, userToken) {
  try {
    const response = await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/users/action-log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({
        analyticsData: {
          name,
          message,
        },
      }),
    });
    if (response.ok) {
      return response.ok;
    }
  } catch (error) {
    console.error('Error sending message:', error);
    return null;
  }
  return null;
}
