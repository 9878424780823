import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import PlaceIcon from '@mui/icons-material/Place';
import MailIcon from '@mui/icons-material/Mail';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CreateIcon from '@mui/icons-material/Create';
import { first } from 'lodash';
import usernameIcon from './userPortalImages/usernameIcon.png';
import birthdayIcon from './userPortalImages/Birthday.png';
import ProfileBanner from './userPortalImages/Profile_Banner_Pro.png';
import USFlag from './userPortalImages/USFlag.png';
import CanadaFlag from './userPortalImages/CanadaFlag.png';
import { EditContentModal } from './modal/EditContentModal';
import { getUserProfileData } from '../../sections/auth/api/Controller';

const UserPortalGeneralProfile = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [editEmailModal, setEditEmailModal] = useState(false);
  const [editPassModal, setEditPassModal] = useState(false);
  const [editBirthdayModal, setEditBirthdayModal] = useState(false);
  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [birthday, setBirthday] = useState(null);

  // User Details states
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [username, setUsername] = useState('');
  // const [phoneNumber, setPhoneNumber] = useState('');

  const token = useSelector((state) => state?.user?.token);
  const userId = useSelector((state) => state?.user?.userId);
  const userEmail = useSelector((state) => state?.user?.email);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    const aspectRatio = innerWidth / innerHeight;
    return { innerWidth, innerHeight, aspectRatio };
  }

  const handleClose = () => {
    setEditEmailModal(false);
    setEditPassModal(false);
    setEditBirthdayModal(false);
    setEditDetailsModal(false);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  };

  const handlePageLoad = async () => {
    const userData = await getUserProfileData(token, userId);
    const { userBirthday, displayName, firstName, lastName, userCountry } = userData.data;

    console.log('userdata', userData.data);
    console.log('country', userData.data.userCountry);

    if (userBirthday !== null && userBirthday !== undefined) {
      const apiDate = new Date(userBirthday);
      console.log('api date', apiDate);
      // apiDate.setDate(apiDate.getDate() + 1);
      const formattedDate = apiDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      setBirthday(formattedDate);
    } else {
      setBirthday(null);
    }

    if (displayName) {
      setUsername(displayName);
    }
    if (firstName) {
      setFirstName(firstName);
    }
    if (lastName) {
      setLastName(lastName);
    }
    if (userCountry) {
      setCountry(userCountry);
    }
  };

  useEffect(() => {
    handlePageLoad();
  }, [
    birthday,
    username,
    firstName,
    lastName,
    country,
    editBirthdayModal,
    editDetailsModal,
    editEmailModal,
    editPassModal,
  ]);

  return (
    <ProfileContainer>
      <InnerContainer>
        <BannerContainer windowSize={windowSize}>
          <ColumnContentContainer>
            <RowContentContainer>
              <img src={usernameIcon} alt="usernameIcon" />
              <BannerName>
                {username}
                {/* <CreateIcon
                  titleAccess="Edit Profile Details"
                  onClick={() => {
                    setEditDetailsModal(true);
                  }}
                  sx={{ color: '#7A7784', cursor: 'pointer', '&:hover': { color: '#beaeff' } }}
                /> */}
              </BannerName>
            </RowContentContainer>
            {country !== '' && country !== null && country !== undefined && (
              <UsernameText>
                <img
                  style={country === 'Canada' ? { height: '35px', width: '35px', marginTop: '3px' } : null}
                  src={country === 'Canada' ? CanadaFlag : USFlag}
                  alt="US Flag"
                />
                {country}
              </UsernameText>
            )}
          </ColumnContentContainer>
        </BannerContainer>
        <UserConsoleText style={{ marginTop: '2rem' }}>General Details</UserConsoleText>
        <ColoredContainer>
          <CreateIcon
            titleAccess="Edit Profile Details"
            onClick={() => {
              setEditDetailsModal(true);
            }}
            sx={{ float: 'right', color: '#7A7784', cursor: 'pointer', '&:hover': { color: '#beaeff' } }}
          />
          <UsernameText style={{ marginLeft: windowSize < 768 ? '30px' : '0ox' }}>
            <img src={usernameIcon} alt="usernameIcon" />
            <p>{username}</p>
          </UsernameText>
          <RowContentContainer>
            <RowContentContainer style={{ flexDirection: 'row' }}>
              <UsernameSubtitle>
                <PersonIcon style={{ verticalAlign: 'bottom', paddingBottom: '3px', color: '#beaeff' }} />
                {firstName} {lastName}
              </UsernameSubtitle>
            </RowContentContainer>
            {/* {phoneNumber !== '' &&
              phoneNumber !== null &&
              phoneNumber !==
                undefined(
                  <RowContentContainer style={{ flexDirection: 'row' }}>
                    <CallIcon style={{ verticalAlign: 'bottom', marginRight: '5px', color: '#beaeff' }} />
                    <UsernameSubtitle>{phoneNumber}</UsernameSubtitle>
                  </RowContentContainer>
                )} */}
            {country !== '' && country !== null && country !== undefined && (
              <RowContentContainer style={{ flexDirection: 'row' }}>
                <PlaceIcon style={{ verticalAlign: 'bottom', marginRight: '5px', color: '#beaeff' }} />
                <UsernameSubtitle>{country}</UsernameSubtitle>
              </RowContentContainer>
            )}
          </RowContentContainer>
        </ColoredContainer>
        <UserConsoleText>Birthday</UserConsoleText>
        <SmallSubtitle>
          Let Intraverse know your birthday! We may send you a gift or exclusive offers and deals on your special day!
        </SmallSubtitle>
        {birthday === null || birthday === undefined ? (
          <PinkButton
            onClick={() => {
              setEditBirthdayModal(true);
            }}
          >
            <p>ADD MY BIRTHDAY</p>
          </PinkButton>
        ) : (
          <Inputcontainer>
            <img
              src={birthdayIcon}
              alt="birthdayIcon"
              style={{ margin: '5px', color: '#beaeff', cursor: 'default', height: '20px', width: '20px' }}
            />
            <Input
              onClick={() => {
                setEditBirthdayModal(true);
              }}
              type="text"
              placeholder={birthday}
            />
            <CreateIcon
              onClick={() => {
                setEditBirthdayModal(true);
              }}
              sx={{ '&:hover': { color: '#beaeff' }, margin: '5px', color: 'grey' }}
            />
          </Inputcontainer>
        )}
        <BorderContainer>
          <PaddedContainer>
            <UserConsoleText>Login and Password</UserConsoleText>
            <SmallSubtitle>The information below is what you currently use to log in Intraverse.</SmallSubtitle>
            <RowContentContainer>
              <Inputcontainer>
                <MailIcon style={{ margin: '5px', color: '#beaeff', cursor: 'default' }} />
                <Input readonly type="email" placeholder="Username" value={userEmail} />
                <CreateIcon
                  onClick={() => {
                    setEditEmailModal(true);
                  }}
                  sx={{ '&:hover': { color: '#beaeff' }, margin: '5px', color: 'grey' }}
                />
              </Inputcontainer>
              <Inputcontainer>
                <VpnKeyIcon style={{ margin: '5px', color: '#beaeff', cursor: 'default' }} />
                <Input readonly type="password" placeholder="Password" />
                <CreateIcon
                  onClick={() => setEditPassModal(true)}
                  sx={{ '&:hover': { color: '#beaeff' }, margin: '5px', color: 'grey' }}
                />
              </Inputcontainer>
            </RowContentContainer>
          </PaddedContainer>
        </BorderContainer>
        <BorderContainer>
          <PaddedContainer>
            <UserConsoleText>Account Removal</UserConsoleText>
            <SmallSubtitle>
              Please contact our support team <span>hello@terrazero.com</span> to temporarily disable or{' '}
              <strong> permanently </strong> delete your account.
            </SmallSubtitle>
            <OulinedButton
              onClick={() => {
                window.location = 'mailto:hello@terrazero.com';
              }}
            >
              <p>Contact Support</p>
            </OulinedButton>
          </PaddedContainer>
          <PinkButton style={{ marginLeft: '2rem' }} onClick={handleLogout}>
            <p>Logout</p>
          </PinkButton>
        </BorderContainer>
      </InnerContainer>
      {editEmailModal && (
        <EditContentModal open={editEmailModal} userEmail={userEmail} isEmail handleClose={handleClose} />
      )}
      {editPassModal && <EditContentModal open={editPassModal} isPassword handleClose={handleClose} />}
      {editBirthdayModal && (
        <EditContentModal
          open={editBirthdayModal}
          isBirthday
          handleClose={handleClose}
          setCurrentBirthday={setBirthday}
        />
      )}
      {editDetailsModal && <EditContentModal open={editDetailsModal} isUserData handleClose={handleClose} />}
    </ProfileContainer>
  );
};

export default UserPortalGeneralProfile;

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  background-color: transparent;
  z-index: 1000;
  padding: 10px 0px;

  @media (max-width: 768px) {
    padding: 15px 0px;
    margin-top: -1rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 15px 25px;
  }

  @media (min-height: 742px) {
    padding: 0px;
  }
`;

const UserConsoleText = styled.h1`
  color: #beaeff;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin: 1rem 0rem;
`;
const BannerName = styled.h1`
  color: #beaeff;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin: 1rem 0rem;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  background-color: #121218;
  padding: 2rem;
  overflow-x: hidden;
`;
const BannerContainer = styled.div`
  display: flex;
  background-image: url(${ProfileBanner});
  background-size: 100% 95%;
  background-repeat: no-repeat;
  max-width: 1280px;
  height: auto;
  padding: 1rem 4rem 4rem 4rem;
  align-items: center;
  justify-content: center;
  margin-left: -33px;
  margin-right: -33px;

  & img {
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    background-size: cover;
  }
`;

const BorderContainer = styled.div`
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  padding: 20px 0;
  margin: 40px -30px;
`;
const PaddedContainer = styled.div`
  padding: 10px 30px;
`;
const RowContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;

  @media (max-width: 768px) {
    flex-direction: column;

    & img {
      margin-left: 3rem;
    }
  }
`;
const ColumnContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
`;

const UsernameText = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin-bottom: 10px;
  color: #fbfcff;
  font-family: 'Barlow', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;

  & img {
    margin-right: 15px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;

    & img {
      margin-right: -35px;
    }

    & p {
      margin-left: 40px;
    }
  }
`;
const UsernameSubtitle = styled.p`
  text-align: left;
  padding-right: 40px;
  color: #fbfcff;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;

  @media (max-width: 768px) {
    padding-right: 0px;
    text-align: center;
  }
`;

const ColoredContainer = styled.div`
  border-radius: 10px;
  background: rgba(190, 174, 255, 0.1);
  padding: 1rem 1rem 1rem 2rem;
  margin: 30px 0px 50px 0px;

  @media (max-width: 768px) {
    padding: 0.5rem;
    text-align: center;
  }
`;

const Input = styled.input`
  flex: 1;
  border: none;
  background: none;
  width: 90%;
  height: 50px;
  color: white;
  margin-left: 8px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(35, 34, 48) inset !important;
    -webkit-text-fill-color: white !important;
  }
`;
const Inputcontainer = styled.div`
  display: flex;
  flex: 1;
  margin-right: 8px;
  border-radius: 10px;
  justify-content: center;
  background: rgba(190, 174, 255, 0.1);
  cursor: pointer;
  height: 50px;
  align-items: center;
  padding: 5px;

  @media (max-width: 768px) {
    margin: 5px;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin: 5px;
    width: 100%;
  }
`;

const SmallSubtitle = styled.p`
  text-align: left;
  color: #ced1d9;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > span {
    color: #beaeff;
    font-weight: 700;
  }
`;

const PinkButton = styled.button`
  display: inline-flex;
  padding: 15px 45px;
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
  cursor: pointer;
  margin: 1rem 0rem 2rem 0rem;

  & p {
    color: #fbfcff;
    font-family: 'Barlow', sans-serif;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const OulinedButton = styled.button`
  display: inline-flex;
  padding: 10px 45px;
  border: 2px solid #beaeff;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: none;
  cursor: pointer;
  margin: 2rem 0rem;

  & p {
    color: #beaeff;
    font-family: 'Barlow', sans-serif;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
`;
