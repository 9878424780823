import {
  Container,
  Typography,
  Card,
  TextField,
  Box,
  Button,
  Avatar,
  Stack,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  Chip,
  Grid,
  useTheme,
  Link,
  Alert,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import ConnectedMerchantUsersTable from '../../components/tables/ConnectedMerchantUsersTable';
import { FormProvider } from '../../components/hook-form';
import { PrimaryButton } from '../../components/common/Buttons/CustomizedButton';
import Iconify from '../../components/Iconify';
import { getMerchant, updateMerchant } from '../../api/MerchantsController';
import { getUser } from '../../api/UsersController';
import Page from '../../components/Page';

const MAXIMUM_BYTES_IMAGE = 200000;

function getStyles(name, projects, theme) {
  return {
    fontWeight: projects.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function MerchantProfile() {
  const user = useSelector((state) => state?.user?.user);
  const token = useSelector((state) => state?.user?.token);
  const userId = useSelector((state) => state?.user?.userId);
  const [allRoles, setAllRoles] = useState([]);
  const navigate = useNavigate();
  const paramsId = useParams();
  const { data, refetch } = useQuery(
    ['user'],
    async () => {
      const resp = await getMerchant(token, paramsId.id);
      const users = [];
      if (resp.merchant) {
        await Promise.all(
          resp.merchantUsers.map(async (merchantUser) => {
            const respUser = await getUser(merchantUser.userId);
            users.push(respUser.data[0]);
          })
        );
        resp.users = users;
        return resp;
      }
    },
    {
      enabled: false,
      retry: 1,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const [loading, setLoading] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const theme = useTheme();
  const [projects, setProjects] = useState([]);
  const [products, setProducts] = useState([]);
  const [name, setName] = useState(null);
  const [businessType, setBusinessType] = useState(null);
  const [merchantName, setMerchantName] = useState(null);
  const [unitNumber, setUnitNumber] = useState(null);
  const [street, setStreet] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [email, setEmail] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [image, setImage] = useState(null);
  const [status, setStatus] = useState(null);
  const [icon, setIcon] = useState(null);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [modalError, setModalError] = useState();
  const [disPlayTextError, setDisplayTextError] = useState(false);
  const [updateAlertMsg, setUpdateAlertMsg] = useState(null);
  const [updateAlertStatus, setUpdateAlertStatus] = useState(null);
  const isStatusColor = status === true ? '#BD3044' : 'green';

  useEffect(() => {
    if (data) {
      setMerchantName(data?.merchant?.merchantName);
      setBusinessType(data?.merchant?.merchantBusinessType);
      // const fullAddress = `${data?.merchant?.unitNumber}, ${data?.merchant?.street}, ${data?.merchant?.city}, ${data?.merchant?.postalCode}, ${data?.merchant?.country}`;
      setUnitNumber(data?.merchant?.unitNumber);
      setStreet(data?.merchant?.street);
      setCity(data?.merchant?.city);
      setPostalCode(data?.merchant?.postalCode);
      setCountry(data?.merchant?.country);
      setEmail(data?.merchant?.merchantContactEmail);
      setImage(`/static/images/avatar/${data.merchantId}.jpg`);
      setStatus(data?.merchant?.status);
      const dateCreated = new Date(data?.merchant?.createdAt);
      const formattedDateCreated = dateCreated.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      setCreatedAt(formattedDateCreated);
      const dateUpdated = new Date(data?.merchant?.updatedAt);
      const formattedDateUpdated = dateUpdated.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      setUpdatedAt(formattedDateUpdated);
    }
  }, [data]);

  if (updateAlertStatus === 400 || updateAlertStatus === 200 || updateAlertStatus === 500) {
    setTimeout(() => {
      setUpdateAlertMsg(null);
      setUpdateAlertStatus(null);
      window.location.reload();
    }, 3000);
  }
  const changeBusinessType = (e) => {
    if (data?.businessType !== e.target.value) {
      setBusinessType(e.target.value);
      setDisabledSubmitBtn(false);
    }
  };
  const changeUnitNumber = (e) => {
    setUnitNumber(e.target.value);
    if (data?.merchant?.unitNumber !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };
  const changeMerchantName = (e) => {
    setMerchantName(e.target.value);
    if (data?.merchant?.merchantName !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };
  const changeEmailHandler = (e) => {
    setEmail(e.target.value);
    if (data?.merchant?.merchantContactEmail !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };
  const changeStreet = (e) => {
    setStreet(e.target.value);
    if (data?.merchant?.street !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };
  const changePostalCode = (e) => {
    setPostalCode(e.target.value);
    if (data?.merchant?.postalCode !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };
  const changeCountry = (e) => {
    setCountry(e.target.value);
    if (data?.merchant?.country !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };
  const changeCity = (e) => {
    setCity(e.target.value);
    if (data?.merchant?.city !== e.target.value) {
      setDisabledSubmitBtn(false);
    }
  };
  const validateInput = () => {
    const errorList = {
      merchantName: '',
      businessType: '',
      unitNumber: '',
      street: '',
      city: '',
      postalCode: '',
      country: '',
      email: '',
    };
    let errors = false;
    if (!merchantName || merchantName.length === 0) {
      errors = true;
      errorList.name = 'Please provide a merchant name.';
    }
    if (!businessType || businessType.length === 0) {
      errors = true;
      errorList.name = 'Please provide a business type.';
    }
    if (!unitNumber || unitNumber.length === 0) {
      errors = true;
      errorList.name = 'Please provide a unit number.';
    }
    if (!street || street.length === 0) {
      errors = true;
      errorList.name = 'Please provide a street.';
    }
    if (!city || city.length === 0) {
      errors = true;
      errorList.name = 'Please provide a city.';
    }
    if (!postalCode || postalCode.length === 0) {
      errors = true;
      errorList.name = 'Please provide a postal code.';
    }
    if (!country || country.length === 0) {
      errors = true;
      errorList.name = 'Please provide a country.';
    }
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w\w+)+$/;
    if (!email || email === 0 || reg.test(email) === false) {
      errors = true;
      errorList.email = 'Please provide a valid email.';
    }
    setModalError(errorList);
    setDisplayTextError(errors);
    return errors;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);
    const dataUpdate = {
      merchantName,
      merchantBusinessType: businessType,
      unitNumber,
      street,
      city,
      postalCode,
      country,
      merchantContactEmail: email,
    };
    if (!validateInput()) {
      const newEmail = email;
      const updateIcon = data?.icon !== image;
      const respMerchantUpdate = await updateMerchant(token, data?.merchant?.merchantId, dataUpdate);
      if (respMerchantUpdate.status === 200) {
        setUpdateAlertStatus(respMerchantUpdate.status);
        setUpdateAlertMsg(respMerchantUpdate.message);
      } else if (respMerchantUpdate.status === 400 || respMerchantUpdate.status === 500) {
        if (respMerchantUpdate.error) {
          setUpdateAlertStatus(respMerchantUpdate.status);
          setUpdateAlertMsg(respMerchantUpdate.error[0].msg);
        } else {
          setUpdateAlertMsg('An error occured. Please try again.');
          setUpdateAlertStatus(respMerchantUpdate.status);
        }
      } else {
        setUpdateAlertMsg('An error occured. Please try again.');
        setUpdateAlertStatus(respMerchantUpdate.status);
      }
    } else {
      setSuccess(false);
      setError(true);
      setMessage('Please correct errors');
    }
    setLoading(false);
  };

  const handleImageChange = (event) => {
    if (!event.target.files[0].type === 'image/jpeg') {
      setUploadErrorMessage('Please upload an image type');
    } else if (event.target.files[0].size >= MAXIMUM_BYTES_IMAGE) {
      setUploadErrorMessage('The uploaded Image file is too large');
    } else {
      const imageURL = URL.createObjectURL(new File(event.target.files, 'image'));
      setImage(imageURL);
      setIcon(event.target.files[0]);
      setDisabledSubmitBtn(false);
    }
  };

  return (
    <Page title="Merchant Profile">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Merchant Profile: {data?.merchant?.merchantName}
          <Link
            variant="h5"
            component={RouterLink}
            to="/dashboard/merchant"
            sx={{ textDecoration: 'none', color: '#000', float: 'right', mr: '12%' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '100px' }}>
              <Iconify icon="bx:arrow-back" fontSize="20px" /> Back
            </div>
          </Link>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Card
              sx={{
                alignContent: 'center',
                p: 1,
                height: 'auto',
              }}
            >
              <FormProvider methods={null} onSubmit={submitHandler}>
                <Box display="flex" width="100%" height={80} alignItems="center" justifyContent="left">
                  <TextField
                    id="merchantName"
                    label="Merchant Name"
                    variant="outlined"
                    style={{ width: '48.5%', marginRight: '20px' }}
                    size="small"
                    value={merchantName || ''}
                    onChange={changeMerchantName}
                    helperText={disPlayTextError && modalError.merchantName}
                    error={disPlayTextError && modalError.merchantName.length > 0 && true}
                  />
                  <Stack direction="row" spacing={2} sx={{ float: 'left', marginLeft: '10px' }}>
                    <Avatar alt="Merchant Image" src={image} sx={{ width: 80, height: 80 }} />
                    <Typography variant="p" component="p">
                      {uploadErrorMessage}
                    </Typography>
                    <Button
                      color="chip"
                      size="large"
                      variant="contained"
                      sx={{ boxShadow: 12, padding: '0 40px', alignSelf: 'center' }}
                      component="label"
                    >
                      Change Icon
                      <input type="file" hidden onChange={handleImageChange} accept="image/*" />
                    </Button>
                  </Stack>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  marginTop={1}
                  height={60}
                  alignItems="center"
                  justifyContent="right"
                  sx={{ float: 'right' }}
                >
                  <TextField
                    id="businessType"
                    label="Business Type"
                    variant="outlined"
                    style={{ width: '50%', marginRight: '20px' }}
                    size="small"
                    value={businessType || ''}
                    onChange={changeBusinessType}
                    placeholder={data?.merchant?.merchantBusinessType}
                    helperText={disPlayTextError && modalError.businessType}
                    error={disPlayTextError && modalError.businessType.length > 0 && true}
                  />
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    style={{ width: '50%', marginRight: '5px' }}
                    size="small"
                    value={email || ''}
                    placeholder={data?.merchant?.email}
                    onChange={changeEmailHandler}
                    helperText={disPlayTextError && modalError.email}
                    error={disPlayTextError && modalError.email.length > 0 && true}
                  />
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  marginTop={1}
                  height={60}
                  alignItems="center"
                  justifyContent="right"
                  sx={{ float: 'right' }}
                >
                  <TextField
                    id="unitNumber"
                    label="Unit Number"
                    variant="outlined"
                    style={{ width: '50%', marginRight: '20px' }}
                    size="small"
                    value={unitNumber || ''}
                    placeholder={data?.merchant?.unitNumber}
                    onChange={changeUnitNumber}
                    helperText={disPlayTextError && modalError.unitNumber}
                    error={disPlayTextError && modalError.unitNumber.length > 0 && true}
                  />
                  <TextField
                    id="street"
                    label="Street"
                    variant="outlined"
                    style={{ width: '50%', marginRight: '5px' }}
                    size="small"
                    value={street || ''}
                    placeholder={data?.merchant?.street}
                    onChange={changeStreet}
                    helperText={disPlayTextError && modalError.street}
                    error={disPlayTextError && modalError.street.length > 0 && true}
                  />
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  marginTop={1}
                  height={60}
                  alignItems="center"
                  justifyContent="right"
                  sx={{ float: 'right' }}
                >
                  <TextField
                    id="city"
                    label="City"
                    variant="outlined"
                    style={{ width: '50%', marginRight: '20px' }}
                    size="small"
                    value={city || ''}
                    placeholder={data?.merchant?.city}
                    onChange={changeCity}
                    helperText={disPlayTextError && modalError.city}
                    error={disPlayTextError && modalError.city.length > 0 && true}
                  />
                  <TextField
                    id="postalCode"
                    label="Postal Code"
                    variant="outlined"
                    style={{ width: '50%', marginRight: '5px' }}
                    size="small"
                    value={postalCode || ''}
                    placeholder={data?.merchant?.postalCode}
                    onChange={changePostalCode}
                    helperText={disPlayTextError && modalError.postalCode}
                    error={disPlayTextError && modalError.postalCode.length > 0 && true}
                  />
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  sx={{ ml: 0, float: 'left', marginTop: 2.5 }}
                >
                  <TextField
                    id="country"
                    label="Country"
                    variant="outlined"
                    style={{ width: '48.7%', marginRight: '5px' }}
                    size="small"
                    value={country || ''}
                    onChange={changeCountry}
                    helperText={disPlayTextError && modalError.country}
                    error={disPlayTextError && modalError.country.length > 0 && true}
                  />
                  <div style={{ marginLeft: '15px' }}>
                    {updateAlertStatus === 400 ||
                      (updateAlertStatus === 500 && <Alert severity="error">{updateAlertMsg}</Alert>)}
                    {updateAlertStatus === 200 && <Alert severity="success">{updateAlertMsg}</Alert>}
                  </div>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  alignItems="flex-end"
                  justifyContent="left"
                  sx={{ ml: 0, float: 'left', marginTop: 2.5 }}
                >
                  <PrimaryButton
                    loading={loading}
                    backgroundColor="#0084F4"
                    disabled={disabledSubmitBtn}
                    showMessage={message}
                    error={error}
                    success={success}
                  >
                    Save Changes
                  </PrimaryButton>
                  <Link
                    variant="subtitle2"
                    component={RouterLink}
                    to="/dashboard/merchant"
                    sx={{ textDecoration: 'none' }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ mr: 4, padding: '0 40px' }}
                      color="cancel"
                      fontFamily="tomorrow"
                    >
                      Cancel
                    </Button>
                  </Link>
                </Box>
              </FormProvider>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card
              sx={{
                alignContent: 'center',
                p: 1,
                width: '50%',
                height: 'auto',
                ml: 8,
                pl: 2.5,
              }}
            >
              <Typography variant="subtitle2" sx={{ mb: 0 }}>
                Created At
              </Typography>
              <Typography variant="body2." sx={{ mb: 9 }}>
                {createdAt}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Last Update
              </Typography>
              <Typography variant="body2." sx={{ mb: 5 }}>
                {updatedAt}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Status
              </Typography>
              <Typography variant="body2." sx={{ mb: 5, fontWeight: 'bold' }} color={isStatusColor}>
                {status === true ? 'Blocked' : 'Active'}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <ConnectedMerchantUsersTable dataUsers={data?.users} />
    </Page>
  );
}
