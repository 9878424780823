import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import { fetchVanityLinkData } from '../../api/VanityLinkController';
import { SaveVanityLink } from '../../redux/actions/VanityLinkActions';
import AvatarPresetPage from './AvatarPresetPage';
import VanityURLRedirects from '../../VanityURLRedirects';
import { getFromLocalStorage, setToLocalStorage, clearAllLocalStorage } from '../../hooks/useLocalStorage';
import IntraverseLogoIcon from '../../assets/images/Intraverse_Logo_Icon.png';
import { getSpotifyToken, getProfile } from '../../feature/spotify/api/SpotifyController';
import EmailForm from './EmailForm';
import NameCollectionForm from './NameCollectionForm';
import GenericTCModal from '../../components/modal/GenericTCModal';
import { LogInWithForgerock } from '../../sections/auth/api/Controller';
import Banner from '../../components/intraverse/ui/Banner';

const GenericLoginPage = () => {
  const dispatch = useDispatch(); // Get the dispatch function
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  // Extracting the ID from the URL. This assumes that the wildcard path captures the ID. Adjust accordingly.
  const pathId = window.location.pathname.split('/').pop();

  const [navigateBackToEmailPage, setNavigateBackToEmailPage] = useState(false);
  const [renderComponent, setRenderComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // User States
  const [userId, setUserId] = useState(getFromLocalStorage('userId'));
  const [userToken, setUserToken] = useState(getFromLocalStorage('userToken'));
  const [displayName, setDisplayName] = useState(getFromLocalStorage('displayName'));
  const [email, setEmail] = useState(getFromLocalStorage('email'));
  const [vanityLinkData, setVanityLinkData] = useState({});
  const hasAcceptedTerms = localStorage.getItem('acceptedTerms') === 'true';
  const [acceptedTerms, setAcceptedTerms] = useState(hasAcceptedTerms);
  const [checkboxStatus, setCheckboxStatus] = useState(true);
  setToLocalStorage('fullscreen', checkboxStatus);
  const [selectedAvatar, setSelectedAvatar] = useState(getFromLocalStorage('selectedAvatar'));

  // Spotify States
  const [spotifyNotPremiumAccount, setSpotifyNotPremiumAccount] = useState(false);
  const [spotifyToken, setSpotifyToken] = useState(getFromLocalStorage('spotifyAccessToken'));

  const [forgeRockError, setForgeRockError] = useState('');
  const [spotifyError, setSpotifyError] = useState('');

  useEffect(() => {
    setIsLoading(true);

    const getVanityLinkData = async (pathId) => {
      try {
        const data = await fetchVanityLinkData(pathId);
        setVanityLinkData(data);
        dispatch(
          SaveVanityLink(
            data.clientLogo,
            data.backgroundImage,
            data.freeformJson,
            data.clientName,
            data.emailLogin,
            data.spotifyLogin,
            data.broadcast,
            data.ambientTrack
          )
        );
      } catch (error) {
        if (process.env.REACT_APP_REDIRECT_TOGGLE) {
          window.location.replace('https://intraverse.com/');
        }
        console.error('Error fetching the vanity link data:', error);
        // Handle this error accordingly. Maybe redirect them to a 404 page or a custom error page?
      }
    };

    if (pathId === '[object%20Object]') {
      return;
    }

    if (pathId) {
      getVanityLinkData(pathId);
    }

    if (code && pathId === 'estee-lauder') {
      const fetchForgeRockData = async () => {
        const resp = await LogInWithForgerock(code);

        if (resp.status !== 200) {
          if (resp.data.error && resp.data.error_description) {
            setForgeRockError(`${resp.data.error_description} Please try again!`);
          } else {
            setForgeRockError('Unable to Login. Please try after sometime.');
          }
        }
        if (resp.status === 200) {
          const userInfo = resp.data;
          setToLocalStorage('email', userInfo.email);
          setToLocalStorage('username', userInfo.sub);
          setToLocalStorage('token', userInfo.token);
          setToLocalStorage('userId', userInfo.userId);
          setEmail(userInfo.email);

          if (getFromLocalStorage('email')) {
            window.location.href = `/estee-lauder`;
          }
        }
      };

      fetchForgeRockData();
    }

    if (code && pathId === 'teddyswims') {
      getSpotifyToken(code, urlParams, process.env.REACT_APP_WEB_URL_TEDDY).then((res) => {
        if (res?.accessToken) {
          getProfile(res.accessToken).then((profile) => {
            if (profile.product === 'free') {
              setSpotifyNotPremiumAccount(true);
              setSpotifyError('Unable to Login. Please try after sometime.');
              return;
            }

            if (profile.product === 'premium') {
              const script = document.createElement('script');
              script.src = 'https://sdk.scdn.co/spotify-player.js';
              script.async = true;
              document.body.appendChild(script);
              window.onSpotifyWebPlaybackSDKReady = () => {};

              setToLocalStorage('spotifyAccessToken', res.accessToken);
              setToLocalStorage('spotifyRefreshToken', res.refreshToken);
              setToLocalStorage('email', profile.email);

              setEmail(profile.email);
              setSpotifyToken(res.accessToken);
            }
          });
        }
      });
    }

    setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 3000);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (email && userToken && userId) {
        clearAllLocalStorage();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [acceptedTerms]);

  useEffect(() => {
    const attributes = {
      room: vanityLinkData?.clientName,
      email: email || null,
      name: displayName || null,
    };

    window.pushHotJarAttributes(userId || null, attributes);

    if (
      ((!email || email === undefined) && !userId && !userToken && vanityLinkData !== undefined) ||
      navigateBackToEmailPage
    ) {
      setRenderComponent(
        <EmailForm
          vanityLinkData={vanityLinkData}
          setEmail={setEmail}
          spotifyError={spotifyError}
          forgeRockError={forgeRockError}
          setForgeRockError={setForgeRockError}
          setSpotifyError={setSpotifyError}
        />
      );
      setNavigateBackToEmailPage(false);
    }

    if (email && !displayName && vanityLinkData !== undefined && selectedAvatar === null) {
      setRenderComponent(<AvatarPresetPage selectedAvatar={selectedAvatar} setSelectedAvatar={setSelectedAvatar} />);
    }

    if (email && !displayName && vanityLinkData !== undefined && selectedAvatar) {
      setRenderComponent(
        <NameCollectionForm
          vanityLinkData={vanityLinkData}
          email={email}
          setUserId={setUserId}
          setUserToken={setUserToken}
          setDisplayNameHome={setDisplayName}
          setIsLoading={setIsLoading}
        />
      );
    }

    if (userToken && userId && (acceptedTerms || vanityLinkData?.spotifyLogin)) {
      setRenderComponent(<VanityURLRedirects userId={userId} />);
    }

    setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 3000);
  }, [
    displayName,
    email,
    userToken,
    userId,
    isLoading,
    vanityLinkData,
    acceptedTerms,
    selectedAvatar,
    navigateBackToEmailPage,
  ]);

  const handleAcceptTerms = () => {
    setAcceptedTerms(true);
    setToLocalStorage('acceptedTerms', 'true');
  };

  return (
    <HomeContainer style={{ backgroundImage: `url(${vanityLinkData?.backgroundImage})` }}>
      <IntraverseLogo
        src={IntraverseLogoIcon}
        alt="Intraverse Logo"
        onClick={() => {
          setNavigateBackToEmailPage(true);
          setEmail(null);
        }}
      />
      <div>
        {email && userToken && userId && !acceptedTerms && !vanityLinkData?.spotifyLogin && (
          <GenericTCModal
            setAcceptedTerms={handleAcceptTerms}
            vanityLinkData={vanityLinkData.freeformJson}
            setCheckboxStatus={setCheckboxStatus}
            checkboxStatus={checkboxStatus}
          />
        )}
      </div>
      <BackgroundOverlay />
      {isLoading && Object.keys(vanityLinkData).length === 0 ? (
        <Box
          sx={{
            width: '50%',
            color: '#968DFD',
          }}
        >
          <LinearProgress color="inherit" size="lg" />
        </Box>
      ) : (
        renderComponent
      )}
      <Banner
        width={'auto'}
        zIndex="3000"
        message={'We failed to connect to Spotify. Please connect a Premium Spotify account and try again.'}
        type={'ERROR'}
        openBanner={spotifyNotPremiumAccount}
        setOpenBanner={setSpotifyNotPremiumAccount}
        topDisplay
        showCloseButton
        gameScreen={false}
        timer={8000}
      />
    </HomeContainer>
  );
};

export default GenericLoginPage;

const HomeContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  /* background-image: url(); */
  background-size: cover;
  background-position: center;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const BackgroundOverlay = styled.div`
  overflow: hidden;
  background-color: #1b1b23;
  opacity: 0.7;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2;
`;

const IntraverseLogo = styled.img`
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20px;
  left: 20px;
  z-index: 5;

  @media (max-width: 960px) {
    width: 25px;
    height: 25px;
    top: 5px;
    left: 5px;
  }
  @media (min-width: 960px) and (max-width: 1440px) {
    width: 30px;
    height: 30px;
    top: 20px;
    left: 20px;
  }
`;
