import { useRef, useState } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Button, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { blockUser, deleteUser, unblockUser } from '../../../api/UsersController';
import Iconify from '../../../components/Iconify';
import StatusModal from '../../../components/common/Modal/StatusModal';
import Alert from "../../../components/common/Alert/Alert";

export default function UserMoreMenu(props) {
  const token = useSelector((state) => state?.user?.token);
  const userRole = useSelector((state) => state?.user?.role);
  const { handleAction, user } = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [deleteUserMsg, setDeleteUserMsg] = useState(null);
  const [deleteUserStatus, setDeleteUserStatus] = useState(null);  
  const [blockAlertMsg, setBlockAlertMsg] = useState(null);
  const [blockAlertStatus, setBlockAlertStatus] = useState(null);
  const [unblockAlertMsg, setUnblockAlertMsg] = useState(null);
  const [unblockAlertStatus, setUnblockAlertStatus] = useState(null);
  
  const handleDeleteUser = async () => {
    const result = await deleteUser(token, user.userId);
    setDeleteUserStatus(result.status);
    setDeleteUserMsg(result.message);
  };

  if (blockAlertStatus === 400 || blockAlertStatus === 201) {
    setTimeout(() => {
      setBlockAlertStatus(null);
      setBlockAlertMsg(null);
      window.location.reload();
    }, 3000);
  }
  if (unblockAlertStatus === 400 || unblockAlertStatus === 200) {
    setTimeout(() => {
      setUnblockAlertStatus(null);
      setUnblockAlertMsg(null);
      window.location.reload();
    }, 3000);
  }

  if (deleteUserStatus === 404 || deleteUserStatus === 200 || 
    deleteUserStatus === 500 || deleteUserStatus === 400) {
    setTimeout(() => {
      setDeleteUserStatus(null);
      setDeleteUserMsg(null);
      window.location.reload();
    }, 3000);
  }

  return (
    <>
      {deleteUserStatus === 404 && (
        <Alert type="error" message='No data found for user or already deleted.'/>
      )}
      {deleteUserStatus === 200 && (
        <Alert type="success" message={deleteUserMsg} />
      )}
      {deleteUserStatus === 400 || deleteUserStatus === 500 && (
        <Alert type="error" message={deleteUserMsg}/>
      )}
      {blockAlertStatus === 400 && (
        <Alert type="error" message={blockAlertMsg}/>
      )}
      {blockAlertStatus === 201 && (
        <Alert type="success" message={blockAlertMsg} />
      )}
      {unblockAlertStatus === 400 && (
        <Alert type="error" message={unblockAlertMsg} />
      )}
      {unblockAlertStatus === 200 && (
        <Alert type="success" message='User successfully unblocked.' />
      )}

      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterLink} to={`/dashboard/user-profile/${user.userId}`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {(userRole === 'admin' || userRole === 'superadmin') && (
          <div>
            {user.blockStatus === false ? (
              <MenuItem
                component={RouterLink}
                to="#"
                sx={{ color: 'text.secondary' }}
                onClick={() => setStatusModal(true)}
              >
                <ListItemIcon>
                  <Iconify icon="bx-block" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary="Block" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
            ) : (
              <MenuItem
                component={RouterLink}
                to="#"
                sx={{ color: 'text.secondary' }}
                onClick={() => setStatusModal(true)}
              >
                <ListItemIcon>
                  <Iconify icon="bx-block" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary="Unblock" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
            )}
          </div>
        )}
        {userRole === 'superadmin' && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={handleDeleteUser}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
      <StatusModal
        open={statusModal}
        handleClose={() => {
          setStatusModal(false);
        }}
        blockStatus={user.blockStatus}
        confirmed={async (reason) => {
          try {
            if(user.blockStatus === false){
              const responseBlock = await blockUser(user.userId, token, reason);
              setBlockAlertMsg(responseBlock.message);
              setBlockAlertStatus(responseBlock.status);
            }
            if(user.blockStatus === true){
              const respUnblock = await unblockUser(user.userId, token);
              setUnblockAlertMsg(respUnblock.message);
              setUnblockAlertStatus(respUnblock.status);
            }
          } catch (errorBlock) {
            console.error(errorBlock);
          }
        }}
      />
    </>
  );
}
