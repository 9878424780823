import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../VagonStream.css';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSpotify } from '../../redux/slices/spotify';
import CornerIconButton from '../../components/intraverse/ui/CornerIconButton';
import { changeURLByRoomId, exitFullScreen, enterFullScreen, updateOrientation } from '../../utils/utils';
import { fetchVanityLinkInfo } from '../../redux/slices/vanityLinks';
import BroadCast from '../../feature/broadcasting/BroadCast';
import AmbientSound from '../../feature/ambientSound/AmbientSound';
import SpotifyInterface from '../../feature/spotify/components/SpotifyControls';
import * as constants from '../../constants';
import { resumePlayer } from '../../feature/spotify/api/SpotifyController';
import { fetchVanityLinkData } from '../../api/VanityLinkController';
import { SaveVanityLink, DeleteVanityLink } from '../../redux/actions/VanityLinkActions';

export const sendVagonMessage = (message) => {
  window.Vagon.sendApplicationMessage(message);
};

const VagonStreamIntraverse = (props) => {
  const vanityLinkInfo = useSelector(fetchVanityLinkInfo);
  const spotifyToken = useSelector(fetchSpotify);

  const dispatch = useDispatch(); // Get the dispatch function
  const elementRef = useRef(null);
  const [showCornerButtons, setShowCornerButtons] = useState(true);
  const [landscape, setLandscape] = useState(false);
  const [fullScreen, setFullScreen] = useState(JSON.parse(localStorage.getItem('fullscreen') || 'false'));
  const [openBroadcastModal, setOpenBroadcastModal] = useState(false);
  const intervalIdRef = useRef(null);
  const [participant, setParticipant] = useState('');
  const [pauseMusic, setPauseMusic] = useState(false);
  const [broadCaster, setBroadCaster] = useState(false);
  const [playingMusic, setPlayingMusic] = useState(false);
  const [teddyNoteOpen, setTeddyNoteOpen] = useState(false);
  const [openTeddySelfiePopup, setOpenTeddySelfiePopup] = useState(false);
  const [openContestModalState, setOpenContestModalState] = useState(false);

  const handleButtonClick = () => {
    const escapeKeyEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
    });

    document.dispatchEvent(escapeKeyEvent);
  };

  const openBroadcastModalTrigger = () => {
    handleButtonClick();
    setOpenBroadcastModal((prev) => !prev);
    handleButtonClick();
  };

  const buttonsVisible = () => {
    setShowCornerButtons((prev) => !prev);
  };

  const startInterval = () => {
    if (!intervalIdRef.current) {
      intervalIdRef.current = setInterval(() => {
        window.Vagon?.focusIframe();
      }, 1000);
    }
  };

  const stopInterval = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const setVanityLinkData = async (pathId) => {
    const vanityLink = await changeURLByRoomId(pathId);
    if (vanityLink) {
      const data = await fetchVanityLinkData(vanityLink);
      dispatch(
        SaveVanityLink(
          data.clientLogo,
          data.backgroundImage,
          data.freeformJson,
          data.clientName,
          data.emailLogin,
          data.spotifyLogin,
          data.broadcast,
          data.ambientTrack
        )
      );
    }
  };

  const removeVanityLinkData = (vagonPlayingMusic) => {
    // changeURLByRoomId('');
    if (vagonPlayingMusic) {
      props.spotifyPlayer?.disconnect();
      playMusic();
    }
    DeleteVanityLink();
  };

  const playMusic = () => {
    setPlayingMusic((prev) => !prev);
  };

  const openTeddyNoteModal = () => {
    handleButtonClick();
    setTeddyNoteOpen((prev) => !prev);
    handleButtonClick();
  };
  const openContestModal = () => {
    handleButtonClick();
    setOpenContestModalState((prev) => !prev);
    handleButtonClick();
  };

  const openTeddySelfiePopupModal = () => {
    handleButtonClick();
    setOpenTeddySelfiePopup((prev) => !prev);
    handleButtonClick();
  };

  const loadedScript = () => {
    let vagonPlayingMusic = false;
    startInterval();

    // Prints out the message sent from the application
    window.Vagon.onApplicationMessage((evt) => {
      const message = evt.message.toString();
      console.log(`EVENT MESSAGE: ${message}`);

      if (Object.prototype.hasOwnProperty.call(constants.spotifyTracks, message)) {
        if (vagonPlayingMusic === false) {
          const type = constants.ALBUM;

          resumePlayer(spotifyToken.token, constants.spotifyTracks[message], type).then(() => {
            vagonPlayingMusic = true;
            playMusic();
          });
        }
        return;
      }
      if (message === constants.OPEN_BROADCAST_MODAL) {
        openBroadcastModalTrigger();
      }
      if (message === constants.PDF_OPEN || message === constants.PDF_CLOSE) {
        buttonsVisible();
      }
      if (message === constants.OPEN_TEDDY_CONTEST) {
        openContestModal();
      }
      if (message === constants.OPEN_TEDDY_NOTE) {
        openTeddyNoteModal();
      }
      if (message === constants.OPEN_TEDDY_SELFIE) {
        openTeddySelfiePopupModal();
      }
      if (message.includes(constants.NEW_ROOM_LOADING)) {
        const parts = message.split(':');
        setVanityLinkData(parts[1]);
      }
      if (message.includes(constants.ROOM_UNLOADED)) {
        removeVanityLinkData(vagonPlayingMusic);
        vagonPlayingMusic = false;
      }
      if (message.includes('SETUPCARD')) {
        window.open("localhost:3000/wallet/source=web_unity");
      }
    });
  };

  useEffect(() => {
    if (fullScreen === true || fullScreen === 'true') {
      enterFullScreen(elementRef);
    } else if (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullScreenElement) &&
      !fullScreen
    ) {
      exitFullScreen();
    }

    updateOrientation(setLandscape);
  }, [fullScreen]);

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://app.vagon.io/vagonsdk.js');
    script.addEventListener('load', () => {
      loadedScript();
    });
    head.appendChild(script);

    window.addEventListener('resize', updateOrientation(setLandscape));

    return () => window.removeEventListener('resize', updateOrientation(setLandscape));
  }, []);

  useEffect(() => {
    if (openBroadcastModal || teddyNoteOpen || openContestModalState || openTeddySelfiePopup) {
      stopInterval();
    } else {
      startInterval();
    }
  }, [openBroadcastModal, teddyNoteOpen, openContestModalState, openTeddySelfiePopup]);

  return (
    <div
      ref={elementRef}
      className="iframe-container"
      style={{ width: '100vw', height: '100vh', background: 'black', zIndex: 100 }}
    >
      {vanityLinkInfo.spotifyLogin && (
        <SpotifyInterface
          player={props.spotifyPlayer}
          setFullScreen={setFullScreen}
          landscape={landscape}
          teddyNoteOpen={teddyNoteOpen}
          setTeddyNoteOpen={setTeddyNoteOpen}
          openTeddySelfiePopup={openTeddySelfiePopup}
          setOpenTeddySelfiePopup={setOpenTeddySelfiePopup}
          playingMusic={playingMusic}
          openContestModalState={openContestModalState}
          setOpenContestModalState={setOpenContestModalState}
        />
      )}
      {vanityLinkInfo.music && (
        <AmbientSound
          audio={'AmbientSong'}
          play={!(participant || broadCaster || pauseMusic)}
          source={vanityLinkInfo.music}
        />
      )}

      {vanityLinkInfo.broadcast && (
        <BroadCast
          openBroadcastModal={openBroadcastModal}
          setOpenBroadcastModal={setOpenBroadcastModal}
          participant={participant}
          setParticipant={setParticipant}
          broadCaster={broadCaster}
          setBroadCaster={setBroadCaster}
          roomName={vanityLinkInfo.clientName}
        />
      )}

      <iframe
        id="vagonFrame"
        title="Vagon Stream Content"
        allow="microphone *; clipboard-read *; clipboard-write *; encrypted-media *;"
        style={{ width: '100vw', height: '100vh', border: 0, zIndex: 100 }}
        src={props.streamUrl}
        sandbox="allow-pointer-lock allow-scripts allow-forms allow-same-origin allow-popups"
      />

      {showCornerButtons && !openBroadcastModal && (
        <FullScreenIconImg landscape={landscape}>
          <CornerIconButton
            showActiveIcon={fullScreen}
            iconHeight={40}
            iconColor="white"
            activeIcon="iconamoon:screen-normal"
            unactiveIcon="iconamoon:screen-full-bold"
            func={() => {
              setFullScreen((value) => !value);
            }}
          />
          {!broadCaster && !participant && vanityLinkInfo.music && (
            <CornerIconButton
              iconHeight={40}
              iconColor="white"
              activeIcon="clarity:volume-up-solid"
              unactiveIcon="clarity:volume-mute-solid"
              func={() => {
                setPauseMusic((value) => !value);
              }}
            />
          )}
        </FullScreenIconImg>
      )}
    </div>
  );
};

VagonStreamIntraverse.propTypes = {
  streamUrl: PropTypes.string.isRequired,
  spotifyPlayer: PropTypes.object,
};

export default VagonStreamIntraverse;

const FullScreenIconImg = styled.div`
  height: auto;
  display: flex;
  flex-direction: ${(props) => (props.landscape ? 'row' : 'column')};
  position: fixed;
  z-index: 1000;
  transform: ${(props) => (props.landscape ? 'rotate(-180deg)' : 'none')};
  ${(props) => (props.landscape ? 'top: 0; left: 0;' : 'top: 0; right: 0;')}
`;
