import { getFromLocalStorage } from '../../../hooks/useLocalStorage';
import * as spotifyConstants from '../../../constants';

export async function handleSpotifyAuth(redirectUrl) {
  console.log('redirectUrl', redirectUrl);
  try {
    window.location.href = `${process.env.REACT_APP_CORE_API_URI}/web/spotify/login/${encodeURIComponent(redirectUrl)}`;
  } catch (error) {
    console.error('Error during Spotify authentication:', error);
  }
}

export async function getSpotifyToken(code, urlParams, redirectUrl) {
  const token = null;

  if (code && urlParams) {
    // If the code is present, send a request to the backend to fetch the token
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CORE_API_URI}/web/spotify/callback/${code}/${encodeURIComponent(redirectUrl)}`
      );

      if (response.ok) {
        const data = await response.json();

        // Remove the code and state parameters from the URL
        urlParams.delete('code');
        urlParams.delete('state');
        const newUrl = `${window.location.origin}${window.location.pathname}`;
        window.history.replaceState({}, '', newUrl);

        return data;
      }
      console.error('Failed to fetch the token from the backend');
      return token;
    } catch (error) {
      console.error('Error during token fetch:', error);
      return token;
    }
  } else {
    return token;
  }
}

// eslint-disable-next-line camelcase
export async function transferPlayback(device_id, token) {
  try {
    // This is assigning the device id passed as the active spotify application
    const response = await fetch(
      // eslint-disable-next-line camelcase
      `${process.env.REACT_APP_CORE_API_URI}/web/spotify/transferPlayback/${device_id}/${token}`
    );

    return response;
  } catch (error) {
    console.error('Error during transfer playback:', error);
  }
}

export async function resumePlayer(token, contextURI, type) {
  try {
    const body = JSON.stringify({
      accessToken: token,
      contextURI,
      type,
    });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    };

    // This is assigning the device id passed as the active spotify application
    const response = await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/spotify/resumePlayback`, requestOptions);

    return response.status;
  } catch (error) {
    console.error('Error during resume player:', error);
    return null;
  }
}

export async function setRepeat(state, deviceId, token) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CORE_API_URI}/web/spotify/setRepeat/${state}/${deviceId}/${token}`
    );

    return response;
  } catch (error) {
    console.error('Error during resume player:', error);
  }
}

export async function getProfile(token) {
  try {
    const response = await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/spotify/profile/${token}`);

    if (response.ok) {
      const data = await response.json();
      return data;
    }

    return null;
  } catch (error) {
    console.error('Error during resume player:', error);
  }
}

export async function getSpotifyRefreshToken(refreshToken) {
  const token = null;

  if (refreshToken) {
    // If the code is present, send a request to the backend to fetch the token
    try {
      const response = await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/spotify/refreshToken/${refreshToken}`);

      if (response.ok) {
        const data = await response.json();

        return data.accessToken;
      }
      console.error('Failed to fetch the token from the backend');
      return token;
    } catch (error) {
      console.error('Error during token fetch:', error);
      return token;
    }
  } else {
    return token;
  }
}

export async function refreshUserToken(userId) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId }),
    };

    const response = await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/users/auth-session`, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error('Error during refresh token:', error);
    return null;
  }
}

export async function signUpUser(displayName, email) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ displayName, email }),
    };

    const response = await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/users/login`, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return data;
    }

    return null;
  } catch (error) {
    console.error('Error during singn up:', error);
    return null;
  }
}

export const logStreaming = async (song, uri) => {
  const userId = getFromLocalStorage('userId');
  const userSession = getFromLocalStorage('userToken');
  if (!userId) {
    return;
  }

  await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/spotify/misc/teddys-lounge-spotify-tracking`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userSession}`,
    },
    body: JSON.stringify({
      analyticsData: {
        trackId: uri,
        sessionId: userSession,
        userId,
        datetimeStarted: new Date(),
        song,
        durationInSeconds: spotifyConstants.STREAM_SECONDS,
      },
    }),
  });
};

export async function sendTeddyNoteToDb(name, message, userToken) {
  console.log('api request');
  try {
    const response = await fetch(`${process.env.REACT_APP_CORE_API_URI}/web/users/action-log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({
        analyticsData: {
          name,
          message,
        },
      }),
    });
    console.log(`Response: ${JSON.stringify(response)}`);
    if (response.ok) {
      console.log('success', response);
      return response.ok;
    }
  } catch (error) {
    console.error('Error sending message:', error);
    return null;
  }
}
