import { API_GET } from './API_FETCH';
import { COREAPI_WEB_STATUSES_URL } from '../constants';

const STATUSES_COREAPIURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_STATUSES_URL}`

export async function getStatuses(token) {
    try {
      return await API_GET(`${STATUSES_COREAPIURL}`, token);
    }
    catch (err) {
        return 500
    }
  }
  