// @mui
import React, { useEffect } from 'react';
import { Grid, Container, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// components
import Page from '../../components/Page';
// sections
import { AppProductCard } from '../../sections/@dashboard/app';
import { fetchUserName, fetchUserToken } from '../../redux/slices/users';
import { fetchMerchantInfo } from '../../redux/slices/merchants';
import { UpdateMerchantStripeId } from '../../redux/actions/MerchantActions';
import axiosInstance from '../../config/axiosConfig';

// ----------------------------------------------------------------------
const products = [
  { btnText: 'sign in' },
  { btnText: 'sign in' },
  {
    cardColor: 'pink',
    btnColor: 'violet',
    btnHoverColor: 'purple',
  },
  {
    cardColor: 'pink',
    btnColor: 'violet',
    btnHoverColor: 'purple',
  },
  {
    cardColor: 'pink',
    btnColor: 'violet',
    btnHoverColor: 'purple',
  },
  { btnText: 'sign in' },
];

export default function MerchantsDashboard() {
  const user = useSelector(fetchUserName);
  const merchant = useSelector(fetchMerchantInfo);
  const token = useSelector(fetchUserToken);
  const dispatch = useDispatch();

  useEffect(() => {});

  useEffect(() => {
    const authStripe = async (authCode) => {
      // Get the current URL query parameters
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_CORE_API_URI}/web/stripe/oauth`,
          {
            authCode,
            merchantId: merchant.merchantId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const stripeId = response.data.stripeClientId;
        dispatch(UpdateMerchantStripeId(stripeId));
      } catch (err) {
        console.log(err);
      }
    };

    const urlSearchParams = new URLSearchParams(window.location.search);

    // Get the value of a specific query parameter
    const authCode = urlSearchParams.get('code');

    if (authCode && !merchant.stripeClientId) {
      authStripe(authCode);
    }
  }, []);

  const handleDeauth = async () => {
    await axiosInstance.post(
      `${process.env.REACT_APP_CORE_API_URI}/web/stripe/deauthorize`,
      {
        merchantId: merchant.merchantId,
        stripeUserId: merchant.stripeClientId,
        client_id: process.env.REACT_APP_TZ_STRIPE_CLIENT_ID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(UpdateMerchantStripeId(null));
  };

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi {user}, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {merchant && !merchant.stripeClientId ? (
            <Grid item>
              <Typography variant="body1" sx={{ mb: 2 }}>
                To proceed to the Merchant Dashboard, you must first connect your merchant Stripe account to Terrazero's
                Stripe account. Please click the button below to do so.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_STRIPE_CONNECT_URL;
                }}
              >
                Connect Stripe Account
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Welcome to the Merchant Portal! Feel free to explore and make use of the features available.
              </Typography>
              <Button variant="contained" color="primary" onClick={handleDeauth}>
                Deauthorize Stripe Account
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
