import { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Container, Typography } from '@mui/material';
import styled from '@emotion/styled';
// sections
import { LoginForm } from '../sections/auth/login';
// import AuthSocial from '../sections/auth/AuthSocial';
import IntraverseLogoFull from './intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
import DiamondBackground from './intraverseLandingPage/IntraverseHomeImages/Diamond_Checkered_Background.png';

export default function Login() {
  const [message, setMessage] = useState(null);

  return (
    <LoginContainer>
      <BorderOutlineStroke>
        <HeaderDiv>
          <Link to="/">
            <LoginLogo src={IntraverseLogoFull} alt="Company Logo" />
          </Link>
          <Link to="/register">
            <CreateAccountButton> Create Account</CreateAccountButton>
          </Link>
        </HeaderDiv>
        <Container maxWidth="sm" sx={{ padding: 0, marginTop: '-20px' }}>
          <LoginTitle>JUMP IN THE INTRAVERSE</LoginTitle>
          <LoginSubtitle>Login with your email below.</LoginSubtitle>
          {message && <Typography sx={{ color: '#FF8484', mb: 3 }}>{message}</Typography>}
          {/* <AuthSocial /> */}
          <LoginForm setMessage={setMessage} />

          <FooterContainer>
            <PrivacyLine>
              © 2023 TerraZero Technologies, Intraverse, and any associated logos are trademarks, service marks, and/or
              registered trademarks of TerraZero Technologies.
            </PrivacyLine>
            <FooterMenu>
              <FooterItems>
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://terrazero.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FooterItem>privacy notice</FooterItem>
                </a>
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://terrazero.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FooterItem>Terms of service</FooterItem>
                </a>
                <FooterItem>cookie preferences</FooterItem>
              </FooterItems>
            </FooterMenu>
          </FooterContainer>
        </Container>
      </BorderOutlineStroke>
    </LoginContainer>
  );
}

// ----------------------------------------------------------------------

const BorderOutlineStroke = styled.div`
  z-index: 3000;
  border: 3px solid rgba(217, 217, 217, 0.2);
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    padding: 5px;
    border: none;
  }

  @media (max-height: 600px) {
    padding: 5px;
    border: none;
    overflow-y: auto;
  }

  /* @media (max-width: 768px) {
    padding: 5px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 5px;
  } */
`;

const LoginContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: rgba(27, 27, 35, 1);
  background-image: url(${DiamondBackground});
  background-size: 350px auto;
  background-position: center;
  height: 100%;

  @media (max-width: 768px) {
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow: auto;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow: auto;
  }
`;

const LoginLogo = styled.img`
  display: flex;
  align-self: flex-start;
  width: 250px;
  height: auto;
  margin-bottom: 30px;
  margin-top: 10px;
  margin-left: 10px;

  @media (max-width: 768px) {
    align-self: center;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
  margin-right: 10px;
  margin-left: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-top: 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LoginTitle = styled.h3`
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 50px;
  margin-top: -0.7rem;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 960px) {
    font-size: 40px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 40px;
  }
`;

const LoginSubtitle = styled.p`
  color: #ced1d9;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin: 1rem 0;
  @media (max-width: 960px) {
    margin: 1rem -10px;
  }
`;

const CreateAccountButton = styled.button`
  border-radius: 5px;
  border: 2px solid #beaeff;
  display: inline-flex;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-right: 1rem;
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-right: 0rem;
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    width: fit-content;
    margin-top: 10px;
  }
`;

const FooterMenu = styled.div`
  display: flex;
  align-items: center;
`;
const FooterItems = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding-bottom: 1rem;
  padding-top: 0rem;

  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

const FooterItem = styled.div`
  color: #fbfcff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  justify-items: center;
  margin-right: 50px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 0.7rem;
    margin: 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 0.9rem;
    margin: 10px;
    overflow: auto;
  }
`;

const PrivacyLine = styled.p`
  color: #ced1d9;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0.7rem;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 0rem 0.5rem;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px;
    margin: 0rem 2rem;
    width: 80%;
  }
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding: 25px 30px;

  @media (max-width: 768px) {
    padding: 5px 5px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 1rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 20px 5px;
  }

  @media (max-height: 600px) {
    padding: 5px;
    position: relative;
  }
`;
