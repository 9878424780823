export const SUPERADMIN_ROLE = 'superadmin';
export const ADMIN_ROLE = 'admin';
export const USER_ROLE = 'user';
export const MERCHANT = 'merchant';
export const SUPERADMIN_DISPLAY_ROLE = 'Super Admin';
export const ADMIN_DISPLAY_ROLE = 'Admin';
export const USER_DISPLAY_ROLE = 'User';
export const COREAPI_WEB_USERS_URL = '/web/users';
export const COREAPI_WEB_MERCHANTS_URL = '/web/merchant';
export const COREAPI_WEB_STATUSES_URL = '/web/statuses';
export const spotifyTracks = {
  TRACK_TEDDYSWIMS_LOSE_CONTROL: 'spotify:album:7nacKlk586eLRBSIsrk9DB',
  // Add more cases here
};

export const ALBUM = 'ALBUM';
export const TRACK = 'TRACK';
export const CONTEXT = 'context';
export const OPEN_TEDDY_NOTE = 'OPEN_TEDDY_NOTE';
export const OPEN_TEDDY_SELFIE = 'OPEN_TEDDY_SELFIE';
export const OPEN_TEDDY_CONTEST = 'OPEN_TEDDY_CONTEST';
export const STREAM_SECONDS = 31;
export const CLIENT_ID = 'diAr304KPO679nsf251epcMw8';
export const REDIRECT_URI = 'https://dev.da8tzvwvdozxp.amplifyapp.com/estee-lauder';
export const SCOPE = 'email';
export const RESP_TYPE = 'code';

export const OPEN_BROADCAST_MODAL = 'OPEN_BROADCAST_MODAL';
export const PDF_OPEN = 'PDF_OPEN';
export const PDF_CLOSE = 'PDF_CLOSE';
export const NEW_ROOM_LOADING = 'NEW_ROOM_LOADING';
export const ROOM_UNLOADED = 'ROOM_UNLOADED';
