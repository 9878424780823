import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Container } from '@mui/material';
import IntraverseHeroBanner from './IntraverseHeroBanner';
import IntraverseBlurbSegment from './IntraverseBlurbSegment';
import IntraverseFeatures from './IntraverseFeatures';
import ImmersiveExperiences from './ImmersiveExperiences';
import LandingSubscribe from './LandingSubscribe';
import LandingTicker from './LandingTicker';
import LandingFooter from './LandingFooter';
import DiamondBackground from './IntraverseHomeImages/Diamond_Checkered_Background.png';

const IntraverseLandingHome = () => {
  const [password, setPassword] = useState('');

  return (
    <>
      {password === 'specialintraverse2024!' ? (
        <IntraverseHomeContainer>
          <IntraverseHeroBanner />
          <BorderOutlineStroke />
          <IntraverseBlurbSegment />
          <IntraverseFeatures />
          <ImmersiveExperiences />
          <LandingSubscribe />
          <LandingTicker />
          <LandingFooter />{' '}
        </IntraverseHomeContainer>
      ) : (
        <PassContainer>
          {' '}
          <Container
            maxWidth="sm"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: '5rem',
            }}
          >
            <p style={{ color: 'white', fontWeight: 700, fontSize: '30px' }}> Please enter a password</p>
            <input
              style={{ fontWeight: 700, fontSize: '30px', marginTop: '2rem' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Container>
        </PassContainer>
      )}
    </>
  );
};

export default IntraverseLandingHome;

const BorderOutlineStroke = styled.div`
  position: absolute;
  width: 97%;
  height: 99.4%;
  opacity: 0.2;
  pointer-events: none;
  top: 0.3%;
  left: 1.5%;
  z-index: 3000;
  border: 3px solid #d9d9d9;
`;

const IntraverseHomeContainer = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
`;

const PassContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgba(27, 27, 35, 1);
  background-image: url(${DiamondBackground});
  background-size: 350px auto;
  align-items: center;

  @media (max-width: 768px) {
    padding-top: 4rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-top: 5rem;
  }
`;
