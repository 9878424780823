import React, { useState } from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

function Alert(props) {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const alertProps = {
    severity: props.type,
    sx: {
      width: "100%",
    },
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000} // 3 seconds
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <MuiAlert onClose={handleClose} {...alertProps}>
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
}

export default Alert;
