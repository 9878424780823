// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const adminNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'merchant',
    path: '/dashboard/merchant',
    icon: getIcon('mdi:store'),
  },
  {
    title: 'profile',
    path: '/dashboard/profile',
    icon: getIcon('eva:person-done-fill'),
  },
];

export const merchantNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
];
