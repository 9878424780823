import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { fetchUserToken } from '../../redux/slices/users';
import axiosInstance from '../../config/axiosConfig';
import DiamondBackground from '../intraverseLandingPage/IntraverseHomeImages/Diamond_Checkered_Background.png';
import PaymentMethod from './PaymentMethod';
import AddCartPopUp from './AddCartPopUp';
import MobileNav from './MobileNav';
import StripeContainer from './StripeContainer';
import DisconnectTab from './DisconnectTab';
import WalletPageHeader from './WalletPageHeader';

const WalletPage = () => {
  const token = useSelector(fetchUserToken);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [toggleAddCart, setToggleAddCart] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disconnectTabOn, setDisconnectTabOn] = useState(false);
  const [displayStripe, setDisplayStripe] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);

  const fetchClientSecret = async () => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_CORE_API_URI}/web/stripe/generate_intent`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axiosInstance
      .request(config)
      .then((response) => {
        console.log(response);
        setClientSecret(response.data.client_secret);
        setToggleAddCart(!toggleAddCart);
        setDisplayStripe(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setupStripeAccount = (userData) => {
    console.log('User DAta', userData);
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_CORE_API_URI}/web/stripe/customers`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        email: userData.email,
      },
    };

    axiosInstance
      .request(config)
      .then((response) => {
        console.log('SUCCESS', response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCustomerPaymentMethods = () => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_CORE_API_URI}/web/stripe/payment_methods`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axiosInstance
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          const { paymentMethods } = response.data;
          setPaymentMethods(paymentMethods);

          setLoading(false);
        }
      })
      .catch(async (error) => {
        if (error.response.status === 404) {
          const {userData} = error.response.data
          await setupStripeAccount(userData)
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchCustomerPaymentMethods();
  }, []);

  const paymentMethodsScreen = () => (
    <>
      <ContainerOne>
        <ContainerTwo>
          <Title>My Wallet</Title>
          <LoginSubtitle>Manage and review your cards, payments, and billing information.</LoginSubtitle>
        </ContainerTwo>
        {paymentMethods.length > 0 ? (
          <DisconnectButton onClick={() => setDisconnectTabOn(true)}>Remove payment method</DisconnectButton>
        ) : (
          <></>
        )}
      </ContainerOne>
      {paymentMethods.length > 0 ? (
        paymentMethods.map((paymentMethod, i) => <PaymentMethod key={`${i}`} paymentMethod={paymentMethod} />)
      ) : (
        <></>
      )}

      {paymentMethods.length === 0 && !toggleAddCart && !clientSecret ? (
        <>
          <AddCartPopUp toggleAddCart={toggleAddCart} fetchClientSecret={fetchClientSecret} />
        </>
      ) : (
        <></>
      )}

      {displayStripe ? <StripeContainer clientSecret={clientSecret} /> : <></>}
    </>
  );

  if (loading) {
    return (
      <MainContainer>
        <WalletPageHeader />
        <DarkBackground>
          <LoginContainer />
        </DarkBackground>
        <MobileNav />
      </MainContainer>
    );
  }

  console.log(paymentMethods.length, toggleAddCart,clientSecret, disconnectTabOn)

  return (
    <MainContainer>
      <WalletPageHeader disconnectTabOn={disconnectTabOn} setDisconnectTabOn={setDisconnectTabOn} />
      <DarkBackground>
        <LoginContainer>
          {disconnectTabOn ? <DisconnectTab setDisconnectTabOn={setDisconnectTabOn} /> : paymentMethodsScreen()}
        </LoginContainer>
      </DarkBackground>
      <MobileNav />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: #24242f;
  z-index: 1000;
  margin-top: 1rem;
  padding-top: 10px 0px;
  @media (max-width: 768px) {
    padding: 0px 0px;
    margin-top: -1rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0px 25px;
  }
`;

const LoginContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: rgba(27, 27, 35, 1);
  background-image: url(${DiamondBackground});
  background-size: 350px auto;
  background-position: center;
  min-height: calc(100vh - 62px);
  border-left: 1px solid rgb(190, 174, 255, 0.1);
  border-right: 1px solid rgb(190, 174, 255, 0.1);
  border-color: #beaeff;
  @media (min-width: 768px) {
    height: 100%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    /* height: 100vh; */
    border: none;
  }
  @media (max-width: 768px) {
    border: 0px;
  }
`;

const DisconnectButton = styled.button`
  border-radius: 5px;
  border: 2px solid #beaeff;
  display: inline-flex;
  padding: 15px 35px;
  max-height: 60px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-right: 1rem;
  margin-top: 10px;
  cursor: pointer;
  margin-right: ${(props) => (props.noMargin ? `0px` : '')};
  width: ${(props) => (props.noMargin ? `100%` : '')};

  @media (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-right: 0rem;
    margin-top: 3rem;
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    width: fit-content;
    margin-top: 10px;
    margin-right: ${(props) => (props.noMargin ? `0px` : '')};
    width: ${(props) => (props.noMargin ? `100%` : '')};
  }
`;

const ContainerOne = styled.div`
  display: flex;
  /* background-color: yellow; */
  padding-top: 2rem;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ContainerTwo = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoginSubtitle = styled.p`
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--text-light-grey, #ced1d9);
  text-align: center;
  font-family: Barlow;
  font-size: 22px;
  padding-top: 2rem;

  /* margin: 1rem 0; */
  @media (max-width: 960px) {
    /* margin: 2rem -10px; */
  }
`;

const DarkBackground = styled.div`
  background: rgba(27, 27, 35, 0.75);
  padding: 0px 150px;
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  @media (max-width: 960px) {
    padding: 0;
    flex: 1;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    padding: 0;
  }
`;

const Title = styled.h3`
  font-size: 26px;
  background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Barlow Condensed;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 768px) {
    margin: auto;
  }
`;

export default WalletPage;
