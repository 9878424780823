/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
// @mui & Image Imports
import { Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as GreenFeatureCheckmark } from './intraverseLandingPage/IntraverseHomeImages/GreenFeatureCheckmark.svg';
import { ReactComponent as RedCross } from './intraverseLandingPage/IntraverseHomeImages/redCross.svg';
import IntraverseLogoFull from './intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
import DiamondBackground from './intraverseLandingPage/IntraverseHomeImages/Diamond_Checkered_Background.png';
// API & Utils
import { RegisterUser } from '../sections/auth/api/Controller';
// import AuthSocial from '../sections/auth/AuthSocial';
import { validateUsername } from '../utils/isValidEmail';

export default function RegisterUserPage() {
  // Form data states
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  // Page Section state handling
  const [section, setSection] = useState('name');
  const [isLoading, setIsLoading] = useState(false);

  // Error states
  const [error, setError] = useState('');
  const [nameError, setNameError] = useState('');
  const [nameValidError, setNameValidError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [termsError, setTermsError] = useState('');
  const [isNewsChecked, setIsNewsChecked] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  // Password checker states
  const [lengthError, setLengthError] = useState(true);
  const [uppercaseError, setUppercaseError] = useState(true);
  const [numberError, setNumbersError] = useState(true);
  const [specialCharError, setSpecialCharError] = useState(true);

  const countryOptions = ['USA', 'Canada'];

  const navigate = useNavigate();

  const nameRegex = /^[A-Za-z\s]+$/;

  useEffect(() => {
    if (!nameRegex.test(firstName + lastName) && firstName.length > 0 && lastName.length > 0) {
      setNameValidError('Your name should only contain letters and/or spaces');
    } else {
      setNameValidError('');
    }
    if (firstName !== '' && lastName !== '' && country !== '') {
      setNameError('');
    } else setNameError('*Please fill out all fields');
  }, [firstName, country, lastName]);

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const validatePassword = (password) => {
    const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/; // allows special chars
    return passwordReg.test(password);
  };

  const handlePasswordChange = (event) => {
    const minLengthRegex = /^.{8,}$/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    // eslint-disable-next-line no-useless-escape
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    setPassword(event.target.value);

    if (minLengthRegex.test(event.target.value)) {
      setLengthError(false);
    } else {
      setLengthError(true);
    }
    if (uppercaseRegex.test(event.target.value)) {
      setUppercaseError(false);
    } else {
      setUppercaseError(true);
    }
    if (numberRegex.test(event.target.value)) {
      setNumbersError(false);
    } else {
      setNumbersError(true);
    }
    if (specialCharacterRegex.test(event.target.value)) {
      setSpecialCharError(false);
    } else {
      setSpecialCharError(true);
    }
  };

  const handleEmailSubmit = () => {
    if (validateEmail(email) && validatePassword(password)) {
      setError('');
      setTermsError('');
      setSection('username');
    } else if (!validateEmail(email)) {
      setError('Please enter a valid email address');
    }
  };

  const handleTermsSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    await RegisterUser(firstName, lastName, email, password, username).then((res) => {
      if (res && isTermsChecked) {
        setTermsError('');
        setIsLoading(true);
        navigate('/login');
      } else if (!isTermsChecked) {
        setTermsError('Please accept our terms & conditions before signing up');
        setIsLoading(false);
      } else {
        setTermsError(
          'Error: Your Email address or Username is already in use.'
        );
        console.log('ERROR SIGNUP FOR USER');
        setIsLoading(false);
      }
    });
  };

  const handleUsernameSubmit = () => {
    if (validateUsername(username)) {
      setUsernameError('');
      setTermsError('');
      setSection('terms');
    } else {
      setUsernameError(
        'Username must contain between 3-20 chars (Letters, numbers, underscores, or hyphens are allowed)'
      );
    }
  };
  const handleNewsCheckboxChange = () => {
    setIsNewsChecked(!isNewsChecked);
  };
  const handleTermsCheckboxChange = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  return (
    <LoginContainer>
      <BorderOutlineStroke>
        <HeaderDiv>
          <Link to="/">
            <LoginLogo src={IntraverseLogoFull} alt="Company Logo" />
          </Link>
          <Link to="/login">
            <CreateAccountButton>SIGN IN</CreateAccountButton>
          </Link>
        </HeaderDiv>
        <Container maxWidth="sm">
          {/* NAME AND COUNTRY PAGE */}

          {section === 'name' && (
            <>
              <StepText>STEP 1 OF 4</StepText>
              <LoginTitle>WHAT'S YOUR NAME?</LoginTitle>
              <LoginSubtitle>
                We will keep this information private and use it to verify your identity when contacting Intraverse.
              </LoginSubtitle>
              {/* <AuthSocial /> */}
              <InputContainer>
                <CountrySelect value={country} onChange={(e) => setCountry(e.currentTarget.value)}>
                  <option value="" disabled hidden>
                    Select a country
                  </option>
                  {countryOptions.map((country) => (
                    <option key={country} value={country}>
                     <p style={{margin: '5px', width: '100px'}}> {country}</p>
                    </option>
                  ))}
                </CountrySelect>
                <NameInput
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                />
                <NameInput
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                />
              </InputContainer>
              {nameError && <p style={{ color: '#FF8484' }}>{nameError}</p>}
              {nameValidError && <p style={{ color: '#FF8484' }}>{nameValidError}</p>}
              <ContinueButton
                style={{
                  background:
                    nameError === '' && nameValidError === '' && country !== ''
                      ? 'linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%)'
                      : '#504C5C',
                  color: nameError === '' && nameValidError === '' && country !== '' ? 'white' : '#9DA0A7',
                }}
                onClick={nameError === '' && nameValidError === '' && country !== '' ? () => setSection('email') : null}
              >
                Continue to Email
              </ContinueButton>
            </>
          )}

          {/* EMAIL AND PASSWORD PAGE */}

          {section === 'email' && (
            <>
              <StepText>STEP 2 OF 4</StepText>
              <LoginTitle>WHAT'S YOUR EMAIL?</LoginTitle>
              <LoginSubtitle>
                The email you provide below will be used to log in Intraverse on desktop and mobile.
              </LoginSubtitle>
              <InputContainer>
                <NameInput type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                <NameInput
                  type="password"
                  value={password}
                  onChange={(event) => {
                    handlePasswordChange(event);
                  }}
                  placeholder="Password"
                />
                {error && <p style={{ color: '#FF8484', alignSelf: 'flex-start' }}>{error}</p>}

                {/* PASSWORD CHECK BOX */}

                <PasswordValidBox>
                  <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      {lengthError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      8 characters long
                    </p>
                    <p>
                      {uppercaseError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One uppercase
                    </p>
                  </PasswordValidText>
                  <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      {' '}
                      {numberError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One number
                    </p>
                    <p>
                      {specialCharError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One special character
                    </p>
                  </PasswordValidText>
                </PasswordValidBox>
              </InputContainer>
              <ContinueButton
                style={{
                  background:
                    validateEmail(email) && validatePassword(password)
                      ? 'linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%)'
                      : '#504C5C',
                  color: validateEmail(email) && validatePassword(password) ? 'white' : '#9DA0A7',
                }}
                onClick={() => {
                  handleEmailSubmit();
                }}
              >
                Continue to Username
              </ContinueButton>
              <StepText
                style={{ color: '#BEAEFF', cursor: 'pointer', marginTop: '0.5rem', marginBottom: '0.6rem' }}
                onClick={() => setSection('name')}
              >
                Back to step 1
              </StepText>
            </>
          )}

          {/* USERNAME PAGE */}

          {section === 'username' && (
            <>
              <StepText>STEP 3 OF 4</StepText>
              <LoginTitle>Create your username</LoginTitle>
              <LoginSubtitle>
                Choose the game you want to use in the Intraverse. Don’t worry, you can change this later!
              </LoginSubtitle>
              <InputContainer>
                <NameInput
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                />
              </InputContainer>
              {usernameError !== '' && <p style={{ color: '#FF8484' }}>{usernameError}</p>}
              <ContinueButton
                style={{
                  background: validateUsername(username)
                    ? 'linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%)'
                    : '#504C5C',
                  color: validateUsername(username) ? 'white' : '#9DA0A7',
                }}
                onClick={() => {
                  handleUsernameSubmit();
                }}
              >
                Continue to Last Step
              </ContinueButton>
              <StepText
                style={{ color: '#BEAEFF', cursor: 'pointer', marginTop: '0.7rem', marginBottom: '0.7rem' }}
                onClick={() => setSection('email')}
              >
                Back to step 2
              </StepText>
            </>
          )}

          {/* TERMS & CONDITIONS PAGE */}

          {section === 'terms' && (
            <SubmitForm onSubmit={handleTermsSubmit}>
              <StepText style={{ marginTop: '-1rem' }}>STEP 4 OF 4</StepText>
              <LoginTitle>REVIEW OUR TERMS</LoginTitle>
              <LoginSubtitle>You’re almost done! Please review our terms and opt-ins below.</LoginSubtitle>
              <CheckboxContainer>
                <TermsText>
                  <Checkbox
                    type="checkbox"
                    id="termsCheckbox"
                    checked={isNewsChecked}
                    onChange={handleNewsCheckboxChange}
                  />
                  <p onClick={handleNewsCheckboxChange}>
                    {' '}
                    Receive news, special offers, event information, and feedback surveys by email from Intraverse.
                  </p>
                </TermsText>
                <TermsText>
                  <Checkbox
                    type="checkbox"
                    id="termsCheckbox"
                    checked={isTermsChecked}
                    onChange={handleTermsCheckboxChange}
                  />
                  <p onClick={handleTermsCheckboxChange}>
                    I acknowledge that I have read and understood{' '}
                    <a
                      style={{ textDecoration: 'none', color: '#BEAEFF', fontWeight: 700 }}
                      href="https://terrazero.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Intraverse Online Privacy Policy
                    </a>{' '}
                    and
                    <a
                      style={{ textDecoration: 'none', color: '#BEAEFF', fontWeight: 700 }}
                      href="https://terrazero.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      End User License Agreement
                    </a>
                    .
                  </p>
                </TermsText>
              </CheckboxContainer>
              {termsError && <p style={{ color: '#FF8484' }}>{termsError}</p>}
              <ContinueButton
                style={{
                  background: isTermsChecked ? 'linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%)' : '#504C5C',
                  color: validateEmail(email) && validatePassword(password) ? 'white' : '#9DA0A7',
                }}
                type="submit"
              >
                {isLoading ? (
                  <div>
                    <CircularProgress size="30px" sx={{ color: '#ced1d9', opacity: '0.5', margin: '0 30px' }} />
                  </div>
                ) : (
                  'CREATE MY ACCOUNT'
                )}
              </ContinueButton>
              <StepText
                style={{ color: '#BEAEFF', cursor: 'pointer', margin: '1rem 0' }}
                onClick={() => setSection('username')}
              >
                Back to step 3
              </StepText>
            </SubmitForm>
          )}

          {/* FOOTER CONTAINER */}

          <FooterContainer>
            <PrivacyLine>
              © 2023 TerraZero Technologies, Intraverse, and any associated logos are trademarks, service marks, and/or
              registered trademarks of TerraZero Technologies.
            </PrivacyLine>
            <FooterMenu>
              <FooterItems>
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://terrazero.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FooterItem>privacy notice</FooterItem>
                </a>
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://terrazero.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FooterItem>Terms of service</FooterItem>
                </a>
                <FooterItem>cookie preferences</FooterItem>
              </FooterItems>
            </FooterMenu>
          </FooterContainer>
        </Container>
      </BorderOutlineStroke>
    </LoginContainer>
  );
}

// ----------------------------------------------------------------------

const BorderOutlineStroke = styled.div`
  z-index: 3000;
  border: 3px solid rgba(217, 217, 217, 0.2);
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    padding: 5px;
    border: none;
  }

  @media (max-height: 600px) {
    padding: 5px;
    border: none;
    overflow-y: auto;
  }
  /* @media (max-width: 768px) {
    padding: 5px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 5px;
  } */
`;

const LoginContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: rgba(27, 27, 35, 1);
  background-image: url(${DiamondBackground});
  background-size: 350px auto;
  background-position: center;
  height: 100%;

  @media (max-width: 768px) {
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow: auto;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow: auto;
  }
`;

const LoginLogo = styled.img`
  display: flex;
  align-self: flex-start;
  width: 250px;
  height: auto;
  margin-bottom: 30px;
  margin-top: 10px;
  margin-left: 10px;

  @media (max-width: 768px) {
    align-self: center;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
  margin-right: 10px;
  margin-left: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-top: 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LoginTitle = styled.h3`
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 960px) {
    font-size: 30px;
    margin-top: 10px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 40px;
  }
`;

const LoginSubtitle = styled.p`
  color: #ced1d9;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin: 1rem 0;
  @media (max-width: 960px) {
    margin: 1.5rem -10px;
  }
`;
const CreateAccountButton = styled.button`
  border-radius: 5px;
  border: 2px solid #beaeff;
  display: inline-flex;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-right: 1rem;
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-right: 0rem;
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    width: fit-content;
    margin-top: 10px;
  }
`;

const ContinueButton = styled.button`
  border-radius: 5px;
  width: 100%;
  border: none;
  display: inline-flex;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  background-color: #504c5c;
  color: #9da0a7;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-bottom: 1rem;
    font-size: 14px;
    padding: 15px 10px;
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const FooterMenu = styled.div`
  display: flex;
  align-items: center;
`;
const FooterItems = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding-bottom: 1rem;
  padding-top: 0rem;

  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

const FooterItem = styled.div`
  color: #fbfcff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  justify-items: center;
  margin-right: 50px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 0.7rem;
    margin: 10px 5px 5px 5px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 0.9rem;
    margin: 10px;
  }
`;

const PrivacyLine = styled.p`
  color: #ced1d9;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0.7rem;

  @media (max-width: 768px) {
    font-size: 13px;
    margin: 0rem 0.5rem;
    width: 110%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px;
    margin: 0rem 2rem;
    width: 80%;
  }
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding: 25px 30px;

  @media (max-width: 768px) {
    padding: 5px 0px;
    position: relative;
    bottom: 0;
    left: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 20px 5px;
  }

  @media (max-height: 600px) {
    padding: 5px;
    position: relative;
  }
`;

const NameInput = styled.input`
  flex: 1;
  background: #1b1b23;
  border: 2px solid #504c5c;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0rem;
  font-size: 18px;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  width: 100%;
  height: 70px;

  ::placeholder {
    color: #9da0a7;
    opacity: 50%;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StepText = styled.p`
  color: #5c567b;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: -20px;
`;
const CountrySelect = styled.select`
  flex: 1;
  background: #1b1b23;
  border: 2px solid #504c5c;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0rem;
  font-size: 18px;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  width: 100%;
  height: 70px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
`;

const Checkbox = styled.input`
  margin-right: 8px;
  width: 35px;
  height: 35px;
`;
const PasswordValidBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  background: #2b2a38;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  padding: 0.7rem;
  margin-top: 15px;

  @media (max-width: 320px) {
    width: 100vw;
    padding: 5px;
    border-radius: 0px;
    margin-top: 5px;
  }
`;
const PasswordValidText = styled.div`
  flex-basis: 100%;
  & > p {
    color: #fbfcff;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
    margin-left: 5px;
  }
  @media (max-width: 320px) {
    width: 120%;
    font-size: 14px;
  }
`;

const TermsText = styled.p`
  color: #ced1d9;
  text-align: left;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin: 0.7rem 0;
  display: flex;
  flex-direction: row;

  @media (max-width: 320px) {
    width: 110%;
    margin-left: -10px;
    font-size: 18px;
    margin-top: 0rem;
  }

  & > a {
    color: #beaeff;
    font-weight: 700;
    cursor: pointer;
  }
`;
const SubmitForm = styled.form``;
