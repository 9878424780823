import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { MerchantListHead, MerchantMoreMenu } from '../sections/@dashboard/merchant';
import NewMerchantModal from '../components/modal/NewMerchantModal';
import Alert from "../components/common/Alert/Alert";
import { getMerchants } from '../api/MerchantsController';
import { getStatuses } from '../api/StatusesController';

const TABLE_HEAD = [
  { id: 'merchantName', label: 'Name', alignRight: false },
  { id: 'merchantBusinessType', label: 'Business Type', alignRight: false },
  { id: 'merchantContactEmail', label: 'Email', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'createdAt', label: 'Created', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Merchant() {
  const token = useSelector((state) => state?.user?.token);
  const userRole = useSelector((state) => state?.user?.role);
  const [filteredData, setFilteredData] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteMerchantStatus, setDeleteMerchantStatus] = useState(null);
  const [deleteMerchantMsg, setDeleteMerchantMsg] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const { data, refetch } = useQuery(
    ["merchants"],
    async () => {
      const merchants = await getMerchants(token);
      const allStatuses = await getStatuses(token);
      const mergedData = {
        merchants,
        allStatuses: allStatuses.statuses
      };
      return mergedData;
    },
    {
      enabled: false,
      retry: 1,
    }
  );
  useEffect(() => {
    refetch();
  }, []);  

  useEffect(() => {
    if(data){
      if (data.merchants.merchants) {
        const filteredMerchants = applySortFilter(data.merchants.merchants, getComparator(order, orderBy), filterName);
        setFilteredData(filteredMerchants);
      }
    }   
  }, [data, filterName, order, orderBy]);

  const handleDeleteMerchantComplete = (status, message) => {
    setDeleteMerchantStatus(status);
    setDeleteMerchantMsg(message);
  };

  if (deleteMerchantStatus === 404 || deleteMerchantStatus === 200 || 
    deleteMerchantStatus === 500 || deleteMerchantStatus === 400) {
    setTimeout(() => {
      setDeleteMerchantStatus(null);
      setDeleteMerchantMsg(null);
      window.location.reload();
    }, 3000);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.merchants.merchants.map((n) => n.merchantId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, status, firstName) => {
    const selectedIndex = selected.findIndex((item) => item.merchantId === name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { merchantId: name, status, firstName });
    } else if (selectedIndex === 0) {
      selected[0] = { ...selected[0], status, firstName };
      newSelected = selected.slice(1);
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleRemove = (event, action) => {
    // setStatusModal(true);
    // setModalAction(action);
  };

  const handleConfirmModal = (event) => {
    console.log('modal confirmed', event);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.merchants.totalMerchantsCount) : 0;
  
  return (
    <Page title="Merchant">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
          Merchants
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setModalOpen(true);
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Merchant
          </Button>
        </Stack>
        <Card>
          {/* <UserListToolbar
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleAction={(e, action) => {
              handleRemove(e, action);
            }}
          /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              {filteredData && (
                <Table>
                  <MerchantListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.merchants.totalMerchantsCount}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  
                  <TableBody>
                    
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { merchantId, merchantName, merchantBusinessType, merchantContactEmail, status, city,
                        unitNumber, street, country, postalCode, createdAt, userId, bgColor} = row;
                      const date = new Date(createdAt);
                      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                      const isItemSelected = selected.findIndex(obj => obj.id === merchantId) !== -1;
                      const fullAddress = `${unitNumber}, ${street}, ${city}, ${postalCode}, ${country}`;
                      return (
                        <TableRow
                          hover
                          key={merchantId}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, merchantId, status, merchantName)} />
                          </TableCell>

                          <TableCell>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              component={RouterLink}
                              to={`/dashboard/merchant-profile/${merchantId}`}
                              sx={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
                            >
                              <Avatar alt={merchantName} sx={{ bgcolor: `${bgColor}` }} src={`/static/images/avatar/${merchantId}.jpg`} />
                              <Typography variant="subtitle2" noWrap>
                                {merchantName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>{merchantBusinessType}</TableCell>
                          <TableCell>{merchantContactEmail}</TableCell>
                          <TableCell>
                            <Label variant="ghost" color={(status === true && 'error') || 'success'}>
                              {status === true ? 'Blocked' : 'Active'}
                            </Label>
                          </TableCell>
                          <TableCell>{fullAddress}</TableCell>
                          <TableCell>
                            {formattedDate}
                          </TableCell>
                          <TableCell align="right">
                            <MerchantMoreMenu
                              handleAction={(e, action) => {
                                handleRemove(e, action);
                              }}
                              merchant={row}
                              handleDeleteMerchantComplete={handleDeleteMerchantComplete} 
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {filteredData && data.merchants.totalMerchantsCount === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          {data && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data?.merchants.totalMerchantsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
          {deleteMerchantStatus === 404 && (
            <Alert type="error" message='No data found for merchant or already deleted.'/>
          )}
          {deleteMerchantStatus === 200 && (
            <Alert type="success" message={'Merchant deleted successfully.'} />
          )}
          {deleteMerchantStatus === 400 || deleteMerchantStatus === 500 && (
            <Alert type="error" message={deleteMerchantMsg}/>
          )}
      </Container>
      <NewMerchantModal
        open={isModalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
        allStatuses={data?.allStatuses}
      />
    </Page>
  );
}
