import React, { useState } from 'react';
import ModalContainer from '../../../components/intraverse/ui/ModalContainer';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';

import { getBroadcastingPermissions } from '../services/BroadcastController';

const BroadCasterModal = (props) => {
  const [openBanner, setOpenBanner] = useState(props.broadCaster ? 'MESSAGE' : '');
  const [password, setPassword] = useState('');

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById('submit-button').click();
    }
  };

  const fetchBroadcastPermissions = () => {
    setOpenBanner('');

    getBroadcastingPermissions(props.roomName, password)
      .then((res) => {
        if (res) {
          localStorage.setItem('currentIdentity', res.identity);
          props.setBroadCaster(true);
          setOpenBanner('MESSAGE');
          setPassword('');
          return startBroadcast(res.token);
        }

        return setOpenBanner('ERROR');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const startBroadcast = async (token) => {
    const audioStream = await getMedia();
    const audioTrack = new window.LivekitClient.LocalAudioTrack(audioStream.getAudioTracks()[0]);

    const room = new window.LivekitClient.Room();
    await room.connect(process.env.REACT_APP_BROADCAST_ROOM_ID, token);
    room.localParticipant.publishTrack(audioTrack);
    props.setRoom(room);
  };

  const getMedia = async () => {
    let audioContextValue = props.audioContext;
    let streamDestinationValue = props.streamDestination;
    try {
      if (!audioContextValue) {
        const desiredSampleRate = 96000;
        const audioContextOptions = {
          sampleRate: desiredSampleRate,
        };
        const newAudioContext = new AudioContext(audioContextOptions);
        audioContextValue = newAudioContext;
        props.setAudioContext(newAudioContext);
      }
      if (!streamDestinationValue) {
        streamDestinationValue = audioContextValue.createMediaStreamDestination();
        props.setStreamDestination(streamDestinationValue);
      }

      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const voiceInput = audioContextValue.createMediaStreamSource(micStream);
      voiceInput.connect(streamDestinationValue);

      return streamDestinationValue.stream;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ModalContainer
        header={<Header functinoLogic={() => props.setOpenBroadcastModal(false)} />}
        body={
          <Body
            openBanner={openBanner}
            setOpenBanner={setOpenBanner}
            password={password}
            setPassword={setPassword}
            broadCaster={props.broadCaster}
            handleInputKeyDown={handleInputKeyDown}
          />
        }
        footer={
          <Footer
            buttonFunction={
              props.broadCaster
                ? () => {
                    props.disconnect();
                    setOpenBanner('');
                  }
                : () => fetchBroadcastPermissions()
            }
            value={props.broadCaster ? props.broadCaster : password}
            broadCaster={props.broadCaster}
          />
        }
      />
    </>
  );
};
export default BroadCasterModal;
