import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { Container, Typography } from '@mui/material';
import styled from '@emotion/styled';

import FooterContainer from '../components/FooterContainer';
import { sendResetEmail } from '../api/UsersController'
// sections

import IntraverseLogoFull from './intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
import DiamondBackground from './intraverseLandingPage/IntraverseHomeImages/Diamond_Checkered_Background.png';

export default function ResetPassword() {
  const [message, setMessage] = useState(null);

  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1);

  const [emailValid, setEmailValid] = useState(false);

  const APP_URL = `${window.location.protocol}//${window.location.host}`;

  const sendRecoveryEmail = async() => {
    if (validateEmail(email)) {
      const result = await sendResetEmail(email, `${APP_URL}/reset-password`);
      if (result.status === 200) {
        console.log(result)
        setStep(2);
      }else if(result.status === 404){
        setMessage("The provided email address is not associated with an account")
      } else {
        console.log(result)
        setMessage("Error sending a email")
      }
    } else {
      console.log('EMAIL NOT VALID');
    }
  };

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  useEffect(() => {
    if (validateEmail(email)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }, [email]);

  return (
    <LoginContainer>
      <BorderOutlineStroke>
        <HeaderDiv>
          <Link to="/">
            <LoginLogo src={IntraverseLogoFull} alt="Company Logo" />
          </Link>
          <Link to="/register">
            <CreateAccountButton> Create Account</CreateAccountButton>
          </Link>
        </HeaderDiv>
        {step === 1 ? (
          <Container maxWidth="sm" sx={{ padding: 0, marginTop: '-20px' }}>
            <LoginTitle>RESET YOUR PASSWORD</LoginTitle>
            <LoginSubtitle>
              It happens! We'll help rest your password. Start by providing the email address associated with the
              account.
            </LoginSubtitle>
            {message && <Typography sx={{ color: '#FF8484', mb: 3, textAlign: "center" }}>{message}</Typography>}
            
            <NameInput
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
            />
            <ContinueButton
              style={{
                background: emailValid ? 'linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%)' : '#504C5C',
                color: emailValid ? 'white' : '#9DA0A7',
              }}
              onClick={
                () => sendRecoveryEmail()
              }
            >
              Send Recovery Email
            </ContinueButton>

            <Link
              style={{
                fontSize: '18px',
                color: '#BEAEFF',
                textTransform: 'uppercase',
                fontWeight: '700',
                display: 'flex',
                justifyContent: 'center',
                margin: '20px',
                textAlign: 'center',
                textDecoration: 'none'
              }}
              component={Link}
              underline="hover"
              to="/login"
            >
              Back to sign in
            </Link>
            
            {/* <FooterContainer/> */}
            
          </Container>
        ) : (
          <Container maxWidth="sm" sx={{ padding: 0, marginTop: '-20px' }}>
            <LoginTitle>Recovery Email Sent!</LoginTitle>
            <LoginSubtitle>
              Please check your inbox for instructions resetting your password. An email sent to:
            </LoginSubtitle>

            <EmailDiv>{email}</EmailDiv>
            <Link to={"/login"}>
              <ContinueButton
                style={{
                  background: emailValid ? 'linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%)' : '#504C5C',
                  color: emailValid ? 'white' : '#9DA0A7',
                }}
              >
                Sign in Intraverse
              </ContinueButton>
            </Link>

            <StepText
                style={{ color: '#BEAEFF', cursor: 'pointer', marginTop: '0.5rem', marginBottom: '0.6rem' }}
                onClick={
                  () => sendRecoveryEmail()
                }
              >
                Resend Email
              </StepText>

              {/* <FooterContainer/> */}
          </Container>
        )}
      </BorderOutlineStroke>
    </LoginContainer>
  );
}

// ----------------------------------------------------------------------

const BorderOutlineStroke = styled.div`
  z-index: 3000;
  border: 3px solid rgba(217, 217, 217, 0.2);
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    padding: 5px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 5px;
  }
`;

const LoginContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: rgba(27, 27, 35, 1);
  background-image: url(${DiamondBackground});
  background-size: 350px auto;
  background-position: center;
  height: 100vh;
  @media (min-width: 768px) {
    height: 100%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 100vh;
  }
`;

const EmailDiv = styled.div`
  /* cursor: pointer; */
  height: 70px;
  /* width: 250px; */
  border-radius: 10px;
  background: rgba(37, 24, 89, 0.5);
  font-family: 'Barlow', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 2rem 0 0 2rem; */

  color: #beaeff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  /* text-transform: uppercase; */

  @media (max-width: 768px) {
    height: 50px;
    width: 100%;
    padding-left: 0rem;
    padding-top: 0.7rem;
    padding: 0rem;
    margin-top: 1rem;
    margin-bottom: 1rem; 
    font-size: 20px;
  }

  @media  (min-width: 768px) and (max-width: 1440px) {
    padding-left: 0.5rem;
    height: 70px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    width: 100%;
  }
`;

const LoginLogo = styled.img`
  display: flex;
  align-self: flex-start;
  width: 250px;
  height: auto;
  margin-bottom: 30px;
  margin-top: 10px;
  margin-left: 10px;

  @media (max-width: 768px) {
    align-self: center;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
  margin-right: 10px;
  margin-left: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-top: 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LoginTitle = styled.h3`
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 50px;
  margin-top: -0.7rem;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 960px) {
    font-size: 40px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 40px;
  }
`;

const LoginSubtitle = styled.p`
  color: #ced1d9;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin: 1rem 0;
  @media (max-width: 960px) {
    margin: 2rem -10px;
  }
`;
const CreateAccountButton = styled.button`
  border-radius: 5px;
  border: 2px solid #beaeff;
  display: inline-flex;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-right: 1rem;
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-right: 0rem;
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    width: fit-content;
    margin-top: 10px;
  }
`;

const NameInput = styled.input`
  flex: 1;
  background: #1b1b23;
  border: 2px solid #504c5c;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0rem;
  font-size: 18px;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  width: 100%;
  height: 70px;

  ::placeholder {
    color: #9da0a7;
    opacity: 50%;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContinueButton = styled.button`
  border-radius: 5px;
  width: 100%;
  border: none;
  display: inline-flex;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  background-color: #504c5c;
  color: #9da0a7;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-bottom: 1rem;
    font-size: 14px;
    padding: 15px 10px;
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const StepText = styled.p`
  color: #5c567b;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: -20px;
`;
