const defaultState = {
  clientLogo: '',
  backgroundImage: '',
  freeformJson: {},
  clientName: '',
  emailLogin: true,
  spotifyLogin: false,
  broadcast: false,
  music: '',
};

const VanityLinkReducer = (state, action) => {
  switch (action.type) {
    case 'VANITY_LINK_SAVE': {
      return {
        ...state,
        clientLogo: action.payload.clientLogo,
        backgroundImage: action.payload.backgroundImage,
        freeformJson: action.payload.freeformJson,
        clientName: action.payload.clientName,
        emailLogin: action.payload.emailLogin,
        spotifyLogin: action.payload.spotifyLogin,
        broadcast: action.payload.broadcast,
        music: action.payload.music,
      };
    }

    case 'DELETE_VANITY_LINK': {
      return defaultState;
    }

    default:
      if (state === undefined) {
        return defaultState;
      }
      return state;
  }
};

export default VanityLinkReducer;
