import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import nextButton from '../../assets/svg/UI_Button_Primary_Next.svg';
import female1 from '../../assets/images/Avatars/female1.png';
import female2 from '../../assets/images/Avatars/female2.png';
import female3 from '../../assets/images/Avatars/female3.png';
import female4 from '../../assets/images/Avatars/female4.png';
import male1 from '../../assets/images/Avatars/male1.png';
import male2 from '../../assets/images/Avatars/male2.png';
import male3 from '../../assets/images/Avatars/male3.png';
import male4 from '../../assets/images/Avatars/male4.png';
import toggleButton from '../../assets/images/Icon-CTA.png';
import { setToLocalStorage } from '../../hooks/useLocalStorage';
import { isMobileDevice } from '../../utils/utils';
import UIButton from '../../components/intraverse/ui/Button';

function AvatarPresetPage({ setSelectedAvatar }) {
  const isMobile = isMobileDevice();
  const sliderRef = useRef(null);
  const selectedImageRef = useRef(null);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [selectedAvatarId, setSelectedAvatarId] = useState(null);
  const [selectedAvatarIndexSet, setSelectedAvatarIndexSet] = useState(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const avatars = [
    { name: 'female2', src: female2, index: 0 },
    { name: 'male4', src: male4, index: 1 },
    { name: 'female4', src: female4, index: 2 },
    { name: 'male1', src: male1, index: 3 },
    { name: 'female1', src: female1, index: 4 },
    { name: 'male3', src: male3, index: 5 },
    { name: 'female3', src: female3, index: 6 },
    { name: 'male2', src: male2, index: 7 },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current) {
        const containerWidth = sliderRef.current.clientWidth;
        const totalWidth = sliderRef.current.scrollWidth;
        const { scrollLeft } = sliderRef.current;

        setShowPrevButton(scrollLeft > 0);
        setShowNextButton(scrollLeft + containerWidth < totalWidth);
      }
    };

    if (sliderRef.current) {
      sliderRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (sliderRef.current) {
        sliderRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollAmount]);

  const handleScroll = (direction) => {
    if (sliderRef.current) {
      const containerWidth = sliderRef.current.clientWidth;

      const itemWidth = sliderRef.current.clientWidth / (isMobile ? 1 : 2);
      const totalItems = avatars.length;

      let newScrollAmount;

      if (direction === 'next') {
        newScrollAmount = Math.min(scrollAmount + itemWidth, totalItems * itemWidth - containerWidth);
      } else {
        newScrollAmount = Math.max(scrollAmount - itemWidth, 0);
      }

      const centerPosition = newScrollAmount === 0 ? 0 : containerWidth / 2 - itemWidth / 2;

      sliderRef.current.scrollTo({
        left: newScrollAmount + centerPosition,
        behavior: 'smooth',
      });

      setScrollAmount(newScrollAmount);
    }
  };

  const handleAvatarClick = (avatar) => {
    setSelectedAvatarIndexSet(avatar.index);
    setSelectedAvatarId(avatar.name);

    selectedImageRef.current = document.getElementById(`image-${avatar.index}`);

    selectedImageRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  const handleSubmit = () => {
    setSelectedAvatar(selectedAvatarId);
    setToLocalStorage('selectedAvatar', selectedAvatarId);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    const aspectRatio = innerWidth / innerHeight;
    return { innerWidth, innerHeight, aspectRatio };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <App>
      <Button onClick={() => handleScroll('prev')} visible={showPrevButton}>
        <ToggleButtonLeft src={toggleButton} />
      </Button>
      <ImagesContainer ref={sliderRef}>
        {avatars.map((image) => (
          <Image
            id={`image-${image.index}`}
            onClick={() => handleAvatarClick(image)}
            alt="sliderImage"
            key={image?.index}
            src={image?.src}
            selectedAvatarIndex={image?.index}
            selectedAvatarId={selectedAvatarIndexSet}
            isMobile={isMobile}
          />
        ))}
      </ImagesContainer>
      <Button visible={showNextButton} right onClick={() => handleScroll('next')}>
        <ToggleButtonRight src={toggleButton} />
      </Button>
      <AvatarBottomBanner windowSize={windowSize}>
        <Copy windowSize={windowSize}>
          <h1>Select your in-game character</h1>
          <p>
            Scroll <YellowText>left</YellowText> or <YellowText>right</YellowText> to choose your avatar to explore
            with.
          </p>
        </Copy>

        <AvatarButton>
          <UIButton
            style={AvatarButton}
            text="Continue"
            func={handleSubmit}
            greyBoxText="Select Avatar"
            value={selectedAvatarId}
          />
        </AvatarButton>
      </AvatarBottomBanner>
    </App>
  );
}

export default AvatarPresetPage;

const App = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 4;
  height: 100%;
  width: 100%;
`;

const ImagesContainer = styled.div`
  display: flex;
  width: 100%;
  height: inherit;
  overflow: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  transition: scroll 0.1s ease-in-out;

  @media (max-width: 730px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  /* SCROLLBAR SPECIFICATIONS */
  scrollbar-width: thin; /* For Firefox */

  &::-webkit-scrollbar {
    width: 5em; /* Adjust the width as needed */
    border-width: 1px;
  }

  &::-webkit-scrollbar-track {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    border-width: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(27, 27, 35, 0.7) 0%, rgba(27, 27, 35, 0.5) 100%);
    border-radius: 10px; /* Adjust the border-radius as needed */
    border-width: 1px;
  }
`;

const Image = styled.img`
  cursor: pointer;
  width: auto;
  height: auto;
  border-right: 3px solid rgba(27, 27, 35, 0.5);
  padding-bottom: 15px;
  padding-top: 7rem;
  padding-bottom: 8rem;

  @media (max-width: 730px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media (max-width: 320px) {
    padding-top: 2rem;
    padding-bottom: 3rem;
    width: fit-content;
    height: fit-content;
    margin-top: 3rem;
  }

  &:hover {
    box-shadow: 0px 8px 60px 0px rgba(190, 174, 255, 0.75) inset;
  }

  ${(props) =>
    props.selectedAvatarIndex === props.selectedAvatarId &&
    `
    border: 6px solid #beaeff;
    box-shadow: 0px 8px 60px 0px rgba(190, 174, 255, 0.75) inset;
  `}
`;

const Button = styled.button`
  ${(props) => (props.visible ? 'display: block;' : 'display: none;')}
  margin: 20px;
  top: calc(100% / 2 - 125px);
  position: fixed;
  ${(props) => (props.right ? 'right: 0;' : 'left: 0;')}
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;

  @media (max-width: 730px) {
    margin: 10px;
    top: calc(100% / 2 - 150px);
  }
`;

const ToggleButtonLeft = styled.img`
  transform: rotate(-180deg);
`;

const ToggleButtonRight = styled.img``;

const EnterButton = styled.img`
  cursor: pointer;
  height: 70px;
  width: 100%;
  background-size: cover;
  background-position: center;

  @media (max-width: 960px) {
    padding-left: 0rem;
  }
`;

const YellowText = styled.span`
  color: #ffe663;
  font-weight: 700;
  text-decoration: none;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const AvatarSlot = styled.div`
  // display: flex;
  // flex: 1;
  // height: 100%;

  // @media (max-width: 730px) {
  //   flex-direction: column;
  // }
  // /* @media (max-width: 1440px) {
  //   flex-direction: column;
  // } */
`;

const Copy = styled.div`
  flex: 2;
  color: white;
  padding: 20px;
  font-family: 'Barlow', sans-serif;

  @media (max-width: 768px) {
    text-align: center;
    & > h1 {
      font-size: 26px;
    }
    & > p {
      font-size: 14px;
    }
  }
  @media (max-width: 1440px) {
    & > h1 {
      ${(props) => (props.windowSize.aspectRatio > 1 ? 'font-size: 22px;' : 'font-size: 26px;')}
    }
    & > p {
      font-size: 16px;
    }
  }
`;

const AvatarBottomBanner = styled.div`
  background: linear-gradient(180deg, rgba(27, 27, 35, 0.7) 0%, rgba(27, 27, 35, 0) 100%);
  height: 150px;
  padding: 2rem;
  padding-left: 4rem;
  width: 100vw;
  display: flex;
  align-items: center;

  ${(props) => props.windowSize.aspectRatio > 1 || (props.windowSize.innerWidth > 731 && 'height: 100px;')}

  @media (max-width: 739px) {
    height: 150px;
    height: auto;
    padding: 0.5rem;

    ${(props) => (props.windowSize.aspectRatio > 1 ? 'flex-direction: row;' : 'flex-direction: column;')}
  }
  @media (max-width: 1440px) {
    /* max-height: 100px; */
    /* height: 120px; */
    padding: 0.3rem;
  }
`;

const AvatarButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 10;

  @media (max-width: 960px) {
    padding: 0rem;
    position: initial;
    padding: 0rem;
    margin: 0rem 0.6rem;
    height: 90px;
    width: 230px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    padding: 0rem;
    position: initial;
    padding: 0rem;
    margin: 1rem;
  }
`;
