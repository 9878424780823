import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Container, Button } from '@mui/material';
import VagonStream from './VagonStream';

const CallToActionPage = () => {
  const [showIFrame, setShowIFrame] = useState(false);

  const navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };
  return showIFrame ? (
    <VagonStream
      streamUrl={
        'https://streams.vagon.io/streams/43c7f4b2-5a5c-4930-8325-59703d13c050?launchFlags=-room_id%2056e8439a-1a9c-11ee-be56-0242ac120002'
      }
    />
  ) : (
    <Container maxWidth="lg">
      <Typography variant="h2" gutterBottom color="white">
        Welcome to Intraverse!
      </Typography>
      <Typography variant="h2" gutterBottom color="white">
        Take your next step ...
      </Typography>
      <Box mt={4} mb={2}>
        <Button
          variant="contained"
          onClick={() => {
            setShowIFrame(true);
          }}
          color="primary"
          size="large"
          style={{ marginRight: 12 }}
        >
          Enter Intraverse
        </Button>
        <Button variant="outlined" color="primary" size="large" onClick={() => routeChange('/dashboard/app')}>
          Enter User Dashboard
        </Button>
      </Box>
    </Container>
  );
};

export default CallToActionPage;
