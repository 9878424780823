import { useRef, useState } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Button, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { deleteMerchant } from '../../../api/MerchantsController';
import Iconify from '../../../components/Iconify';
import StatusModal from '../../../components/common/Modal/StatusModal';
import Alert from "../../../components/common/Alert/Alert";

export default function MerchantMoreMenu(props) {
  const token = useSelector((state) => state?.user?.token);
  const { handleAction, merchant } = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [statusModal, setStatusModal] = useState(false);

  const handleDeleteMerchant = async () => {
    const result = await deleteMerchant(token, merchant.merchantId);
    props.handleDeleteMerchantComplete(result.status, result.message);
  };

  return (
    <>
      
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterLink} to={`/dashboard/merchant-profile/${merchant.merchantId}`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
        sx={{ color: 'text.secondary' }}
        onClick={handleDeleteMerchant}
        >
        <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
