import { fetchVanityLinkByRoomId } from '../api/VanityLinkController';
import '../VagonStream.css';

export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const isMobileDevice = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobile;
};

/**
 * Validates a username based on the following criteria:
 * - Should be between 3 and 20 characters.
 * - Can contain letters (both uppercase and lowercase), numbers, underscores, and hyphens.
 * - Must start with a letter.
 * @param {string} username - The username to validate.
 * @returns {boolean} - True if the username is valid, false otherwise.
 */
export const validateUsername = (username) => {
  const usernameRegex = /^[a-zA-Z][a-zA-Z0-9_-]{2,19}$/;
  return usernameRegex.test(username);
};

export const secondsToHms = (d) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay + sDisplay;
};

export const changeURLByRoomId = async (roomId) => {
  try {
    const vanityLink = await fetchVanityLinkByRoomId(roomId);

    if (vanityLink && vanityLink?.vanityLink) {
      // window.history.pushState({}, '', vanityLink?.vanityLink);
      return vanityLink?.vanityLink;
    }

    if (!vanityLink) {
      window.history.pushState({}, '', '/'); // Set URL to an empty string
    }

    return null;
  } catch (error) {
    console.error('Error fetching vanity link data:', error);
    return null;
  }
};

export const enterFullScreen = (elementRef) => {
  if (elementRef.current) {
    if (elementRef.current.requestFullscreen) {
      elementRef.current.requestFullscreen();
    } else if (elementRef.current.mozRequestFullScreen) {
      // Firefox
      elementRef.current.mozRequestFullScreen();
    } else if (elementRef.current.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elementRef.current.webkitRequestFullscreen();
    } else if (elementRef.current.msRequestFullscreen) {
      // Edge
      elementRef.current.msRequestFullscreen();
    } else if (elementRef.current.webkitEnterFullscreen) {
      // For iOS Safari
      elementRef.current.webkitEnterFullscreen();
    } else if (elementRef.current.webkitEnterFullScreen) {
      // For iOS Chrome (tentative)
      elementRef.current.webkitEnterFullScreen();
    }
    // updateOrientation();
  }
};

export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    // Chrome, Safari, and Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    // Edge
    document.msExitFullscreen();
    // eslint-disable-next-line no-dupe-else-if
  } else if (document.webkitExitFullscreen) {
    // For iOS Safari
    document.webkitExitFullscreen();
  } else if (document.webkitExitFullScreen) {
    // For iOS Chrome (tentative)
    document.webkitExitFullScreen();
  }
};

export const updateOrientation = (setLandscape) => {
  // first check to ensure it is mobile user
  if (!isMobileDevice) return;
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const aspectRatio = width / height;
  const iframeElement = document.getElementById('vagonFrame');

  // second check to ensure aspect Ratio is below 1
  if (aspectRatio < 1) {
    iframeElement.classList.add('force-landscape');
    iframeElement.style.removeProperty('width');
    iframeElement.style.removeProperty('height');
    setLandscape(true);
  } else {
    iframeElement.classList.remove('force-landscape');
    iframeElement.style.width = '100vw';
    iframeElement.style.height = '100vh';
    setLandscape(false);
  }
};
