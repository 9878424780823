import cities from 'cities.json';
import { API_GET, API_POST, API_DELETE } from './API_FETCH';
import { COREAPI_WEB_MERCHANTS_URL } from '../constants';

const MERCHANTS_COREAPIURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_MERCHANTS_URL}`

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i+=1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export async function getMerchants(token) {
  try{
    const resp =  await API_GET(`${MERCHANTS_COREAPIURL}`, token);
    if(resp.status === 200){
      const respData = resp.merchants;
      for (let index = 0; index < respData.length; index+=1) {
        const element = respData[index];
        element.bgColor = getRandomColor();
      }
      return resp;
    }
    return resp;
  }
  catch (err) {
    console.log(err);
    return 500
  }
}

export async function getMerchant(token, merchantId) {
  try {
    return await API_GET(`${MERCHANTS_COREAPIURL}/${merchantId}`, token);
  }
  catch (err) {
      return 500
  }
}

export async function RegisterMerchant(data, token) {
  try {
      return await API_POST(`${MERCHANTS_COREAPIURL}`, data, token, 'POST');
  }
  catch (err) {
      return 500
  }
}

export async function updateMerchant(token, merchantId, data) {
  try {
    return await API_POST(`${MERCHANTS_COREAPIURL}/${merchantId}`, data, token, 'PUT');
  }
  catch (err) {
      return 500
  }
}

export async function deleteMerchant(token, merchantId) {
  try {
    return await API_DELETE(`${MERCHANTS_COREAPIURL}/${merchantId}`, token);
  } catch (error) {
    return 500
  }
}

export async function getCities() {
  try {
    const filteredCities = cities.filter(city => ["CA", "US"].includes(city.country));
    // Add a unique ID to each city object
    filteredCities.forEach((city, index) => {
      city.id = index + 1;
      city.name = `${city.name}, ${city.country}`;
    });
    return filteredCities;
  }
  catch (err) {
      return 500
  }
}
