import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Avatar, DialogActions, IconButton, InputBase } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Iconify from '../Iconify';
import Loader from '../Loader';
import { getUsers } from '../../api/UsersController';
import axiosInstance from '../../config/axiosConfig';

const ItemListStyle = {
  border: '1px solid',
  borderRadius: '5px',
  borderColor: 'lightgrey',
  boxSizing: 'border-box',
};

function VirtualizedList({ filteredUsers, handleSelect }) {
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', boxSizing: 'border-box', padding: '20px' }}>
      {filteredUsers.map((user, i) => (
        <ListItem
          style={ItemListStyle}
          key={i}
          component="div"
          disablePadding
          onClick={() => {
            handleSelect(user);
          }}
        >
          <ListItemButton>
            <Avatar />
            <MemoizedListItemText user={user} onClick={() => console.log(user)} />
          </ListItemButton>
        </ListItem>
      ))}
    </Box>
  );
}

const MemoizedListItemText = React.memo(({ user, onClick }) => (
  <ListItemText
    style={{
      marginLeft: '10px',
    }}
    primary={`${user.name}`}
    secondary={`${user.email}`}
    userid={`${user.userId}`}
    onClick={onClick}
  />
));

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    minWidth: '600px',
    alignItems: 'center',
    display: 'flex',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    paddingLeft: '27px',
    paddingRight: '18px',
  },
  '& .MuiDialogActions-root': {
    overflowX: 'hidden',
    paddingLeft: '27px',
    paddingRight: '18px',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  paddingTop: '5px',
  paddingBottom: '5px',
  boxSizing: 'border-box',
  border: '1px solid',
  borderColor: 'lightgrey',
  borderRadius: '5px',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

VirtualizedList.propTypes = {
  filteredUsers: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddCollaboratorsModal() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const token = useSelector((state) => state?.user?.token);
  const [searching, setSearching] = useState(false); // New state to track searching state
  const [cancelTokenSource, setCancelTokenSource] = useState(null); // New state to store cancel token source
  const [apiLoading, setApiLoading] = useState(false); // New state to track API loading state

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddCollaborators = () => {
    alert('TODO -> Add collaborator');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendInvitation = async () => {
    // To Do Send Invitation create here Fetch Function
  };

  const inviteUser = async () => {
    await sendInvitation(selectedUser);
    setUsers([]);
    setFilteredUsers([]);
    setIsUserSelected(false);
    setSelectedUser(null);
    setSearchValue('');
    setOpen(false);
  };

  const cancelPreviousSearch = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Previous search cancelled');
    }
  };

  const handleSearch = async (value) => {
    if (value === '') {
      setUsers([]);
      setFilteredUsers([]);
      setSearchValue('');
      cancelPreviousSearch();
      return;
    }

    setLoading(true);
    setSearchValue(value);
    cancelPreviousSearch();

    const source = axiosInstance.CancelToken.source();
    setCancelTokenSource(source);

    setSearching(true); // Start searching

    try {
      setApiLoading(true); // Start API loading
      const fetchedUsers = await getUsers(token, value, { cancelToken: source.token });
      const newData = fetchedUsers.data.map(({ userId, email, firstName, lastName }) => ({
        userId,
        email,
        name: `${firstName} ${lastName}`,
      }));
      const filteredUsers = newData.filter((user) => {
        const name = user.name.toLowerCase();
        const email = user.email.toLowerCase();
        const searchValue = value.toLowerCase();
        return name.includes(searchValue) || email.includes(searchValue);
      });
      setUsers(newData);
      setFilteredUsers(filteredUsers);
    } catch (error) {
      if (axiosInstance.isCancel(error)) {
        console.log('Search cancelled');
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
      setApiLoading(false); // Stop API loading
      setSearching(false); // Stop searching
    }
  };

  const handleSelect = (user) => {
    // console.log("selected user", user);
    setIsUserSelected(true);
    setSelectedUser(user);
  };

  const handleUnselectUser = () => {
    // console.log("unselect user");
    setIsUserSelected(false);
    setSelectedUser(null);
    setUsers([]);
    setFilteredUsers([]);
    setSearchValue('');
  };

  return (
    <div>
      <Button onClick={handleClickOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
        Add Collaborators
      </Button>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <Box padding={3} alignItems="center" display="flex" flexDirection="column">
          <GroupAddIcon padding={5} />
          {isUserSelected ? (
            <Typography align="center" marginTop="5px">
              {`Do you want to add ${selectedUser.name} as a collaborator`}
            </Typography>
          ) : (
            <Typography align="center" marginTop="5px">
              Search for a collaborator
            </Typography>
          )}
        </Box>

        {isUserSelected ? (
          <ListItem component="div" style={ItemListStyle}>
            <ListItemButton>
              <Avatar />
              <ListItemText
                onClick={inviteUser}
                primary={`${selectedUser.name}`}
                secondary={`${selectedUser.email}`}
                userid={`${selectedUser.userId}`}
                style={{ marginLeft: '10px' }}
              />
              <button onClick={handleUnselectUser} style={{ border: 'none' }}>
                <DeleteIcon />
              </button>
            </ListItemButton>
          </ListItem>
        ) : (
          <>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by username, full name or email"
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => {
                  e.preventDefault();
                  handleSearch(e.target.value);
                }}
              />
            </Search>
            {searching ? <Loader /> : <VirtualizedList filteredUsers={filteredUsers} handleSelect={handleSelect} />}
          </>
        )}

        <DialogContent dividers />
        <DialogActions
          style={{
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isUserSelected ? (
            <Button onClick={handleAddCollaborators} variant="contained" style={{ width: '500px' }}>
              Add
            </Button>
          ) : null}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
