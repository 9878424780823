// @mui
import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
// components
import Page from '../../components/Page';
// sections
import { AppProductCard } from '../../sections/@dashboard/app';
import { fetchUserName } from '../../redux/slices/users';
// ----------------------------------------------------------------------
const products = [
  { btnText: 'sign in' },
  { btnText: 'sign in' },
  {
    cardColor: 'pink',
    btnColor: 'violet',
    btnHoverColor: 'purple',
  },
  {
    cardColor: 'pink',
    btnColor: 'violet',
    btnHoverColor: 'purple',
  },
  {
    cardColor: 'pink',
    btnColor: 'violet',
    btnHoverColor: 'purple',
  },
  { btnText: 'sign in' },
];

export default function DashboardApp() {
  const user = useSelector(fetchUserName);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi {user}, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {products.map((product, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <AppProductCard
                btnText={product.btnText}
                cardColor={product.cardColor}
                btnColor={product.btnColor}
                btnHoverColor={product.btnHoverColor}
                logoUrl={product.logoUrl}
                sx={product.sx}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
