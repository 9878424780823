import React from 'react'
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { fetchUserToken } from '../../redux/slices/users';
import axiosInstance from '../../config/axiosConfig';
import { ReactComponent as PurpleDot } from './icons/purple_dot.svg';

const DisconnectTab = ({setDisconnectTabOn}) => {

 const token = useSelector(fetchUserToken);

 const disconnectAccount = () => {
  const config = {
    method: 'delete',
    url: `${process.env.REACT_APP_CORE_API_URI}/web/stripe/disconnect_account`,
    headers: { 
      'Authorization': `Bearer ${token}`
    }
  };
  
  axiosInstance.request(config)
    .then(response => {
      console.log(response)
      window.location.reload();
    })
    .catch(error => {
      console.error(error);
    });
 }

 return (
   <Container>
     <UserConsoleText>Remove payment method?</UserConsoleText>
     <LoginSubtitle>This will remove the payment method setup on your Intraverse account. Doing so will prevent th following:</LoginSubtitle>

     <Card>
         <BulletPointRow>
           <IconHolder>
             <PurpleDot/>
           </IconHolder>
           <BulletText>You can no longer purchase dollar items from Intraverse and PRO stores.</BulletText>
         </BulletPointRow>
         <BulletPointRow>
           <IconHolder>
             <PurpleDot/>
           </IconHolder>
           <BulletText>Your PRO store will become inaccessible to other players and you won’t receive or process new orders.</BulletText>
         </BulletPointRow>
     </Card>

       <ButtonHolder>
         <DisconnectButton noMargin
             onClick={() => {
               disconnectAccount()
             }}
           >
             Yes, remove
         </DisconnectButton>
         <PinkButton
           onClick={() => setDisconnectTabOn(false)}
         >
           No, Go Back
         </PinkButton>
       </ButtonHolder>
  </Container>
 )
}

const UserConsoleText = styled.h1`
  color: #beaeff;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 48px;
  text-align: center;
  padding-top: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin: 1rem 0rem;
`;

const LoginSubtitle = styled.p`
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--text-light-grey, #CED1D9);
  text-align: center;
  font-family: Barlow;
  font-size: 22px;
  padding-top: 2rem;

  /* margin: 1rem 0; */
  @media (max-width: 960px) {
    /* margin: 2rem -10px; */
  }
`;

const BulletPointRow = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 1rem 2rem;
`

const Container = styled.div`
  @media (max-width: 768px) {
    padding-top: 40px;
  }
`

const Card = styled.div`
  width: 600px;
  padding-top: 35px;
  height: 300px;
  border-radius: 20px;
  background: var(--bg-primary-100, #1b1b23);
  margin: auto;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 90vw;
    padding-top: 50px;
  }
`;

const DisconnectButton = styled.button`
  border-radius: 5px;
  border: 2px solid #beaeff;
  display: inline-flex;
  padding: 15px 35px;
  max-height: 60px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-right: 1rem;
  margin-top: 10px;
  cursor: pointer;
  margin-right: ${props => props.noMargin ? `0px` : ""};
  width: ${props => props.noMargin ? `100%` : ""};
  

  @media (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-right: 0rem;
    margin-top: 3rem;
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    width: fit-content;
    margin-top: 10px;
    margin-right: ${props => props.noMargin ? `0px` : ""};
    width: ${props => props.noMargin ? `100%` : ""};
  }
`;


const BulletText = styled.p`
    font-family: Barlow;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #CED1D9;
  `

  const IconHolder = styled.div`
    display: flex;
    /* background-color: green; */
    padding-top: 4px;
    min-width: 30px;
    justify-content: space-evenly;
  `

  const ButtonHolder = styled.div`
    margin: auto;
    width: 600px;
    /* background-color: green; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (min-width: 768px) and (max-width: 1440px) {
      width: 600px;
      margin-top: 10px;
      margin-right: ${props => props.noMargin ? `0px` : ""};
      width: ${props => props.noMargin ? `100%` : ""};
    }
    @media (max-width: 768px) {
      width: 90%;
      margin-top: 10px;
      margin-right: ${props => props.noMargin ? `0px` : ""};
      width: ${props => props.noMargin ? `100%` : ""};
    }
  `

  const PinkButton = styled.button`
    margin-top: 1rem;
    display: inline-flex;
    padding: 20px 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--Logo, linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%));
    color: var(--text-primary, #fbfcff);
    text-align: center;
    font-family: Barlow;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    width: 100%;
  `;

export default DisconnectTab