import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import NewUserModal from '../components/modal/NewUserModal';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import StatusModal from '../components/common/Modal/StatusModal';
import SkeletonTable from '../components/SkeletonTable';
import { getUsers, getRoles } from '../api/UsersController';
import { USER_DISPLAY_ROLE } from '../constants';

const TABLE_HEAD = [
  { id: 'firstName', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'displayName', label: 'Display Name', alignRight: false },
  { id: 'active', label: 'Status', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'createdAt', label: 'Created', alignRight: false },
  { id: '' },
];

export default function User() {
  const token = useSelector((state) => state?.user?.token);
  const [filteredData, setFilteredData] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setModalOpen] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [totalUsersCount, setTotalUsersCount] = useState(0);

  const { refetch, isFetching } = useQuery(
    ['users'],
    async () => {
      try {
        const users = await getUsers(page, rowsPerPage, filterName, orderBy, order);

        setFilteredData(users.data);
        setTotalUsersCount(users.totalUsersCount);
        return true;
      } catch (err) {
        setAllRoles([]);
        setFilteredData([]);
        return err;
      }
    },
    {
      enabled: false,
      retry: 1,
    }
  );

  useEffect(() => {
    setPage(0);
  }, [filterName]);

  useEffect(() => {
    refetch();
  }, [refetch, page, rowsPerPage, filterName, orderBy, order]);

  useEffect(() => {
    const fetchRoles = async () => {
      const allRoles = await getRoles(token);
      setAllRoles(allRoles.roles);
    };
    fetchRoles();
  }, [token]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredData.map((n) => ({
        id: n.userId,
      }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, status, firstName) => {
    const selectedIndex = selected.findIndex((item) => item.id === name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { id: name, status, firstName });
    } else if (selectedIndex === 0) {
      selected[0] = { ...selected[0], status, firstName };
      newSelected = selected.slice(1);
    } else {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleRemove = () => {
    setStatusModal(true);
  };

  const handleConfirmModal = (event) => {
    console.log('modal confirmed', event);
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setModalOpen(true);
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New User
          </Button>
        </Stack>
        <Card>
          <UserListToolbar
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleAction={(e, action) => {
              handleRemove(e, action);
            }}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              {isFetching ? (
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ my: 3, position: 'relative' }}>
                  <SkeletonTable rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                  <CircularProgress
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </Stack>
              ) : (
                <>
                  {filteredData && (
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={filteredData.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredData.map((row) => {
                          const { userId, firstName, email, displayName, blockStatus, createdAt, bgColor, role } = row;
                          let roleUser = role;
                          if (roleUser === '') {
                            roleUser = USER_DISPLAY_ROLE;
                          } else {
                            roleUser = role;
                          }
                          const date = new Date(createdAt);
                          const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
                            .getDate()
                            .toString()
                            .padStart(2, '0')}/${date.getFullYear()} ${date
                            .getHours()
                            .toString()
                            .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
                            .getSeconds()
                            .toString()
                            .padStart(2, '0')}`;

                          const isItemSelected = selected.findIndex((obj) => obj.id === userId) !== -1;
                          return (
                            <TableRow
                              hover
                              key={userId}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, userId, blockStatus, firstName)}
                                />
                              </TableCell>

                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                  component={RouterLink}
                                  to={`/dashboard/user-profile/${userId}`}
                                  sx={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
                                >
                                  <Avatar
                                    alt={firstName}
                                    sx={{ bgcolor: `${bgColor}` }}
                                    src={
                                      row.profileIconBase64
                                        ? row.profileIconBase64
                                        : `/static/images/avatar/${userId}.jpg`
                                    }
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {firstName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>{email}</TableCell>
                              <TableCell>{displayName}</TableCell>
                              <TableCell>
                                <Label variant="ghost" color={(blockStatus === true && 'error') || 'success'}>
                                  {blockStatus === true ? 'Blocked' : 'Active'}
                                </Label>
                              </TableCell>
                              <TableCell>{roleUser}</TableCell>
                              <TableCell>{formattedDate}</TableCell>

                              <TableCell align="right">
                                <UserMoreMenu
                                  handleAction={(e, action) => {
                                    handleRemove(e, action);
                                  }}
                                  user={row}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>

                      {isEmpty(filteredData) && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  )}
                </>
              )}
            </TableContainer>
          </Scrollbar>

          {!isEmpty(filteredData) && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalUsersCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
      <NewUserModal
        open={isModalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
        allRoles={allRoles}
      />
      <StatusModal
        open={statusModal}
        handleClose={() => {
          setStatusModal(false);
        }}
        confirmed={handleConfirmModal}
      />
    </Page>
  );
}
