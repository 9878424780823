import React, { useState } from 'react';
import styled from '@emotion/styled';
import DiamondBackground from './IntraverseHomeImages/Diamond_Checkered_Background.png';
import MobileFrameFingers from './IntraverseHomeImages/Mobile_Frame_Black_Bars_Transparent.png';
import splitLine from './IntraverseHomeImages/splitLine.png';

const ImmersiveExperiences = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleClickTab = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <ExperiencesContainer>
      <ExperiencesTextContainer>
        <ExperiencesText>Immersive Experiences</ExperiencesText>
        <FingersFrame>
          <MobileFrameFingersContainer>
            <VideoWithOverlay>
              <ImageOverlay />
              {activeTab === 1 && (
                <VideoContainer>
                  <video controls muted autoPlay loop>
                    <source
                      type="video/mp4"
                      src={'https://intraverse-web-client-assets.s3.amazonaws.com/Teddy+Swims.mp4'}
                    />
                  </video>
                </VideoContainer>
              )}
              {activeTab === 2 && (
                <VideoContainer>
                  <video controls muted autoPlay loop>
                    <source
                      type="video/mp4"
                      src={'https://intraverse-web-client-assets.s3.amazonaws.com/bebeverse+sizzlereel+v1.mp4'}
                    />
                  </video>
                </VideoContainer>
              )}
            </VideoWithOverlay>
          </MobileFrameFingersContainer>
        </FingersFrame>
        <TabContainer>
          <Tab onClick={() => handleClickTab(1)} active={activeTab === 1} activeTab>
            Teddy's Lounge
          </Tab>
          <LineImg src={splitLine} alt="split Line" />
          <Tab onClick={() => handleClickTab(2)} active={activeTab === 2} activeTab>
            Bebeverse
          </Tab>
        </TabContainer>
        <ExperiencesTextContainerInner>
          {activeTab === 1 ? (
            <ExperiencesBodyText>
              Join Teddy Swims and his band in Teddy’s Lounge, a two-story music loft reflecting Teddy’s artistry.{' '}
              <br />
              <br />
              Players streamed Teddy’s latest album through a <span> Spotify integration </span>and got the chance to
              win free concert tickets in an exclusive Easter Egg contest.
            </ExperiencesBodyText>
          ) : (
            <ExperiencesBodyText>
              Teleport back to the '60s in Bebe's Hot Box Mothership, a vintage-themed spaceship environment with
              Grammy-nominated singer and songwriter Bebe Rexha. <br />
              <br />
              This featured her release of "Satellite" with Snoop Dogg and proved so popular, a{' '}
              <span>second experience</span>, Virtual Roller Disco, was crafted.
            </ExperiencesBodyText>
          )}
        </ExperiencesTextContainerInner>
      </ExperiencesTextContainer>
    </ExperiencesContainer>
  );
};
export default ImmersiveExperiences;

const ExperiencesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(27, 27, 35, 1);
  background-image: url(${DiamondBackground});
  background-size: 350px auto;
  margin-top: -2px;
  margin-bottom: -2px;
`;

const MobileFrameFingersContainer = styled.div`
  position: relative;
  /* z-index: 1000; */
  margin: 1rem 0 3rem 0;
  justify-content: center;
  display: flex;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 90%;
  margin-top: 10px;
  margin-left: 23px;

  @media (max-width: 950px) {
    margin-left: 0rem;
  }

  @media (min-width: 950px) and (max-width: 1024px) {
    margin-left: 0rem;
  }

  /* @media (min-width: 1024px) and (max-width: 1335px) {
    margin-left: 0rem;
  } */
`;

const VideoWithOverlay = styled.div`
  position: relative;
  height: 443px;
  width: 1080px;
  justify-content: center;
  display: flex;

  @media (max-width: 768px) {
    height: 443px;
    width: 700px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 443px;
    width: 800px;
  }
  @media (min-width: 1024px) and (max-width: 1250px) {
    height: 443px;
    width: 1024px;
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(${MobileFrameFingers});
  background-repeat: no-repeat;
  /* background-size: 82%; */
  pointer-events: none;
  /* z-index: 1000; */

  @media (max-width: 950px) {
    display: none;
  }

  @media (min-width: 950px) and (max-width: 1024px) {
    width: 100vw;
    margin-left: -4rem;
  }

  @media (min-width: 1024px) and (max-width: 1110px) {
    width: 100vw;
    margin-left: -2rem;
  }
  @media (min-width: 1100px) and (max-width: 1216px) {
    width: 100vw;
    margin-left: 4rem;
  }
  @media (min-width: 1216px) and (max-width: 1335px) {
    margin-left: -5rem;
  }
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -1.5rem;
`;
const LineImg = styled.img`
  height: 55px;
  width: 5px;
  display: flex;
  align-content: center;
  margin-top: 1.5rem;

  @media (max-width: 320px) {
    margin-right: 1rem;
  }
  @media (max-width: 960px) {
    margin-top: 0.5rem;
  }
`;
const Tab = styled.div`
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${({ active }) => (active ? '#BEAEFF' : '#FBFCFF')};
  margin-right: 5rem;
  margin-left: 5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.5rem;
    margin-right: 3rem;
    margin-left: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const FingersFrame = styled.div`
  width: 100%;
  justify-content: center;
  /* z-index: 1000; */
`;
const ExperiencesTextContainer = styled.div`
  width: 80%;
  min-height: 160px;
  margin: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 960px) {
    width: 100%;
    min-height: none;
    margin: 1rem;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    margin: 2rem;
  }
`;
const ExperiencesTextContainerInner = styled.div`
  width: 75%;
  min-height: 160px;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 10px;
  background: #2b2a38;

  @media (max-width: 960px) {
    width: 95%;
    min-height: none;
    height: fit-content;
    margin: 1rem;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    margin: 2rem;
    width: 95%;
    min-height: none;
    height: fit-content;
  }
`;

const ExperiencesText = styled.h1`
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #5c567b;
  margin-bottom: 1rem;

  @media (max-width: 960px) {
    font-size: 2rem;
  }

  @media (min-width: 960px) and (max-width: 1024px) {
    font-size: 4rem;
  }
`;

const ExperiencesBodyText = styled.p`
  color: #ced1d9;
  text-align: left;
  font-family: 'Barlow', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 1px;
  height: 160px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  & > span {
    color: #ffe664;
    font-weight: 700;
  }

  @media (max-width: 960px) {
    font-size: 1rem;
    height: fit-content;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 1.2rem;
    height: fit-content;
  }
`;
