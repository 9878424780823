import UAParser from 'ua-parser-js';
import { DeleteUser } from '../../../redux/actions/UserActions';
import { DeleteMerchant } from '../../../redux/actions/MerchantActions';
import { API_POST, API_GET } from '../../../api/API_FETCH';
import { COREAPI_WEB_USERS_URL } from '../../../constants';
import axiosInstance from '../../../config/axiosConfig';
import { getFromLocalStorage } from '../../../hooks/useLocalStorage';

const COREAPIURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_USERS_URL}`;

async function getDeviceInfo() {
  const userAgentString = window.navigator.userAgent;
  const parser = new UAParser(userAgentString);
  const result = parser.getResult();
  return result;
}

export async function LogInWithEmail(email, password) {
  try {
    const data = {
      email,
      password,
    };
    const respData = await API_POST(`${COREAPIURL}/login`, data, '', 'POST');

    return respData;
  } catch (err) {
    return err;
  }
}

export async function RegisterUser(
  firstName,
  lastName,
  email,
  password,
  username
  // confirmPassword
) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
        firstName,
        lastName,
        displayName: username,
        confirmPassword: password,
        role: 'admin',
        createDefaultRoom: true,
      }),
    };
    console.log(requestOptions);
    const response = await fetch(`${COREAPIURL}`, requestOptions);

    if (response.ok) {
      const data = await response.json();
      // send deviceInfo to action log
      const deviceInfo = await getDeviceInfo();
      await sendInfoToActionLog('USER_DEVICE_INFO', deviceInfo, data.token);
      // send avatar selection to action log
      const selectedAvatarId = getFromLocalStorage('selectedAvatar');
      if (selectedAvatarId !== '' || selectedAvatarId !== undefined || selectedAvatarId !== null) {
        const avatarInfo = {
          avatarpresetid: selectedAvatarId,
        };
        await sendInfoToActionLog('avatar_preset_selection', avatarInfo, data.token);
      }
      console.log(data);
      return data;
    }

    return null;
  } catch (error) {
    console.log(error);
    console.error('Error during sign up:', error);
    return null;
  }
}

export async function UpdateUserProfileData(token, newFirstName, newLastName, country, username) {
  try {
    const data = {
      firstName: newFirstName,
      lastName: newLastName,
      displayName: username,
      userCountry: country,
    };

    return await API_POST(COREAPIURL, data, token, 'PUT');
  } catch (error) {
    console.log(error);
    console.error('Error during user data update:', error);
    return false;
  }
}

export async function UpdateUserBirthday(token, birthday, userId) {
  try {
    const userData = await getUserProfileData(token, userId);
    console.log(userData);
    if (userData.data.birthdayUpdateCount >= 3) {
      throw new Error('Exceeded the allowed number of updates for birthday');
    }
    const birthdayClean = new Date(birthday);
    // birthdayClean.setHours(0, 0, 0, 0);
    const newBirthdayCount = parseInt(userData.data.birthdayUpdateCount, 10) + 1;
    // console.log('newBirthdayCount', userData.data.birthdayUpdateCount);
    const data = {
      userBirthday: birthdayClean,
      birthdayUpdateCount: newBirthdayCount,
    };
    console.log(data);

    return await API_POST(COREAPIURL, data, token, 'PUT');
  } catch (error) {
    console.log(error);
    console.error('Error during user birthday update:', error);
    return false;
  }
}

// eslint-disable-next-line consistent-return
export async function getUserProfileData(token, userId) {
  try {
    const response = await API_GET(`${COREAPIURL}/${userId}`, token, 'GET');

    if (response.status === 200) {
      const userData = await response;
      // console.log(userData);
      return userData;
    }
  } catch (error) {
    console.error('Error during user profile data fetch:', error);
    return null;
  }
}

export async function UpdateUserEmail(token, newEmail) {
  try {
    const response = await fetch(`${COREAPIURL}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email: newEmail }),
    });

    if (response.ok) {
      const responseData = response.json();
      return {
        success: true,
        message: responseData,
      };
    }
    const errorData = await response.json();
    return {
      success: false,
      message: errorData,
    };
  } catch (error) {
    console.log(error);
    console.error('Error during user data update:', error);
    return false;
  }
}

export async function signUpUser(displayName, email) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ displayName, email }),
    };

    const response = await fetch(`${COREAPIURL}/login`, requestOptions);

    if (response.ok) {
      const data = await response.json();
      // send deviceInfo to action log
      const deviceInfo = await getDeviceInfo();
      await sendInfoToActionLog('USER_DEVICE_INFO', deviceInfo, data.token);
      // send avatar selection to action log
      const selectedAvatarId = getFromLocalStorage('selectedAvatar');
      if (selectedAvatarId !== '' || selectedAvatarId !== undefined || selectedAvatarId !== null) {
        const avatarInfo = {
          avatarpresetid: selectedAvatarId,
        };
        await sendInfoToActionLog('avatar_preset_selection', avatarInfo, data.token);
      }
      return data;
    }

    return null;
  } catch (error) {
    console.error('Error during singn up:', error);
    return null;
  }
}

export async function sendInfoToActionLog(actionType, freeform, userToken) {
  try {
    const response = await axiosInstance.post(
      `${COREAPIURL}/action-log`,
      {
        actionType,
        freeform,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error sending message:', error);
    return false;
  }
}

export async function LogInWithForgerock(code) {
  try {
    console.log('LogInWithForgerock code::', code);
    const response = await API_GET(`${COREAPIURL}/get-token/forgerock/${code}`, '');
    console.log('LogInWithForgerock resp:::', response);
    console.log(response.status);

    if (response.status === 400 || response.status === 500 || response.status === 401) {
      return response;
    }
    if (response.status === 200) {
      console.log('access token data: ', JSON.stringify(response));

      console.log('accessToken', response.data.access_token);
      const accessToken = response.data.access_token;
      console.log('accessToken set: ', accessToken);

      const userInfoResponse = await API_GET(`${COREAPIURL}/get-user/info/${accessToken}`, '');

      console.log('userInfoResponse>>>>', userInfoResponse);
      console.log(userInfoResponse.status, userInfoResponse.data);

      if (userInfoResponse.status === 400 || userInfoResponse.status === 500 || userInfoResponse.status === 401) {
        return userInfoResponse;
      }
      if (userInfoResponse.status === 200) {
        console.log('user info:', JSON.stringify(userInfoResponse));
        // if (respData.data.token) {
        //   const deviceInfo = await getDeviceInfo();
        //   await sendInfoToActionLog('USER_DEVICE_INFO', deviceInfo, respData.data.token);
        // }
        return userInfoResponse;
      }

      // const respData = {
      //       "data": {
      //         "sub": "cachu",
      //         "email": "cachu@hk.estee.com",
      //         "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0ZjU0NGNlYS1mMjMwLTQxMjUtYjgzOC1lY2M1ODFlYWEwYzMiLCJpYXQiOjE2OTY1NDUyNTgsImV4cCI6MTY5NjU0ODg1OCwicm9sZXMiOltdLCJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjUwMDEifQ.bgl2FOhwA1JWVI9WLGrw43NROP7rIjmdgAFHISTO3zk",
      //         "userId": "4f544cea-f230-4125-b838-ecc581eaa0c3"
      //     }
      //   };
    }
  } catch (err) {
    console.log('LogInWithForgerock Error::: ', err);
    return err;
  }
}

export async function Logout() {
  try {
    await axiosInstance.post(`${COREAPIURL}/logout`);
    DeleteUser();
    DeleteMerchant();
  } catch (err) {
    return 500;
  }
}

export async function ConfirmPasswordReset(token, newPassword) {
  try {
    const data = {
      password: newPassword,
    };
    const response = await API_POST(COREAPIURL, data, token, 'PUT');

    if (response.status === 200) {
      const responseData = response;
      return {
        success: true,
        message: responseData,
      };
    }
    const errorData = await response;
    return {
      success: false,
      message: errorData,
    };
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const currentPasswordChecker = async (currentPassword, token, userId) => {
  try {
    return await API_POST(
      `${COREAPIURL}/check/current-password/`,
      { password: currentPassword, userId },
      token,
      'POST'
    );
  } catch (err) {
    return false;
  }
};
