import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import IntraverseMiniLogoIcon from './userPortalImages/ColoredIntraverseLogo.png';
import MenuIcon from '../intraverseLandingPage/IntraverseHomeImages/HamburgerIconSVG.svg';
import IntraverseLogoFull from '../intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
import DiamondBackground from '../intraverseLandingPage/IntraverseHomeImages/Diamond_Checkered_Background.png';
import CloseMenu from '../intraverseLandingPage/IntraverseHomeImages/CloseMenu.png';

const UserPortalHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate();

  const currentPath = window.location.pathname

  const routeChange = (path) => {
    navigate(path);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    const aspectRatio = innerWidth / innerHeight;
    return { innerWidth, innerHeight, aspectRatio };
  }

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    if (currentPath === "/wallet") {
      setActiveTab(1);
    } else if (currentPath === "/orders") {
      setActiveTab(2);
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PortalHeaderContainer window={windowSize.innerWidth}>
      {windowSize.innerWidth > 769 && (
        <>
          <PortalHeaderMenu isOpen={isOpen}>
            <PortalHeaderLogo onClick={() => routeChange('/')} src={IntraverseMiniLogoIcon} alt="Intraverse Logo" />
            <PortalHeaderMenuItems>
              <PortalHeaderMenuItem active={activeTab === 0} onClick={() => {
                routeChange('/user-console')
                }}>
                Profile
              </PortalHeaderMenuItem>
              <PortalHeaderMenuItem active={activeTab === 1} onClick={() => {
                // handleTabClick(1)
                routeChange('/wallet')
              }}
              >
                Wallet
              </PortalHeaderMenuItem>
              <PortalHeaderMenuItem active={activeTab === 2} onClick={() => {
                // handleTabClick(2)
                routeChange('/orders')
                }}>
                Orders
              </PortalHeaderMenuItem>
            </PortalHeaderMenuItems>
          </PortalHeaderMenu>
          <PortalHeaderMenuButtons>
            <PlayButton onClick={() => routeChange('/')}>Play Now</PlayButton>
          </PortalHeaderMenuButtons>
        </>
      )}
      {windowSize.innerWidth < 769 && (
        <PortalMobileHeader>
          {!isOpen && (
            <PortalHeaderMobileInnerLogo
              onClick={() => routeChange('/')}
              src={IntraverseMiniLogoIcon}
              alt="Intraverse Logo"
            />
          )}
          {isOpen ? (
            <CloseButton onClick={toggleMenu} src={CloseMenu} alt="Close Menu" />
          ) : (
            <MenuIconContainer onClick={toggleMenu} src={MenuIcon} alt="Menu Icon" />
          )}
        </PortalMobileHeader>
      )}
      {isOpen && (
        <PortalMobileMenu>
          <PortalMobileLogo src={IntraverseLogoFull} alt="Intraverse Full Logo" />
          <PortalMobileMenuItems>
            <PortalMobileMenuItem onClick={() => routeChange('/user-console')}>Profile</PortalMobileMenuItem>
            <PortalMobileMenuItem onClick={() => routeChange('/wallet')}>Wallet</PortalMobileMenuItem>
            <PortalMobileMenuItem onClick={() => routeChange('/orders')}>Orders</PortalMobileMenuItem>
          </PortalMobileMenuItems>
          <PortalMobileMenuButtons>
            <MobilePlayButton>Play For Free</MobilePlayButton>
          </PortalMobileMenuButtons>
        </PortalMobileMenu>
      )}
    </PortalHeaderContainer>
  );
};

export default UserPortalHeader;

const PortalHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(prop) => (prop.window > 769 ? '#000' : 'transparent')};
  z-index: 1000;
  padding: 0px 40px;

  @media (max-width: 768px) {
    padding: 15px 25px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 15px 25px;
  }
`;

const PortalHeaderLogo = styled.img`
  max-width: 100px;
  margin-right: 3rem;
  margin-left: 1rem;

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 80px;
    margin-right: 2rem;
    margin-left: 0rem;
  }
`;
const PortalHeaderMenu = styled.div`
  display: flex;
  align-items: center;
`;

const PortalHeaderMenuItems = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
`;

const PortalHeaderMenuItem = styled.div`
  color: #fbfcff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  justify-items: center;
  margin-right: 35px;
  cursor: pointer;
  padding-bottom: 8px;
  margin-bottom: -18px;
  border-bottom: ${(props) => (props.active ? '6px solid #BEAEFF' : 'none')};

  &:hover {
    color: #beaeff;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    letter-spacing: 1px;
    margin-right: 25px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1rem;
    letter-spacing: 1px;
    margin-right: 25px;
  }
`;

const PortalHeaderMenuButtons = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 10px;
`;

const PlayButton = styled.button`
  border-radius: 5px;
  background: rgba(190, 174, 255, 0.2);
  width: auto;
  height: fit-content;
  padding: 10px 30px;
  border: none;
  cursor: pointer;

  color: #beaeff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding: 15px 15px;
    font-size: 18px;
    letter-spacing: 1px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 15px 15px;
    font-size: 16px;
    letter-spacing: 1px;
  }

  &:hover {
    background: #beaeff;
    color: black;
  }
`;

// MOBILE MENU

const MenuIconContainer = styled.img`
  width: 50px;
  height: 50px;
  z-index: 1000;
  position: fixed;
  top: 1rem;
  right: 1.5rem;
  display: flex;
  @media (max-width: 768px) {
    height: 35px;
    width: 35px;
    top: 0.6rem;
    right: 0.5rem;
  }
`;
const PortalHeaderMobileInnerLogo = styled.img`
  width: 50px;
  height: 50px;
  z-index: 1000;

  position: fixed;
  top: 2rem;
  left: 1.5rem;
  display: flex;

  @media (max-width: 768px) {
    height: 30px;
    width: 40px;
    top: 0.6rem;
    left: 0.5rem;
  }

  /* @media (min-width: 768px) and (max-width: 1024px) {
    height: 45px;
    width: 60px;
  } */
`;

const PortalMobileMenu = styled.div`
  display: flex;
  position: absolute;
  top: -1.1rem;
  left: 1.5%;
  align-items: center;
  flex-direction: column;
  width: 97vw;
  height: fit-content;
  padding-bottom: 1.5rem;
  background: rgba(27, 27, 35, 1);
  background-image: url(${DiamondBackground});
  background-size: 350px auto;
`;

const PortalMobileHeader = styled.div`
  display: flex;
  align-items: center;
`;
const CloseButton = styled.img`
  width: 60px;
  height: 60px;
  z-index: 1000;

  position: fixed;
  top: 3.7%;
  right: 2rem;
  display: flex;

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;
const PortalMobileLogo = styled.img`
  position: relative;
  top: -4.5rem;
  left: -7rem;
  width: 50vw;
  margin-top: 6rem;

  @media (max-width: 600px) {
    top: -3.7rem;
    left: -3rem;
    width: 50vw;
  }
`;

const PortalMobileMenuItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  flex-direction: column;
  width: 80%;
`;
const PortalMobileMenuItem = styled.div`
  color: #fbfcff;
  text-align: left;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 2.7rem;
`;

const PortalMobileMenuButtons = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
`;

const MobilePlayButton = styled.button`
  border-radius: 5px;
  background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
  padding: 20px 35px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;

  color: #fbfcff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
