import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as PlusSign } from './icons/plusSign.svg';
import { ReactComponent as SadFace } from './icons/sad_face.svg';
import { ReactComponent as Stripe } from './icons/stripe.svg';
import { ReactComponent as Terrazero } from './icons/terrazero.svg';

const AddCartPopUp = ({ fetchClientSecret}) => {
  return (
    <Card>
      <PurpleText>No payment method</PurpleText>
      <Subtitle>Oops! It looks that you do not have an active credit card or payment method linked to your account.</Subtitle>
        <PinkButton
          onClick={() => {
            fetchClientSecret()
          }}
        >
          <p>
          Add a card
          </p>
        </PinkButton>
      <LogoHolder>
       <Terrazero/>
       <PlusSign/>
       <Stripe/>
      </LogoHolder>
    </Card>
  );
};

const Card = styled.div`
  width: 600px;
  padding-top: 105px;
  height: 590px;
  border-radius: 20px;
  background: var(--bg-primary-100, #1b1b23);
  margin: auto;
  margin-top: 4rem;
  display: flex;;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 90vw;
    padding-top: 50px;
    height: 450px;
  }
`;

const LogoHolder = styled.div`
  margin-top: 23px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  /* background-color: yellow; */
`;

const PurpleText = styled.p`
  color: var(--purple-purple, #beaeff);
  margin-top: 30px;
  text-align: center;
  font-family: Barlow Condensed;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

const Subtitle = styled.p`
  color: var(--text-light-grey, #ced1d9);
  text-align: center;
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 30px;
`;

const PinkButton = styled.button`
  display: inline-flex;
  padding: 15px 45px;
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  max-width: 300px;
  background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
  cursor: pointer;
  margin: 1rem 0rem 2rem 0rem;

  & p {
    color: #fbfcff;
    font-family: 'Barlow', sans-serif;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  @media (max-width: 768px) {
     width: 90%;
  }
`;

export default AddCartPopUp;
