import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './VagonStream.css';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import CornerIconButton from './components/intraverse/ui/CornerIconButton';
import { isMobileDevice, exitFullScreen, enterFullScreen } from './utils/utils';
import { getFromLocalStorage } from './hooks/useLocalStorage';
import { fetchVanityLinkInfo } from './redux/slices/vanityLinks';
import BroadCast from './feature/broadcasting/BroadCast';
import AmbientSound from './feature/ambientSound/AmbientSound';
import SpotifyInterface from './components/SpotifyControls';
import * as spotifyConstants from './constants';
import { resumePlayer } from './feature/spotify/api/SpotifyController';

export const sendVagonMessage = (message) => {
  window.Vagon.sendApplicationMessage(message);
};

const VagonStream = (streamUrl) => {
  const vanityLinkInfo = useSelector(fetchVanityLinkInfo);
  const dispatch = useDispatch(); // Get the dispatch function
  const isMobile = isMobileDevice();
  const elementRef = useRef(null);
  const [showCornerButtons, setShowCornerButtons] = useState(true);
  const [landscape, setLandscape] = useState(false);
  const [fullScreen, setFullScreen] = useState(
    !vanityLinkInfo?.spotifyLogin ? getFromLocalStorage('fullscreen') : false
  );
  const [openBroadcastModal, setOpenBroadcastModal] = useState(false);
  const intervalIdRef = useRef(null);
  const [participant, setParticipant] = useState('');
  const [pauseMusic, setPauseMusic] = useState(false);
  const [broadCaster, setBroadCaster] = useState(false);
  const [playingMusic, setPlayingMusic] = useState(false);
  const [teddyNoteOpen, setTeddyNoteOpen] = useState(false);
  const [openTeddySelfiePopup, setOpenTeddySelfiePopup] = useState(false);
  const [openContestModalState, setOpenContestModalState] = useState(false);

  const handleButtonClick = () => {
    const escapeKeyEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
    });

    document.dispatchEvent(escapeKeyEvent);
  };

  const openBroadcastModalTrigger = () => {
    handleButtonClick();
    setOpenBroadcastModal((prev) => !prev);
    handleButtonClick();
  };

  const buttonsVisible = () => {
    setShowCornerButtons((prev) => !prev);
  };

  const startInterval = () => {
    if (!intervalIdRef.current) {
      intervalIdRef.current = setInterval(() => {
        window.Vagon?.focusIframe();
      }, 1000);
    }
  };

  const stopInterval = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const playMusic = () => {
    setPlayingMusic((prev) => !prev);
  };

  const openTeddyNoteModal = () => {
    handleButtonClick();
    setTeddyNoteOpen((prev) => !prev);
    handleButtonClick();
  };
  const openContestModal = () => {
    handleButtonClick();
    setOpenContestModalState((prev) => !prev);
    handleButtonClick();
  };

  const openTeddySelfiePopupModal = () => {
    handleButtonClick();
    setOpenTeddySelfiePopup((prev) => !prev);
    handleButtonClick();
  };

  const loadedScript = () => {
    let vagonPlayingMusic = false;
    startInterval();

    // Prints out the message sent from the application
    window.Vagon.onApplicationMessage((evt) => {
      const message = evt.message.toString();
      console.log(`EVENT MESSAGE: ${message}`);

      if (Object.prototype.hasOwnProperty.call(spotifyConstants.spotifyTracks, message)) {
        if (vagonPlayingMusic === false) {
          const type = spotifyConstants.ALBUM;

          resumePlayer(getFromLocalStorage('spotifyAccessToken'), spotifyConstants.spotifyTracks[message], type).then(
            () => {
              vagonPlayingMusic = true;
              playMusic();
            }
          );
        }
        return;
      }
      if (message === 'OPEN_BROADCAST_MODAL') {
        openBroadcastModalTrigger();
      }
      if (message === 'PDF_OPEN' || message === 'PDF_CLOSE') {
        buttonsVisible();
      }
      if (message === spotifyConstants.OPEN_TEDDY_CONTEST) {
        openContestModal();
      }
      if (message === spotifyConstants.OPEN_TEDDY_NOTE) {
        openTeddyNoteModal();
      }
      if (message === spotifyConstants.OPEN_TEDDY_SELFIE) {
        openTeddySelfiePopupModal();
      }
      if (message.includes('SETUPCARD')) {
        window.open("localhost:3000/wallet/source=web_unity");
      }
    });
  };

  useEffect(() => {
    if (fullScreen === true || fullScreen === 'true') {
      enterFullScreen(elementRef);
    } else if (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullScreenElement) &&
      !fullScreen
    ) {
      exitFullScreen();
    }

    updateOrientation();
  }, [fullScreen]);

  const updateOrientation = () => {
    // first check to ensure it is mobile user
    if (!isMobile) return;
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    const aspectRatio = width / height;
    const iframeElement = document.getElementById('vagonFrame');

    // second check to ensure aspect Ratio is below 1
    if (aspectRatio < 1) {
      iframeElement.classList.add('force-landscape');
      iframeElement.style.removeProperty('width');
      iframeElement.style.removeProperty('height');
      setLandscape(true);
    } else {
      iframeElement.classList.remove('force-landscape');
      iframeElement.style.width = '100vw';
      iframeElement.style.height = '100vh';
      setLandscape(false);
    }
  };

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://app.vagon.io/vagonsdk.js');
    script.addEventListener('load', () => {
      loadedScript();
    });
    head.appendChild(script);

    window.addEventListener('resize', updateOrientation);

    return () => window.removeEventListener('resize', updateOrientation);
  }, []);

  useEffect(() => {
    if (openBroadcastModal || teddyNoteOpen || openContestModalState || openTeddySelfiePopup) {
      stopInterval();
    } else {
      startInterval();
    }
  }, [openBroadcastModal, teddyNoteOpen, openContestModalState, openTeddySelfiePopup]);

  return (
    <div
      ref={elementRef}
      className="iframe-container"
      style={{ width: '100vw', height: '100vh', background: 'black', zIndex: 100 }}
    >
      {vanityLinkInfo.spotifyLogin && (
        <SpotifyInterface
          setFullScreen={setFullScreen}
          landscape={landscape}
          teddyNoteOpen={teddyNoteOpen}
          setTeddyNoteOpen={setTeddyNoteOpen}
          openTeddySelfiePopup={openTeddySelfiePopup}
          setOpenTeddySelfiePopup={setOpenTeddySelfiePopup}
          playingMusic={playingMusic}
          openContestModalState={openContestModalState}
          setOpenContestModalState={setOpenContestModalState}
          updateOrientation={updateOrientation}
        />
      )}
      {vanityLinkInfo.music && (
        <AmbientSound
          audio={'AmbientSong'}
          play={!(participant || broadCaster || pauseMusic)}
          source={vanityLinkInfo.music}
        />
      )}

      {vanityLinkInfo.broadcast && (
        <BroadCast
          openBroadcastModal={openBroadcastModal}
          setOpenBroadcastModal={setOpenBroadcastModal}
          participant={participant}
          setParticipant={setParticipant}
          broadCaster={broadCaster}
          setBroadCaster={setBroadCaster}
          roomName={vanityLinkInfo.clientName}
        />
      )}

      <iframe
        id="vagonFrame"
        title="Vagon Stream Content"
        allow="microphone *; clipboard-read *; clipboard-write *; encrypted-media *;"
        style={{ width: '100vw', height: '100vh', border: 0, zIndex: 100 }}
        src={streamUrl.streamUrl}
        sandbox="allow-pointer-lock allow-scripts allow-forms allow-same-origin allow-popups"
      />

      {showCornerButtons && !openBroadcastModal && (
        <FullScreenIconImg landscape={landscape}>
          <CornerIconButton
            showActiveIcon={fullScreen}
            iconHeight={40}
            iconColor="white"
            activeIcon="iconamoon:screen-normal"
            unactiveIcon="iconamoon:screen-full-bold"
            func={() => {
              setFullScreen((value) => !value);
            }}
          />
          {!broadCaster && !participant && vanityLinkInfo.music && (
            <CornerIconButton
              iconHeight={40}
              iconColor="white"
              activeIcon="clarity:volume-up-solid"
              unactiveIcon="clarity:volume-mute-solid"
              func={() => {
                setPauseMusic((value) => !value);
              }}
            />
          )}
        </FullScreenIconImg>
      )}
    </div>
  );
};

VagonStream.propTypes = {
  streamUrl: PropTypes.string.isRequired,
};

export default VagonStream;

const FullScreenIconImg = styled.div`
  height: auto;
  display: flex;
  flex-direction: ${(props) => (props.landscape ? 'row' : 'column')};
  position: fixed;
  z-index: 1000;
  transform: ${(props) => (props.landscape ? 'rotate(-180deg)' : 'none')};
  ${(props) => (props.landscape ? 'top: 0; left: 0;' : 'top: 0; right: 0;')}
`;
