import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import './CustomizedButton.css';

export function PrimaryButton({ error, success, showMessage, ...props }) {
  const messageStyle = ['message', error ? 'warning' : ' ', success ? 'success' : ' '].join(' ');

  return (
    <div>
      {showMessage && (
        <div className={messageStyle}>
          <p>{showMessage}</p>
        </div>
      )}
      <LoadingButton
        size="large"
        type="submit"
        variant="contained"
        fullWidth={props.fullWidth}
        loading={props.loading}
        sx={{ boxShadow: 12, mr: 4, padding: '0 40px' }}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </LoadingButton>
    </div>
  );
}

export function CancelButton({ onClick }) {
  return (
    <>
      <Button
        size="large"
        variant="contained"
        sx={{ boxShadow: 12, mr: 4, padding: '0 40px' }}
        color="cancel"
        onClick={onClick}
      >
        Cancel{' '}
      </Button>
    </>
  );
}
CancelButton.propTypes = {
  onClick: PropTypes.func,
};

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * What loading state to use
   */
  loading: PropTypes.any,
  /**
     
     * Optional click handler
     */
  onClick: PropTypes.func,
  /**
   * Show message if trigger was successful or error
   */
  showMessage: PropTypes.string,
  /**
   * Shows red text
   */
  error: PropTypes.bool,
  /**
   * Shows green text
   */
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

PrimaryButton.defaultProps = {
  backgroundColor: null,
  onClick: undefined,
};
