import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import SpotifyLogoIcon2 from '../../assets/images/spotify/spotifyLogo2.png';
import EmailIcon from '../../assets/images/email.svg';
import EmailButtonIcon from '../../assets/images/Icon-CTA.png';
import EmailButtonIconGrey from '../../assets/images/Icon-CTAgrey.png';
import { isValidEmail } from '../../utils/isValidEmail';
import { setToLocalStorage, getFromLocalStorage } from '../../hooks/useLocalStorage';
import { CLIENT_ID, REDIRECT_URI, SCOPE, RESP_TYPE } from '../../constants';
import { handleSpotifyAuth } from '../../feature/spotify/api/SpotifyController';

const EmailForm = ({ setEmail, vanityLinkData, spotifyError, forgeRockError, setForgeRockError, setSpotifyError }) => {
  const [emailForm, setEmailForm] = useState(getFromLocalStorage('email') || '');
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');

  const { otherLoginImage, freeformJson, emailLogin, ssoMetadataJson, sso, urlSuffix, clientName, spotifyLogin } =
    vanityLinkData;

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById('submit-button').click();
    }
  };

  const handleEmailSave = async () => {
    setForgeRockError('');
    setSpotifyError('');
    if (!validateEmail()) return;
    setToLocalStorage('email', emailForm);
    setEmail(emailForm);
    // setSpotifyReminder(true);
  };

  const validateEmail = () => {
    if (!emailForm || !emailForm.trim()) {
      setEmailError('Email is required');
      return false;
    }

    if (!isValidEmail(emailForm)) {
      setEmailError('Invalid email address');
      return false;
    }
    setEmailValid(true);
    setEmailError('');

    return true;
  };

  useEffect(() => {
    if (isValidEmail(emailForm)) {
      setEmailValid(true);
      setEmailError('');
    } else {
      setEmailValid(false);
    }
  }, [emailForm]);

  const handleOtherLoginAuth = async () => {
    const prefixUrl = ssoMetadataJson.authPrefix ? ssoMetadataJson.authPrefix : process.env.REACT_APP_CORE_API_URI;
    if (sso) {
      try {
        const params = new URLSearchParams({
          client_id: CLIENT_ID,
          redirect_uri: REDIRECT_URI,
          scope: SCOPE,
          response_type: RESP_TYPE,
        });
        window.location.href = `${prefixUrl}${ssoMetadataJson.authUrl}?${params.toString()}`;
      } catch (error) {
        // window.location.href = `${process.env.PUBLIC_URL}/clientName`;
        setForgeRockError('Error during SSO authentication');
        console.error('Error during SSO authentication:', error);
      }
    }
  };

  return (
    <>
      <TitleContainer>
        <PageTitle> {freeformJson ? freeformJson.emailTitle : ''}</PageTitle>
        <PageSubtitle>{freeformJson ? freeformJson.emailSubtitle : ''}</PageSubtitle>
      </TitleContainer>
      {emailLogin && (
        <>
          {clientName === 'estee-lauder' && password !== 'dWba5t:cS=;wTK%^&2s8' ? (
            <PassContainer>
              <PageTitle style={{ textAlign: 'center' }}> Please enter a password:</PageTitle>
              <EmailSectionVertical style={{ width: '100%', height: '40px', marginTop: '2rem' }}>
                <EmailSection>
                  <Input
                    style={{ fontWeight: 700, fontSize: '30px', paddingLeft: '10px' }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </EmailSection>
              </EmailSectionVertical>
            </PassContainer>
          ) : (
            <>
              <EmailSectionVertical>
                <EmailSection>
                  <EmailIconContainer>
                    <Icon src={EmailIcon} alt="Email Icon" />
                    <EmailText>EMAIL</EmailText>
                  </EmailIconContainer>
                  <Input
                    type="email"
                    placeholder="What is your email address?"
                    value={emailForm}
                    onChange={(e) => setEmailForm(e.target.value)}
                    onKeyDown={handleInputKeyDown}
                  />
                  {!emailValid && <WarningText>{emailError}</WarningText>}
                </EmailSection>
                <EmailButton
                  src={emailValid ? EmailButtonIcon : EmailButtonIconGrey}
                  alt="Email Button"
                  id="submit-button"
                  onClick={() => handleEmailSave()}
                />
              </EmailSectionVertical>
            </>
          )}
        </>
      )}
      {(sso && emailLogin) ||
        (emailLogin && spotifyLogin && (
          <Line>
            <OrText>OR</OrText>
          </Line>
        ))}
      {sso && (
        <SpotifyContainer onClick={() => handleOtherLoginAuth()}>
          <SpotifyInner>
            <SpotifyTitle>{freeformJson ? freeformJson?.signupTitle : ''}</SpotifyTitle>
            <SpotifySubtitle>
              {/* <span
              style={{
                fontStyle: 'italic',
                color: '#FFE664',
              }}
            >
            </span>{' '} */}
              {freeformJson ? freeformJson.signupSubtitle : ''}
              {/* is required to unlock the full experience */}
              <WarningText> {forgeRockError}</WarningText>
            </SpotifySubtitle>
          </SpotifyInner>
          <SpotifyLogo
            style={{
              height: urlSuffix === 'estee-lauder' ? '170px' : '100px',
              width: urlSuffix === 'estee-lauder' ? '170px' : '100px',
            }}
            src={freeformJson ? otherLoginImage : null}
            alt="Box Logo"
          />
        </SpotifyContainer>
      )}
      {spotifyLogin && (
        <SpotifyContainer onClick={() => handleSpotifyAuth(process.env.REACT_APP_WEB_URL_TEDDY)}>
          <SpotifyInner>
            <SpotifyTitle>{freeformJson ? freeformJson?.spotifySignupTitle : ''}</SpotifyTitle>
            <SpotifySubtitle>
              {freeformJson ? freeformJson.spotifySignupSubtitle : ''}
              <WarningText> {spotifyError}</WarningText>
            </SpotifySubtitle>
          </SpotifyInner>
          <SpotifyLogo
            style={{
              height: '100px',
              width: '100px',
            }}
            src={SpotifyLogoIcon2}
            alt="Box Logo"
          />
        </SpotifyContainer>
      )}
    </>
  );
};

export default EmailForm;

EmailForm.propTypes = {
  vanityLinkData: PropTypes.object,
  setEmail: PropTypes.func,
  setSpotifyError: PropTypes.func,
  setForgeRockError: PropTypes.func,
  forgeRockError: PropTypes.string,
  spotifyError: PropTypes.string,
};

const PassContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;

  @media (max-width: 768px) {
    padding-top: 2rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-top: 3rem;
  }
`;

const SpotifyContainer = styled.div`
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: fit-content;
  z-index: 5;
  background: rgba(27, 27, 35, 0.9);
  display: flex;
  align-items: center;
  border: 4px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 2rem;

  @media (max-width: 960px) {
    width: 80%;
    padding: 15px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    width: 80%;
    padding: 5px;
  }
`;
const SpotifyInner = styled.div`
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 960px) {
    padding: 5px;
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1440px) {
    width: 90%;
  }
`;

const WarningText = styled.p`
  font-size: 1rem;
  align-self: center;
  color: #beaeff;
  font-family: 'Barlow', sans-serif;
  font-style: italic;
  padding: 0 2rem;

  @media (max-width: 960px) {
    font-size: 0.5rem;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 50%;
  margin: 20px 0;
  z-index: 5;

  @media (min-width: 960px) and (max-width: 1440px) {
    margin: 0;
    width: 80%;
  }
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

  &:not(:empty)::before {
    margin-right: 0.5em;
  }

  &:not(:empty)::after {
    margin-left: 0.5em;
  }

  @media (max-width: 960px) {
    width: 80%;
  }
`;
const OrText = styled.h1`
  font-size: 1.5rem;
  margin: 15px;
  color: white;
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-weight: 600;
  padding: 1rem;

  @media (max-width: 960px) {
    font-size: 1rem;
    padding: 0rem;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 1.3rem;
  }
`;
const SpotifyTitle = styled.h1`
  font-size: 1.5rem;
  margin-bottom: -5px;
  color: white;
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-weight: 600;
  padding-left: 2rem;

  @media (max-width: 960px) {
    font-size: 1.1rem;
    padding-bottom: 10px;
    padding-left: 0rem;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 1.3rem;
  }
`;
const SpotifySubtitle = styled.div`
  font-size: 1rem;
  padding-left: 2rem;
  margin-top: 15px;
  margin-bottom: 20px;
  color: white;
  font-family: 'Barlow', sans-serif;

  @media (max-width: 960px) {
    font-size: 0.8rem;
    padding-left: 0rem;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 1.1rem;
  }
`;

const SpotifyLogo = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 4rem;

  @media (max-width: 960px) {
    width: 50px;
    height: 50px;
    margin-right: 0rem;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    width: 70px;
    height: 70px;
  }
`;

const EmailSection = styled.div`
  background: rgba(27, 27, 35, 0.9);
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
  border: 4px solid rgba(255, 255, 255, 0.2);
  padding: 5px;
  width: 50%;
  height: 65px;
  flex-shrink: 0;
  flex: 1;

  @media (max-width: 960px) {
    height: 55px;
    width: 100%;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    height: 55px;
    width: 100%;
  }
`;
const EmailButton = styled.img`
  cursor: pointer;
  height: 60px;
  padding-left: 1rem;

  @media (max-width: 960px) {
    /* height: 50px; */
    width: 50px;
    padding-left: 0rem;
    padding-top: 0.7rem;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    padding-left: 0.5rem;
    height: 65px;
    /* width: 65px; */
  }
`;

const EmailSectionVertical = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  z-index: 5;
  align-content: center;

  @media (max-width: 960px) {
    height: 100px;
    width: 85%;
    padding-left: 0;
    flex-direction: column;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    width: 80%;
  }
`;

const EmailIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  height: 65px;

  @media (max-width: 960px) {
    height: 70px;
    margin: 10px;
    padding: 10px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    height: 70px;
    width: fit-content;
    margin: 10px;
    padding: 10px;
  }
`;

const EmailText = styled.span`
  font-size: 0.7rem;
  margin-top: 5px;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  color: white;
  @media (max-width: 960px) {
    font-size: 10px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 12px;
    width: 90%;
  }
`;

const Input = styled.input`
  flex: 1;
  background: none;
  border: none;
  outline: none;
  height: 65px;
  /* width: 64px; */
  padding-left: 1rem;
  font-size: 18px;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  @media (max-width: 960px) {
    font-size: 12px;
    padding-left: 5px;
    height: 55px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 14px;
    padding-left: 5px;
    height: 60px;
  }

  ::placeholder {
    color: #9da0a7;
    opacity: 50%;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;

  @media (max-width: 960px) {
    height: 15px;
    width: 15px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    height: 20px;
    width: 20px;
  }

  @media (min-width: 1440px) and (max-width: 2000px) {
    height: 25px;
    width: 25px;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  z-index: 5;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  margin-top: 6rem;

  @media (max-width: 960px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const PageTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 5px;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  color: #ced1d9;
  @media (max-width: 960px) {
    font-size: 22px;
    padding: 1rem;
    margin-top: 6rem;
  }

  @media (min-height: 0px) and (max-height: 360px) {
    margin-top: 12rem;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 1.6rem;
  }
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-weight: 500;
  color: #ced1d9;
  @media (max-width: 960px) {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 1rem;
  }
`;
