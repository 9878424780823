const defaultState = {
  products: null,
};

const ProductReducer = (state, action) => {
  switch (action.type) {
    case 'PRODUCT_SAVE': {
      return {
        ...state,
        products: action.payload.products,
      };
    }
    case 'PRODUCT_DELETE': {
      return defaultState;
    }

    default:
      if (state === undefined) {
        return defaultState;
      }
      return state;
  }
};

export default ProductReducer;
