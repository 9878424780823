import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Iconify from '../../Iconify';

const CornerIconButton = (props) => {
  const [activeIcon, setActiveIcon] = useState(props.showActiveIcon);

  useEffect(() => {
    setActiveIcon(props.showActiveIcon);
  }, [props.showActiveIcon]);

  return (
    <CornerIcon
      verticalPlacement={props.verticalPlacement}
      horizontalPlacement={props.horizontalPlacement}
      onClick={() => {
        setActiveIcon((value) => !value);
        props.func();
      }}
    >
      <Iconify
        icon={activeIcon ? props.activeIcon : props.unactiveIcon}
        sx={{
          width: 'auto',
          height: props.iconHeight,
          color: props.iconColor,
          '@media (max-width: 960px)': {
            height: '70%',
          },
        }}
      />
    </CornerIcon>
  );
};

export default CornerIconButton;

CornerIconButton.defaultProps = {
  horizontalPlacement: 'LEFT',
  verticalPlacement: 'TOP',
  iconHeight: 50,
  iconColor: 'white',
  showActiveIcon: true,
};

CornerIconButton.propTypes = {
  iconColor: PropTypes.string,
  iconHeight: PropTypes.number,
  unactiveIcon: PropTypes.string.isRequired,
  activeIcon: PropTypes.string.isRequired,
  func: PropTypes.any,
  showActiveIcon: PropTypes.bool,
  verticalPlacement: PropTypes.oneOf(['BOTTOM', 'TOP']),
  horizontalPlacement: PropTypes.oneOf(['RIGHT', 'LEFT']),
};

const CornerIcon = styled.div`
  cursor: pointer;
  // height: 60px;
  padding: 15px 15px 10px 15px;
  // position: fixed;
  z-index: 3000;
  background: linear-gradient(180deg, rgba(27, 27, 35, 0.75) 0%, rgba(27, 27, 35, 0) 100%);
  ${(props) => (props.verticalPlacement === 'BOTTOM' ? 'bottom: 0;' : 'top: 0;')}
  ${(props) => (props.horizontalPlacement === 'LEFT' ? 'left: 0;' : 'right:0;')}

  @media (max-width: 960px) {
    height: 60px;
    padding: 10px 10px 5px 10px;
  }

  @media (max-width: 400px) {
    transform: rotate(-90deg);
    top: initial;
    right: initial;
    bottom: -5px;
    left: 0;
  }
`;
