import * as Yup from 'yup';
import { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { LogInWithEmail } from '../api/Controller';
import { SUPERADMIN_ROLE } from '../../../constants';
import { SaveMerchant } from '../../../redux/actions/MerchantActions';
import { SaveUser } from '../../../redux/actions/UserActions';

// eslint-disable-next-line react/prop-types
export default function LoginForm({ setMessage }) {
  const dispatch = useDispatch(); // Get the dispatch function
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const email = useRef();
  const password = useRef();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    const enteredEmail = email.current.value;
    const enteredPassword = password.current.value;
    const res = await LogInWithEmail(enteredEmail, enteredPassword);
    if (res.status === 200) {
      const role = res.userRoles[0];
      if (role === SUPERADMIN_ROLE || res.isMerchant) {
        setMessage('Login Successful. Admin');
        if (res.isMerchant) {
          const { merchantName, merchantId, street, city, country, postalCode, merchantBusinessType, stripeClientId } =
            res.merchant;
          const address = { street, city, country, postalCode };

          dispatch(SaveMerchant(merchantName, merchantId, address, merchantBusinessType, stripeClientId));
        }

        if (res.userRoles[0] === SUPERADMIN_ROLE || res.isMerchant) {
          dispatch(
            SaveUser(
              res.user.email,
              res.user.displayName,
              res.token,
              res.userId,
              res.userRoles[0],
              res.isMerchant,
              res.profileIconBase64
            )
          );
        }
      } else {
        dispatch(
          SaveUser(
            res.user.email,
            res.user.displayName,
            res.token,
            res.userId,
            '',
            res.isMerchant,
            res.profileIconBase64
          )
        );
      }
    } else {
      setMessage('Failed to login, please ensure your information is correct');
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          // InputLabelProps={{ shrink: true, variant: 'filled' }}
          name="email"
          label="Email address"
          inputRef={email}
          sx={{
            background: 'none',
            borderRadius: '5px',
          }}
          InputProps={{
            sx: {
              backgroundColor: '#1B1B23',
              borderRadius: '5px',
              '& input': {
                color: 'white',
              },
            },
          }}
        />

        <RHFTextField
          inputRef={password}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          // InputLabelProps={{ shrink: true, variant: 'filled' }}
          sx={{
            background: 'none',
            borderRadius: '5px',
          }}
          InputProps={{
            sx: {
              backgroundColor: '#1B1B23',
              borderRadius: '5px',
              '& input': {
                color: 'white',
              },
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify color="#BEAEFF" icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
        <RHFCheckbox
          sx={{ color: 'white', borderRadius: '5px', display: 'flex' }}
          name="remember"
          label="Stay signed in"
        />
      </Stack>

      <LoadingButton
        sx={{
          backgroundColor: '#504C5C',
          borderRadius: '5px',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#504C5C',
          },
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        LOG IN
      </LoadingButton>
      {/* <ContinueButton type="submit"  loading={isSubmitting}>SIGN IN</ContinueButton> */}
      <Link
        style={{
          fontSize: '18px',
          color: '#BEAEFF',
          textTransform: 'uppercase',
          fontWeight: '700',
          display: 'flex',
          justifyContent: 'center',
          margin: '20px',
          textAlign: 'center',
        }}
        component={RouterLink}
        underline="hover"
        to="/reset-password"
      >
        Forgot password?
      </Link>
    </FormProvider>
  );
}

const ContinueButton = styled.button`
border-radius: 5px;
width: 100%;
border: none;
display: inline-flex;
padding: 15px 35px;
justify-content: center;
align-items: center;
background-color: #504c5c;
color: #9da0a7;
text-align: center;
font-family: 'Barlow', sans-serif;
font-size: 18px;
font-weight: 700;
letter-spacing: 1.8px;
text-transform: uppercase;
margin-top: 1.5rem;
margin-bottom: 1.5rem;
cursor: pointer;

@media (max-width: 768px) {
  width: 100%;
  align-self: center;
  margin-bottom: 1rem;
  font-size: 16px;
  padding: 15px 20px;
}

@media (min-width: 768px) and (max-width: 1440px) {
  width: 100%;
  margin-top: 10px;
}
`;