import { useElements, useStripe, CardExpiryElement, CardNumberElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import styled from '@emotion/styled';
import BillingDetailsFields from './BillingDetailsField';

export default function CheckoutForm({ clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    const aspectRatio = innerWidth / innerHeight;
    return { innerWidth, innerHeight, aspectRatio };
  }

  const [checkoutError, setCheckoutError] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);

  const [isProcessing, setProcessingTo] = useState(false);

  const handleCardDetailsChange = (ev) => {
    if (ev.error) {
      setCheckoutError(ev.error.message)
    } else {
      setCheckoutError()
    };
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    
    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value,
        country: ev.target.country.value,
      },
      phone: ev.target.phone.value,
    };

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    console.log(billingDetails)

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: billingDetails,
      },
    });

    console.log(result)

    if (result.setupIntent.status === 'succeeded') {
      window.location.reload();
    }

    if (result.error) {
      setCheckoutError(result.error.message);
    }

    setIsLoading(false);
  };


  const iframeStyles = {
    base: {
      fontSize: '16px',
      iconColor: '#fff',
      color: "#9da0a7",
      '::placeholder': {
        paddingLeft: "0px",
        color: "#9da0a7",
        opacity: "30%",
        fontSize: windowSize.innerWidth > 769 ? "18px": "12px",    
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
    complete: {
      iconColor: 'green',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    hidePostalCode: true,
  };

  return (
    <FormContainer>
      <form id="card-form" onSubmit={(ev) => handleSubmit(ev)}>
        <LoginTitle>New Payment Method</LoginTitle>
        <Subtitle>Credit Card Information</Subtitle>
        <Label>Credit Card Number</Label>
        <CardElementContainer>
          <CardNumberElement
            options={cardElementOpts}
            onChange={(ev) => handleCardDetailsChange(ev)}
          />
        </CardElementContainer>
        <Label>Expiry Date</Label>
        <CardElementContainer>
          <CardExpiryElement
            options={cardElementOpts}
            onChange={(ev) =>handleCardDetailsChange(ev)}
          />
        </CardElementContainer>
        <Label>CVC</Label>
        <CardElementContainer>
          <CardCvcElement
            options={cardElementOpts}
            onChange={(ev) => handleCardDetailsChange(ev)}
          />
        </CardElementContainer>

        <BillingDetailsFields />
        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
        

        <PinkButton type='submit' 
        disabled={isProcessing || !stripe}
        >
          <p>Add card</p>
        </PinkButton>
        
      </form>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  width: 500px;
  margin: auto;
  padding-top: 30px;

  @media (max-width: 960px) {
    max-width: 100vw;
  }
`

const CheckoutError = styled.h2`
  color: white;
  text-align: center;
`

const PinkButton = styled.button`
  display: inline-flex;
  padding: 15px 45px;
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
  cursor: pointer;
  margin: 1rem 0rem 2rem 0rem;

  & p {
    color: #fbfcff;
    font-family: 'Barlow', sans-serif;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  @media (max-width: 768px) {
     width: 90%;
  }
`;

const Subtitle = styled.p`
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--text-light-grey, #CED1D9);
  text-align: start;
  font-family: Barlow;
  font-size: 22px;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const LoginTitle = styled.h3`
  color: #beaeff;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 50px;
  margin-top: -0.7rem;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 960px) {
    font-size: 40px;
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    font-size: 40px;
  }
`;

const Label = styled.label`
  flex: 1;
  background: #1b1b23;
  outline: none;
  padding-left: 0.5rem;
  margin: 0.5rem 0rem;
  font-size: 18px;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  width: 100%;
  height: 70px;
`;

const CardElementContainer = styled.div`
  flex: 1;
  background: #1b1b23;
  border: 2px solid #504c5c;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem 1rem;
  padding-left: 0px;
  margin: 0.5rem 0rem;
  font-size: 18px;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
  color: #9da0a7;
  width: 100%;
  height: 70px;

  ::placeholder {
    color: #9da0a7;
    opacity: 50%;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
  
  @media (max-width: 768px) {
     width: 90%;
  }
`;
