import Skeleton from '@mui/material/Skeleton';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

SkeletonTable.propTypes = {
  rowsPerPage: PropTypes.number,
  TABLE_HEAD: PropTypes.array,
};
function SkeletonTable({ rowsPerPage, TABLE_HEAD }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {TABLE_HEAD.map((col) => (
            <TableCell key={col.id}>
              <Skeleton variant="text" width="70%" />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from(new Array(rowsPerPage)).map((_, index) => (
          <TableRow key={index}>
            {TABLE_HEAD.map((col) => (
              <TableCell key={col.id}>
                <Skeleton variant="rectangular" height={31} width="80%" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default SkeletonTable;
