// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Box, Button } from '@mui/material';
// utils
// components
import Logo from '../../../assets/TZAppLogo.svg';

AppProductCard.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppProductCard({ btnText, cardColor, btnColor, btnHoverColor, logoUrl, sx, ...other }) {
  return (
    <Card
      sx={{
        px: 5,
        py: 7,
        textAlign: 'center',
        bgcolor: cardColor,
        width: '100%',
        height: '100%',
        dropShadow: '5px 5px 5px rgba(0, 0, 0, 0.80)',
        ...sx,
      }}
      {...other}
    >
      <Box sx={{ width: '100%', marginBottom: 6 }}>
        <img src={logoUrl} alt="brandLogo" />
      </Box>
      <Button
        sx={{
          mr: 4,
          boxShadow: 12,
          color: 'white',
          width: '100%',
          py: 1.5,
          fontSize: 20,
          backgroundColor: btnColor,
          '&:hover': {
            backgroundColor: btnHoverColor,
          },
        }}
      >
        {btnText}
      </Button>
    </Card>
  );
}

AppProductCard.propTypes = {
  /**
   * text on the button
   */
  btnText: PropTypes.string,
  /**
   * What background color to use on button
   */
  btnColor: PropTypes.string,
  /**
   * What background color to use on button hover
   */
  btnHoverColor: PropTypes.string,
  /**
   * What background color to use
   */
  cardColor: PropTypes.string,
  /**
   * What Logo to use
   */
  logoUrl: PropTypes.string,
};

AppProductCard.defaultProps = {
  btnText: 'sign in',
  cardColor: alpha('#2F9FFF', 0.25),
  btnColor: '#0084F4',
  btnHoverColor: '#005EAD',
  logoUrl: Logo,
};
