import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import Spotify from '../../feature/spotify/Spotify';
import { fetchSpotify } from '../../redux/slices/spotify';
import { SaveSpotifyPlayer } from '../../redux/actions/SpotifyActions';
import { fetchUserId, fetchUserName, fetchUserToken, fetchUserEmail } from '../../redux/slices/users';
import { getFromLocalStorage, setToLocalStorage, clearAllLocalStorage } from '../../hooks/useLocalStorage';
import IntraverseLogoIcon from '../../assets/images/Intraverse_Logo_Icon.png';
import AvatarPresetPage from './AvatarPresetPage';
import UserConsole from '../userPortalIntraverse/UserConsole';
import VanityURLRedirects from '../../VanityURLRedirects';
import GenericTCModal from '../../components/modal/GenericTCModal';
import DiamondBackground from '../intraverseLandingPage/IntraverseHomeImages/Diamond_Checkered_Background.png';
import SelectorScreenBgTransparent from '../intraverseLandingPage/IntraverseHomeImages/SelectorScreenBgTransparent.png';
import SelectorIntraverseLogoIcon from '../intraverseLandingPage/IntraverseHomeImages/IntraverseLogoIcon2.png';
import Banner from '../../components/intraverse/ui/Banner';

function Welcome() {
  const dispatch = useDispatch(); // Get the dispatch function

  const feeFormJSON = {
    nameTitle: '',
    urlSuffix: '',
    clientName: 'Intraverse Privacy Policy',
    emailTitle: '',
    nameSubtitle: '',
    emailSubtitle: '',
    spotifySignupTitle: '',
    spotifySignupSubtitle: '',
    termsAndConditionsText1:
      'By pressing <strong>Accept</strong>, you agree to receive personalized updates and marketing messages based on your information, interests, activities, website visits, and device data.',
    termsAndConditionsText2: 'For more information about how we use your personal information, please see ',
    termsAndConditionsLinkTZLink: 'https://terrazero.com/privacy-policy',
    tzName: 'TerraZero Privacy Policy',
    termsAndConditionsClientLink: 'https://terrazero.com/privacy-policy',
  };

  const [renderComponent, setRenderComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // User States
  const spotify = useSelector(fetchSpotify);

  const userId = useSelector(fetchUserId);
  const userToken = useSelector(fetchUserToken);
  const [spotifyPlayer, setSpotifyPlayer] = useState(null);

  const displayName = useSelector(fetchUserName);
  const email = useSelector(fetchUserEmail);
  const [vanityLinkData, setVanityLinkData] = useState({});
  const hasAcceptedTerms = localStorage.getItem('acceptedTerms') === 'true';
  const [acceptedTerms, setAcceptedTerms] = useState(hasAcceptedTerms);
  const [checkboxStatus, setCheckboxStatus] = useState(true);
  const [selectedAvatar, setSelectedAvatar] = useState(getFromLocalStorage('selectedAvatar'));
  const [selectorScreenPage, setSelectorScreenPage] = useState('home');
  const [spotifyNotPremiumAccount, setSpotifyNotPremiumAccount] = useState(false);

  const handleAcceptTerms = () => {
    setAcceptedTerms(true);
    setToLocalStorage('acceptedTerms', 'true');

    // The below code has to be initialized when a button click happens
    if (spotify.token) {
      const player = new window.Spotify.Player({
        name: 'Web Playback SDK',
        getOAuthToken: (cb) => {
          cb(spotify.token);
        },
        volume: 0.3,
      });
      setSpotifyPlayer(player);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (acceptedTerms && email && userToken && userId) {
        localStorage.removeItem('acceptedTerms');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [acceptedTerms]);

  useEffect(() => {
    const attributes = {
      room: 'Intraverse',
      email: email || null,
      name: displayName || null,
    };

    window.pushHotJarAttributes(userId || null, attributes);

    if (selectorScreenPage === 'play') {
      if (selectedAvatar === null) {
        setRenderComponent(<AvatarPresetPage selectedAvatar={selectedAvatar} setSelectedAvatar={setSelectedAvatar} />);
      }

      if (userToken && userId && acceptedTerms && selectedAvatar) {
        setRenderComponent(<VanityURLRedirects userId={userId} token={userToken} spotifyPlayer={spotifyPlayer} />);
      }
    }

    if (selectorScreenPage === 'console') {
      setRenderComponent(<UserConsole />);
    }

    setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 3000);
  }, [
    displayName,
    email,
    userToken,
    userId,
    isLoading,
    vanityLinkData,
    acceptedTerms,
    selectorScreenPage,
    selectedAvatar,
  ]);

  return (
    <>
      {selectorScreenPage === 'home' && userToken && userId && (
        <SelectorScreenContainer>
          <Banner
            width={'auto'}
            zIndex="3000"
            message={'We failed to connect. Please connect with a Premium Spotify account.'}
            type={'ERROR'}
            openBanner={spotifyNotPremiumAccount}
            setOpenBanner={setSpotifyNotPremiumAccount}
            topDisplay
            showCloseButton
            gameScreen={false}
            timer={12000}
          />

          <SelectorLogo src={SelectorIntraverseLogoIcon} alt="Selector Logo" />
          <ButtonsContainer>
            <SelectorScreenButton onClick={() => setSelectorScreenPage('play')} selectorScreenPage={selectorScreenPage}>
              PLAY NOW
            </SelectorScreenButton>
            <SelectorScreenButton
              onClick={() => setSelectorScreenPage('console')}
              selectorScreenPage={selectorScreenPage}
            >
              MY CONSOLE
            </SelectorScreenButton>

            {/* <SelectorScreenButton
              onClick={() => setSelectorScreenPage('settings')}
              selectorScreenPage={selectorScreenPage}
            >
              SETTINGS
            </SelectorScreenButton> */}

            {/* This is temporary until we have a settings page */}
            <SpotifyButton>
              <Spotify />
            </SpotifyButton>
          </ButtonsContainer>

          <SelectorFooterText>
            {/* <p>VERSION 0.1</p> */}
            <p>© 2023 TerraZero Technologies. All rights reserved.</p>
          </SelectorFooterText>
        </SelectorScreenContainer>
      )}
      {selectorScreenPage !== 'home' && selectorScreenPage !== 'console' && userToken && userId && (
        <HomeContainer style={{ backgroundImage: `url(${DiamondBackground})` }}>
          <IntraverseLogo
            src={IntraverseLogoIcon}
            alt="Intraverse Logo"
            onClick={() => setSelectorScreenPage('home')}
          />
          <div>
            {selectedAvatar && !acceptedTerms && (
              <GenericTCModal
                setAcceptedTerms={handleAcceptTerms}
                vanityLinkData={feeFormJSON}
                setCheckboxStatus={setCheckboxStatus}
                checkboxStatus={checkboxStatus}
              />
            )}
          </div>
          <BackgroundOverlay />
          {renderComponent}
        </HomeContainer>
      )}
      {selectorScreenPage === 'console' && userToken && userId && (
        <>
          <Navigate to="/user-console" />
        </>
      )}
    </>
  );
}

export default Welcome;

const SelectorScreenContainer = styled.div`
  /* box-sizing: border-box; */
  background: rgba(27, 27, 35, 1);
  background-image: url(${SelectorScreenBgTransparent});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  @media (min-width: 768px) {
    height: 100%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 100vh;
  }
`;

const SelectorLogo = styled.img`
  position: absolute;
  right: 15%;
  top: 10%;
  width: 80px;
  height: 80px;
  z-index: 15;
  margin-right: -2rem;
`;

const SpotifyButton = styled.div`
  position: relative;
  float: right;
  left: 30px;
  margin-top: 1.5rem;

  @media (max-width: 960px) {
    margin-top: 1rem;
  }
`;

const ButtonsContainer = styled.div`
  position: absolute;
  right: 15%;
  top: 35%;
`;
const SelectorFooterText = styled.div`
  position: absolute;
  right: 12%;
  bottom: 5%;

  & > p {
    color: #9da0a7;
    text-align: right;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 1.6px;
  }
`;

const SelectorScreenButton = styled.button`
  cursor: pointer;
  height: auto;
  width: 100%;
  height: 40px;
  margin: 1.5rem 2rem;

  text-align: right;
  color: #fbfcff;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 960px) {
    margin: 0.5rem 2rem;
  }
`;

const HomeContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  background: rgba(27, 27, 35, 1);
  background-size: 350px auto;
  background-position: center;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const BackgroundOverlay = styled.div`
  overflow: hidden;
  background-color: #1b1b23;
  opacity: 0.7;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2;
`;

const IntraverseLogo = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20px;
  left: 20px;
  z-index: 5;

  @media (max-width: 960px) {
    width: 25px;
    height: 25px;
    top: 5px;
    left: 5px;
  }
  @media (min-width: 960px) and (max-width: 1440px) {
    width: 30px;
    height: 30px;
    top: 20px;
    left: 20px;
  }
`;
