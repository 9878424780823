import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Backdrop, Box, Modal, Fade, Typography, TextField } from '@mui/material';
import { PrimaryButton, CancelButton } from '../Buttons/CustomizedButton';

const style = {
  padding: '25px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius: '12px',
  boxShadow: 24,
};

export default function StatusModal({ action, confirmed, open, handleClose, blockStatus }) {
  const [reason, setReason] = useState('');
  const [confirming, setConfirming] = useState(false);
  const handleUnblockConfirmClick = () => {
    confirmed();
    handleClose();
  };
  const handleConfirmClick = () => {
    setConfirming(true);
  };

  const handleReasonConfirm = () => {
    confirmed(reason);
    handleClose();
  };

  const handleCancelClick = () => {
    setConfirming(false);
    handleClose();
  };
  return (
    <div>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
      <Fade in={open}>
        <Box sx={style}>
          {blockStatus === false &&
            <div>
              <Typography id="transition-modal-title" variant="h6" component="h2" textAlign="center" letterSpacing="1px">
                <p>Are you sure you want to block user?</p>
              </Typography>
              {confirming ? (
                <Typography id="transition-modal-description" textAlign="center" sx={{ mt: 3, mb: 0, ml: 2 }}>
                    <TextField
                      value={reason || ''}
                      onChange={(e) => setReason(e.target.value)}
                      placeholder="Please enter a reason"
                      style={{ width: '100%', padding: '8px', borderRadius: '4px', resize: 'none' }}
                    />
                    <PrimaryButton onClick={handleReasonConfirm}>Confirm</PrimaryButton>
                    <CancelButton onClick={handleCancelClick}/>
                </Typography>
              ) : (
                <Typography id="transition-modal-description" textAlign="center" sx={{ mt: 3, mb: 3, ml: 2 }}>
                  <PrimaryButton onClick={handleConfirmClick}>Confirm</PrimaryButton>
                  <CancelButton onClick={handleClose} />
                </Typography>
              )}
            </div>
          }
          {blockStatus === true &&
            <div>
              <Typography id="transition-modal-title" variant="h6" component="h2" textAlign="center" letterSpacing="1px"> 
                <p>Are you sure you want to unblock user?</p>
              </Typography>
              <Typography id="transition-modal-description" textAlign="center" sx={{ mt: 3, mb: 0, ml: 2 }}>
                <PrimaryButton onClick={() => handleUnblockConfirmClick()}> Confirm</PrimaryButton>
                <CancelButton onClick={() => handleClose()} />
              </Typography>
            </div>
          }
        </Box>
      </Fade>
      </Modal>
    </div>
  );
}

StatusModal.propTypes = {
  action: PropTypes.string,
  confirmed: PropTypes.func,
  status: PropTypes.string
};
