/* eslint-disable react/prop-types */
import * as React from 'react';
import { useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {
  TextField,
  Box,
  Grid,
  FormControl,
  Button,
  Stack,
  Chip,
  Divider,
  Typography,
  InputAdornment,
  IconButton,
  Avatar,
  Autocomplete
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { RegisterMerchant, getCities } from '../../api/MerchantsController';
import Iconify from '../Iconify';
import { FormProvider } from '../hook-form';
import { PrimaryButton, CancelButton } from '../common/Buttons/CustomizedButton';
import Alert from '../common/Alert/Alert';

export default function NewMerchantModal(props) {
  const token = useSelector((state) => state?.user?.token);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [icon, setIcon] = useState(null);
  const [createMerchantMsg, setCreateMerchantMsg] = useState(null);
  const [createMerchantStatus, setCreateMerchantStatus] = useState(null);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [disPlayTextError, setDisplayTextError] = useState(false);
  const [modalError, setModalError] = useState();
  const [upload, setUpload] = useState('');
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const merchantName = useRef();
  const businessType = useRef();
  const email = useRef();
  const unitNumber = useRef();
  const street = useRef();
  const postalCode = useRef();
  const uploadButton = useRef();
  const TZ_ICON = '/static/images/avatar/TZIcon.png';
  const { allStatuses } = props;
  const [status, setStatus] = useState('');
  const [city, setCity] = useState(null);
  const [autoCountry, setAutoCountry] = useState(null);

  const countries = [{'countryId':1, 'countryName': 'United States'}, 
                    {'countryId':2, 'countryName': 'Canada'}];
  const validateInput = () => {
    const errorList = {
      email: '',
      city: '',
      merchantName: '',
      businessType: '',
      unitNumber: '',
      street: '',
      postalCode: '',
      autoCountry: '',
      status: ''
    };
    let errors = false;
    if (!merchantName.current.value || merchantName.current.value.length === 0) {
      errors = true;
      errorList.merchantName = 'Please provide your merchant name.';
    }
    if (!status || status.length === 0) {
      errors = true;
      errorList.status = 'Please choose a status.';
    }
    if (!autoCountry || autoCountry.length === 0) {
      errors = true;
      errorList.autoCountry = 'Please choose country.';
    }
    if (!city || city.length === 0) {
      errors = true;
      errorList.city = 'Please choose city.';
    }
    if (!businessType.current.value || businessType.current.value.length === 0) {
      errors = true;
      errorList.businessType = 'Tell us what kind of business you are in.';
    }
    if (!unitNumber.current.value || unitNumber.current.value.length === 0) {
      errors = true;
      errorList.unitNumber = 'Please provide your unit number.';
    }
    if (!street.current.value || street.current.value.length === 0) {
      errors = true;
      errorList.street = 'Please provide your street.';
    }
    
    const usaZipCodeRegex = /^\d{5}(?:-\d{4})?$/;
    const canadaPostalCodeRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
    
    if (!postalCode.current.value || postalCode.current.value.length === 0) {
      errors = true;
      errorList.postalCode = 'Please provide your postal code.';
    }else{
      if(autoCountry === 'United States' || autoCountry === 'united states' || autoCountry === 'UNITED STATES' || autoCountry === 'US' || autoCountry === 'USA'){
        const usaCheck = usaZipCodeRegex.test(postalCode.current.value);
        if(usaCheck === false){
          errors = true;
          errorList.postalCode = 'Please provide valid postal code of USA.';
        }
      }
      if(autoCountry === 'Canada' || autoCountry === 'canada' || autoCountry === 'CANADA' || autoCountry === 'CA'){
        const caCheck = canadaPostalCodeRegex.test(postalCode.current.value)
        if(caCheck === false){
          errors = true;
          errorList.postalCode = 'Please provide valid postal code of Canada. [A1A 1A1]';
        }
      }
    }
 
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.current.value || email.current.value.length === 0 || reg.test(email.current.value) === false) {
      errors = true;
      errorList.email = 'Please provide a valid email.';
    }
    setModalError(errorList);
    setDisplayTextError(errors);
    return errors;
  };

  const addProfileHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);
    
    if (!validateInput()) {
      const formattedAutoCity = city.split(',')[0].trim();
      const data = {
        status,
        merchantBusinessType: businessType.current.value,
        merchantContactEmail: email.current.value,
        merchantName: merchantName.current.value,
        unitNumber: unitNumber.current.value,
        street: street.current.value,
        postalCode: postalCode.current.value,
        country: autoCountry,
        city: formattedAutoCity,
      };
      const respMerchant = await RegisterMerchant(data, token);
      if(respMerchant.status === 201){
        setError(false);
        setSuccess(true);
        setCreateMerchantStatus(respMerchant.status);
        setCreateMerchantMsg('Merchant created successfully');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }else{
        if(respMerchant.error){
          setCreateMerchantStatus(400);
          setMessage(respMerchant.error[0].msg);
        }else{
          setCreateMerchantStatus(respMerchant.status);
          setMessage('An error occured. Please try again.');
        }
        setSuccess(false);
        setError(true);
      }
    } else {
      setSuccess(false);
      setError(true);
      setMessage('All inputs must be completed.');
    }
    setLoading(false);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value); 
  };
  const handleCountryChange = (event) => {
    setAutoCountry(event.target.value); 
  };

  const handleImageChange = (event) => {
    if (!event.target.files[0].type === 'image/jpeg') {
      setUploadErrorMessage('Please upload an image type');
    } else if (event.target.files[0].size >= 2000) {
      setUploadErrorMessage('The uploaded Image file is too large');
    } else {
      const imageURL = URL.createObjectURL(new File(event.target.files, 'image'));
      setUpload(imageURL);
      setUploadErrorMessage('');
      setIcon(event.target.files[0]);
    }
  };

  const handleClearInputs = () => {
    setUpload('');
    setMessage(null);
    setError(false);
    setDisplayTextError(false);
    setModalError();
    setIcon(null);
    props.handleClose();
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClearInputs}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1000px',
          },
        },
      }}
    >
      <FormProvider methods={null} onSubmit={addProfileHandler}>
        <DialogTitle>Create New Merchant</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginBottom: '10px', marginTop: '10px' }}>
            <Grid item xs={12} md={6}>
              <TextField
                id="merchantName"
                label="Merchant Name"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                inputRef={merchantName}
                helperText={disPlayTextError && modalError.merchantName}
                error={disPlayTextError && modalError.merchantName.length > 0 && true}
              />
              
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                helperText={disPlayTextError && modalError.email}
                inputRef={email}
                error={disPlayTextError && modalError.email.length > 0 && true}
              />
              <TextField
                id="unitNumber"
                label="Unit Number"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                inputRef={unitNumber}
                helperText={disPlayTextError && modalError.unitNumber}
                error={disPlayTextError && modalError.unitNumber.length > 0 && true}
              />
              <FormControl style={{ width: '100%', marginBottom: '20px' }}>                  
                <InputCity 
                  city={city} 
                  setCity={setCity} 
                  setAutoCountry={setAutoCountry} 
                  disPlayTextError={disPlayTextError}
                  modalError={modalError}
                />
              </FormControl>

              <Stack direction="row" alignItems="center" style={{ marginBottom: '20px' }} columnGap={5}>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  style={{ backgroundColor: 'rgba(145, 158, 171, 0.16)', color: 'black' }}
                  sx={{ boxShadow: 12, padding: '0 40px', alignSelf: 'center' }}
                >
                  Upload Icon
                  <input onChange={handleImageChange} hidden accept="image/*" multiple type="file" ref={uploadButton} />
                </Button>
                <Avatar
                  alt="Client Icon"
                  src={upload}
                  sx={{ width: 80, height: 80, border: '1px solid rgba(145, 158, 171, 0.16)' }}
                />
              </Stack> 
              <Typography variant="p" component="p" color="#0084F4">
                {uploadErrorMessage}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="businessType"
                label="Business Type"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                helperText={disPlayTextError && modalError.businessType}
                inputRef={businessType}
                error={disPlayTextError && modalError.businessType.length > 0 && true}
              />

              {allStatuses && allStatuses.length > 0 && (
                <FormControl style={{ width: '100%', marginBottom: '20px' }}>                  
                <InputLabel style={{ marginTop: '-7px' }}>Status</InputLabel>
                  <Select
                    style={{ height: '40px' }}
                    labelId="simple-select-label1"
                    id="simple-select"
                    label="Status"
                    value={status || ''} 
                    onChange={handleStatusChange}
                  >
                    {allStatuses.map((option) => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {disPlayTextError && modalError.status && (
                    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{modalError.status}</div>
                  )}                
                  </FormControl>
              )}
              <TextField
                id="street"
                label="Street"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                inputRef={street}
                helperText={disPlayTextError && modalError.street}
                error={disPlayTextError && modalError.street.length > 0 && true}
              />
              
              <TextField
                id="postalCode"
                label="Postal Code"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                inputRef={postalCode}
                helperText={disPlayTextError && modalError.postalCode}
                error={disPlayTextError && modalError.postalCode.length > 0 && true}
              />

              {countries && countries.length > 0 && (
                <FormControl style={{ width: '100%', marginBottom: '20px' }}>                  
                <InputLabel style={{ marginTop: '-7px' }}>Country</InputLabel>
                  <Select
                    style={{ width: '100%', marginBottom: '20px' }} size="small"
                    labelId="simple-select-label1"
                    id="simple-select-country"
                    label="Country"
                    value={autoCountry || ''} 
                    onChange={handleCountryChange}
                  >
                    {countries.map((option) => (
                      <MenuItem key={option.countryId} value={option.countryName}>
                        {option.countryName}
                      </MenuItem>
                    ))}
                  </Select>
                  {disPlayTextError && modalError.country && (
                    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{modalError.country}</div>
                  )}                
                  </FormControl>
              )}
            </Grid>
          </Grid>

          {(createMerchantStatus === 201 &&
            <Alert type="success" message={createMerchantMsg}/>
          )}
          {(createMerchantStatus === 400 &&
            <Alert type="error" message={createMerchantMsg}/>
          )}
          
          <Box display="flex" width="100%" alignItems="flex-end" justifyContent="left" sx={{ ml: 0, float: 'left' }}>
            <PrimaryButton
              loading={loading}
              backgroundColor="#0084F4"
              showMessage={message}
              error={error}
              success={success}
            >
              Confirm
            </PrimaryButton>
            <CancelButton onClick={handleClearInputs} />
          </Box>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
}

const InputCity = (props) => {
  const [cityOptions, setCityOptions] = useState([]);
  const handlePick = (e, v) => {
    if (v) {
      props.setCity(v.name);
      if(v.country === 'CA'){
        props.setAutoCountry('Canada');
      }else if(v.country === 'US'){
        props.setAutoCountry('United States');
      }
    }
  };

  useEffect(() => {
    fetchCitiesData().then((data) => {
      setCityOptions(data);
    });
  }, []);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      getOptionLabel={(option) => option.name}
      options={cityOptions}
      onChange={handlePick}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => 
        <TextField {...params} 
          label="City" 
          style={{ width: '100%', marginBottom: '20px' }} 
          size="small"
          helperText={props.disPlayTextError && props.modalError.city}
          error={props.disPlayTextError && props.modalError.city.length > 0}
      />}
    />
  );
};

const fetchCitiesData = async () => {
  try {
    const response = await getCities();
    return response;
  } catch (error) {
    console.error("Error fetching states data:", error);
    return [];
  }
};
