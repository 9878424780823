import { createContext, useState } from 'react';

const UserContext = createContext({ setSubscribed: (value) => value });

const InitializeFiltersContext = () => {
  const [subscribed, setSubscribed] = useState(false);

  return {
    subscribed,
    setSubscribed,
  };
};

export { UserContext, InitializeFiltersContext };
