import React, { useEffect, useState } from 'react';
import axiosInstance from './config/axiosConfig';
import VagonStream from './VagonStream';
import VagonStreamIntraverse from './pages/vagon/VagonStreamIntraverse';
import { getFromLocalStorage } from './hooks/useLocalStorage';
import { isMobileDevice } from './utils/utils';
import { sendInfoToActionLog } from './sections/auth/api/Controller';
import { actionTypes } from './utils/actionTypes';

const VanityURLRedirects = (props) => {
  const [redirectURL, setRedirectURL] = useState(null);
  const [roomIdPath, setRoomIdPath] = useState('');

  useEffect(() => {
    const fetchVanityLink = async (id) => {
      try {
        const response = id ? await axiosInstance.get(`/web/vanity_links/${id}`) : { data: {} };
        const { roomId = '', staticUrl = process.env.REACT_APP_DEFAULT_STREAM_URL } = response.data;

        setRoomIdPath(roomId);

        const launchFlags = {
          '-roomid': roomId,
          '-userid': props.userId,
          '-authtoken': getFromLocalStorage('userToken') || props.token,
          '-disableVoiceChat': false,
          '-showdebugger': true,
          '-userdevicetype': isMobileDevice() ? 'mobile' : 'desktop',
          '-avatarpreset': getFromLocalStorage('selectedAvatar'),
        };

        if (!roomId) {
          delete launchFlags['-roomid'];
        }

        const queryString = Object.entries(launchFlags)
          .map(([key, value]) => `${key}%20${value}`)
          .join('%20');
        const redirectURL = `${staticUrl}?launchFlags=${queryString}`;
        console.log('redirectURL', redirectURL);
        setRedirectURL(redirectURL);
      } catch (error) {
        if (process.env.REACT_APP_REDIRECT_TOGGLE) {
          window.location.replace('https://intraverse.com/');
        }
        console.error('Error fetching the vanity link:', error);
      }
    };

    const pathId = window.location.pathname.split('/').pop();
    fetchVanityLink(pathId);
  }, [props.userId]);

  useEffect(() => {
    sendInfoToActionLog(actionTypes.SESSION_START, {}, getFromLocalStorage('userToken'));
  }, []);

  return (
    redirectURL &&
    (roomIdPath ? (
      <VagonStream streamUrl={redirectURL} />
    ) : (
      <VagonStreamIntraverse streamUrl={redirectURL} spotifyPlayer={props.spotifyPlayer} />
    ))
  );
};

export default VanityURLRedirects;
