/* eslint-disable react/prop-types */
import * as React from 'react';
import { useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {
  TextField,
  Box,
  Grid,
  FormControl,
  Button,
  Stack,
  Chip,
  Divider,
  Typography,
  InputAdornment,
  IconButton,
  Avatar
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { RegisterUser } from '../../api/UsersController';
import { SUPERADMIN_ROLE,
  ADMIN_ROLE,
  USER_ROLE,
  SUPERADMIN_DISPLAY_ROLE, 
  ADMIN_DISPLAY_ROLE, 
  USER_DISPLAY_ROLE  } from '../../constants';
import Iconify from '../Iconify';
import { FormProvider } from '../hook-form';
import { PrimaryButton, CancelButton } from '../common/Buttons/CustomizedButton';
import Alert from '../common/Alert/Alert';

export default function NewUserModal(props) {
  const MAXIMUM_BYTES_IMAGE = 200000;
  const userRole = useSelector((state) => state?.user?.role);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [icon, setIcon] = useState(null);
  const [createUserMsg, setCreateUserMsg] = useState(null);
  const [createUserStatus, setCreateUserStatus] = useState(null);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [disPlayTextError, setDisplayTextError] = useState(false);
  const [modalError, setModalError] = useState();
  const [upload, setUpload] = useState('');
  const [eventName, setEventName] = useState([]);
  const [productName, setProductName] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [role, setRole] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const roleRef = useRef();
  const firstName = useRef();
  const lastName = useRef();
  const displayName = useRef();
  const email = useRef();
  // const phoneNumber = useRef();
  const password = useRef();
  const confirmPassword = useRef();
  const company = useRef();
  const uploadButton = useRef();
  const TZ_ICON = '/static/images/avatar/TZIcon.png';
  
  const validateInput = () => {
    const errorList = {
      email: '',
      firstName: '',
      lastName: '',
      displayName: '',
      password: '',
      confirmPassword: '',
      uploadButton: '',
    };
    let errors = false;
    if (!firstName.current.value || firstName.current.value.length === 0) {
      errors = true;
      errorList.firstName = 'Please provide your first name.';
    }
    if (!displayName.current.value || displayName.current.value.length === 0) {
      errors = true;
      errorList.displayName = 'Please provide your user name.';
    }
    if (!lastName.current.value || lastName.current.value.length === 0) {
      errors = true;
      errorList.lastName = 'Please provide your last name.';
    }
    if (!password.current.value || password.current.value.length < 6) {
      errors = true;
      errorList.password = 'Please provide min 6 lenght password.';
    }
    if (!confirmPassword.current.value || confirmPassword.current.value.length < 6) {
      errors = true;
      errorList.confirmPassword = 'Please provide min 6 lenght confirm password.';
    }
    // if (role === 'corporate') {
    //   if (!uploadButton.current.value) {
    //     errors = true;
    //     errorList.uploadButton = 'Please upload an image.';
    //   }
    // }

    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w\w+)+$/;
    if (!email.current.value || email.current.value.length === 0 || reg.test(email.current.value) === false) {
      errors = true;
      errorList.email = 'Please provide a valid email.';
    }
    // const regExp = /[a-zA-Z]/g;
    // if (
    //   !phoneNumber.current.value ||
    //   phoneNumber.current.value.length < 10 ||
    //   regExp.test(phoneNumber.current.value) === true
    // ) {
    //   errorList.phoneNumber = 'Your phone number must include your area code without any symbols.';
    //   errors = true;
    // }
    setModalError(errorList);
    setDisplayTextError(errors);
    return errors;
  };

  const addProfileHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);
    const data = {
      active: true,
      // client: role === 'corporate' ? selectedCompany.id : '',
      email: email.current.value,
      icon: TZ_ICON,
      lastUpdated: null,
      createdAt: null,
      firstName: firstName.current.value,
      lastName: lastName.current.value,
      displayName: displayName.current.value,
      role,
      uid: null,
    };
    if (!validateInput()) {
      data.email = email.current.value;
      data.password = password.current.value;
      data.confirmPassword = confirmPassword.current.value;
      const respUser = await RegisterUser(data);
      if(respUser.status === 201){
        setError(false);
        setSuccess(true);
        setCreateUserStatus(respUser.status);
        setCreateUserMsg('User created successfully');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }else{
        if(respUser.error){
          setCreateUserStatus(400);
          setMessage(respUser.error[0].msg);
        }else{
          setCreateUserStatus(respUser.status);
          setMessage('An error occured. Please try again.');
        }
        setSuccess(false);
        setError(true);
      }
    } else {
      setSuccess(false);
      setError(true);
      setMessage('All inputs must be completed.');
    }
    setLoading(false);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSingleCompanyChange = (event) => {
    setSelectedCompany(event.target.value); 
  };
  const handleRoleChange = (event) => {
    setRole(event.target.value); 
  };

  const handleImageChange = (event) => {
    if (!event.target.files[0].type === 'image/jpeg') {
      setUploadErrorMessage('Please upload an image type');
    } else if (event.target.files[0].size >= MAXIMUM_BYTES_IMAGE) {
      setUploadErrorMessage('The uploaded Image file is too large');
    } else {
      const imageURL = URL.createObjectURL(new File(event.target.files, 'image'));
      setUpload(imageURL);
      setUploadErrorMessage('');
      setIcon(event.target.files[0]);
    }
  };

  const handleClearInputs = () => {
    setUpload('');
    setRole('');
    setProductName([]);
    setEventName([]);
    setMessage(null);
    setError(false);
    setDisplayTextError(false);
    setModalError();
    setIcon(null);
    props.handleClose();
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClearInputs}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1000px',
          },
        },
      }}
    >
      <FormProvider methods={null} onSubmit={addProfileHandler}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginBottom: '10px', marginTop: '10px' }}>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                inputRef={email}
                helperText={disPlayTextError && modalError.email}
                error={disPlayTextError && modalError.email.length > 0 && true}
              />
              <TextField
                id="firstName"
                label="First Name"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                helperText={disPlayTextError && modalError.firstName}
                inputRef={firstName}
                error={disPlayTextError && modalError.firstName.length > 0 && true}
              />
              <TextField
                id="lastName"
                label="Last Name"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                helperText={disPlayTextError && modalError.lastName}
                inputRef={lastName}
                error={disPlayTextError && modalError.lastName.length > 0 && true}
              />
              {/* <TextField
                id="phoneNumber"
                label="Phone Number"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                inputRef={phoneNumber}
                helperText={disPlayTextError && modalError.phoneNumber}
                error={disPlayTextError && modalError.phoneNumber.length > 0 && true}
              /> */}

              <FormControl style={{ width: '100%', marginBottom: '20px' }}>
                <InputLabel id="simple-select-label">Role</InputLabel>
                <Select
                  style={{ height: '40px' }}
                  labelId="simple-select-label"
                  id="simple-select"
                  label="Role"
                  value={role}
                  onChange={handleRoleChange}
                > 
                  {props.allRoles.map((option) => {
                    let dropdownValue;
                    if (option === ADMIN_ROLE) {
                      dropdownValue = ADMIN_DISPLAY_ROLE;
                    } else if (option === SUPERADMIN_ROLE) {
                      dropdownValue = SUPERADMIN_DISPLAY_ROLE;
                    } else if (option === USER_ROLE) {
                      dropdownValue = USER_DISPLAY_ROLE;
                    }
                    return (
                      <MenuItem key={option} value={option}>
                        {dropdownValue}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {disPlayTextError && (
                <Typography sx={{ color: 'red', mb: 1, fontSize: 12 }}>{modalError.uploadButton}</Typography>
              )}
              <Stack direction="row" alignItems="center" style={{ marginBottom: '20px' }} columnGap={5}>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  style={{ backgroundColor: 'rgba(145, 158, 171, 0.16)', color: 'black' }}
                  // style={{ backgroundColor: 'rgba(145, 158, 171, 0.16)', color: role !== 'Client' ? 'grey' : 'black' }}
                  // disabled={role !== 'Client'}
                  sx={{ boxShadow: 12, padding: '0 40px', alignSelf: 'center' }}
                >
                  Upload Icon
                  <input onChange={handleImageChange} hidden accept="image/*" multiple type="file" ref={uploadButton} />
                </Button>
                <Avatar
                  alt="Client Icon"
                  src={upload}
                  sx={{ width: 80, height: 80, border: '1px solid rgba(145, 158, 171, 0.16)' }}
                />
              </Stack>
              <Typography variant="p" component="p" color="#0084F4">
                {uploadErrorMessage}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="Password"
                label="Password"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                helperText={disPlayTextError && modalError.password}
                error={disPlayTextError && modalError.password.length > 0 && true}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={password}
              />{' '}
              <TextField
                id="confirmPassword"
                label="Confirm Password"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                helperText={disPlayTextError && modalError.confirmPassword}
                error={disPlayTextError && modalError.confirmPassword.length > 0 && true}
                type={showPassword ? 'text' : 'confirmPassword'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={confirmPassword}
              />{' '}
              <TextField
                id="displayName"
                label="Display Name"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                size="small"
                defaultValue=""
                helperText={disPlayTextError && modalError.displayName}
                inputRef={displayName}
                error={disPlayTextError && modalError.displayName.length > 0 && true}
              />
              {/* {role === 'corporate' &&
                <FormControl sx={{ width: '100%', marginBottom: '20px' }}>
                  <InputLabel id="multiple-chip-label" size="small">Clients</InputLabel>
                  <Select
                    style={{ height: '40px' }}
                    labelId="multiple-chip-label"
                    id="multiple-chip"
                    multiple={false}
                    value={selectedCompany}
                    onChange={handleSingleCompanyChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Company" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.plainName}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                  {JSON.stringify(props.companies) &&
                      props.companies.map((company) => (
                        <MenuItem key={company.id} value={company}>
                          {company.plainName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              } */}
            </Grid>
          </Grid>

          {(createUserStatus === 201 &&
            <Alert type="success" message={createUserMsg}/>
          )}
          {(createUserStatus === 400 &&
            <Alert type="error" message={createUserMsg}/>
          )}
          
          <Box display="flex" width="100%" alignItems="flex-end" justifyContent="left" sx={{ ml: 0, float: 'left' }}>
            <PrimaryButton
              loading={loading}
              backgroundColor="#0084F4"
              showMessage={message}
              error={error}
              success={success}
            >
              Confirm
            </PrimaryButton>
            <CancelButton onClick={handleClearInputs} />
          </Box>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
}
