import { Navigate, useRoutes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import RegisterUserPage from './pages/RegisterUserPage';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/Page404';
import DashboardApp from './pages/admins/DashboardApp';
import Profile from './pages/Profile';
import UserProfile from './pages/UserProfile';
import User from './pages/User';
import Merchant from './pages/Merchant';
import { Logout } from './sections/auth/api/Controller';
import MerchantProfile from './pages/shared/MerchantProfile';
import GenericLoginPage from './pages/shared/GenericLoginPage';
import { fetchIsMerchant, fetchUserId, fetchUserName, fetchUserRole, fetchUserToken } from './redux/slices/users';
import MerchantsDashboard from './pages/merchants/MerchantsDashboard';
import { SUPERADMIN_ROLE } from './constants';
import { fetchMerchantInfo } from './redux/slices/merchants';
import Welcome from './pages/shared/Welcome';
import CallToActionPage from './CallToAction';
import IntraverseLandingHome from './pages/intraverseLandingPage/IntraverseLandingHome';
// import background from './assets/images/BG.png';
import DiamondBackground from './pages/intraverseLandingPage/IntraverseHomeImages/Diamond_Checkered_Background.png';
import UserConsole from './pages/userPortalIntraverse/UserConsole';
import PasswordUpdate from './pages/PasswordUpdate';
import UserConsoleWallet from './pages/walletPage/UserConsoleWallet';
import UserConsoleOrders from './pages/userPortalIntraverse/UserConsoleOrders';


const Background = styled(Box)({
  width: '100vw',
  background: 'rgba(27, 27, 35, 1)',
  backgroundImage: `url(${DiamondBackground})`,
  backgroundSize: '350px auto',
  backgroundPosition: 'center',
});

window.addEventListener('storage', (e) => {
  if (e.key === 'persist:user') {
    const json = JSON.parse(e.newValue).user;
    if (!JSON.parse(json).user) {
      Logout();
    }
  }
});

// ${process.env.API_URL}/core-api/web/vanity_links/${id}
export default function Router() {
  const user = useSelector(fetchUserName);
  const role = useSelector(fetchUserRole);
  const token = useSelector(fetchUserToken);
  const userId = useSelector(fetchUserId);
  const isMerchant = useSelector(fetchIsMerchant);
  const merchant = useSelector(fetchMerchantInfo);
  const { merchantId } = merchant;

  return useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute user={user} token={token} userId={userId} role={role}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        // Share Routes
        {
          path: 'profile',
          element: (
            <ProtectedRoute user={user} token={token} userId={userId} role={role}>
              <Profile />{' '}
            </ProtectedRoute>
          ),
        },

        // Merchant Routes
        ...(isMerchant
          ? [
              {
                path: `merchant-profile/${merchantId}`,
                element: (
                  <ProtectedRoute user={user} token={token} userId={userId} role={role}>
                    <MerchantProfile />{' '}
                  </ProtectedRoute>
                ),
              },
              {
                path: 'app',
                element: (
                  <ProtectedRoute user={user} token={token} userId={userId} role={role}>
                    <MerchantsDashboard />{' '}
                  </ProtectedRoute>
                ),
              },
            ]
          : []),

        // Admin Routes
        ...(role && role === SUPERADMIN_ROLE
          ? [
              {
                path: 'app',
                element: (
                  <ProtectedRoute user={user} token={token} userId={userId} role={role}>
                    <DashboardApp />{' '}
                  </ProtectedRoute>
                ),
              },
              {
                path: 'merchant',
                element: (
                  <ProtectedRoute user={user} token={token} userId={userId} role={role}>
                    <Merchant />{' '}
                  </ProtectedRoute>
                ),
              },
              {
                path: 'user',
                element: (
                  <ProtectedRoute user={user} token={token} userId={userId} role={role}>
                    <User />{' '}
                  </ProtectedRoute>
                ),
              },
              {
                path: 'user-profile/:id',
                element: (
                  <ProtectedRoute user={user} token={token} userId={userId} role={role}>
                    <UserProfile />{' '}
                  </ProtectedRoute>
                ),
              },
              {
                path: 'merchant-profile/:id',
                element: (
                  <ProtectedRoute user={user} token={token} userId={userId} role={role}>
                    <MerchantProfile />{' '}
                  </ProtectedRoute>
                ),
              },
            ]
          : []),
      ],
    },
    {
      path: 'call-to-action',
      element: (
        <ProtectedRoute user={user} token={token} userId={userId} role={role}>
          <CallToActionPage />
        </ProtectedRoute>
      ),
    },
    {
      path: 'user-console',
      element: (
        <UserConsoleRoute user={user} token={token} userId={userId} role={role}>
          <UserConsole />
        </UserConsoleRoute>
      ),
    },
    {
      path: 'login',
      element: (
        <RedirectIfLoggedIn user={user} token={token} userId={userId} role={role}>
          <Login style={{ Index: 1000 }} />
        </RedirectIfLoggedIn>
      ),
    },
    {
      path: 'register',
      element: (
        <RedirectIfLoggedIn user={user}>
          <RegisterUserPage />
        </RedirectIfLoggedIn>
      ),
    },
    {
      path: 'wallet',
      element: (
        <UserConsoleRoute user={user} token={token} userId={userId} role={role}>
          <UserConsoleWallet/>
        </UserConsoleRoute>
      ),
    },
    {
      path: 'orders',
      element: (
        <UserConsoleRoute user={user}>
          <UserConsoleOrders/>
        </UserConsoleRoute>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <RedirectIfLoggedIn user={user} token={token} userId={userId} role={role}>
          {' '}
          <ResetPassword />
        </RedirectIfLoggedIn>
    )},
    {
      path: 'reset-password/:token',
      element: <PasswordUpdate />,
    },

    {
      path: '*',
      element: <GenericLoginPage />,
    },
    {
      path: '/',
      element: !user ? <IntraverseLandingHome /> : <Welcome />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: user ? <Navigate to="/404" /> : <Navigate to="/login" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}

function ProtectedRoute({ children, user, token, userId, role }) {
  if ((user === '' && token === '' && userId === '' && role === '') || role !== SUPERADMIN_ROLE) {
    return <Navigate to="/" replace />;
  }
  return children;
}
ProtectedRoute.propTypes = {
  children: PropTypes.any,
  user: PropTypes.any,
  token: PropTypes.any,
  userId: PropTypes.any,
  role: PropTypes.any,
};
function UserConsoleRoute({ children, user, token, userId, role }) {
  if (user === '' && token === '' && userId === '' && role === '') {
    return <Navigate to="/" replace />;
  }
  return children;
}
UserConsoleRoute.propTypes = {
  children: PropTypes.any,
  user: PropTypes.any,
  token: PropTypes.any,
  userId: PropTypes.any,
  role: PropTypes.any,
};

function RedirectIfLoggedIn({ children, user, token, userId, role }) {
  if (user !== '' && token !== '' && userId !== '') {
    if (role === SUPERADMIN_ROLE) {
      return <Navigate to="/call-to-action" replace />;
    }
    return <Navigate to="/" replace />;
  }

  return children;
}
RedirectIfLoggedIn.propTypes = {
  children: PropTypes.any,
  user: PropTypes.any,
  token: PropTypes.any,
  userId: PropTypes.any,
  role: PropTypes.any,
};
