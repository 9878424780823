import axios from 'axios';
import { store } from '../redux/Store';
import { refreshAccessToken } from '../redux/actions/UserActions';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_URI,
});

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const { token } = state.user;

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      const state = store.getState();
      originalRequest._retry = true;
      await store.dispatch(refreshAccessToken(state.user.userId));

      const newState = store.getState();
      const newToken = newState.user.token;

      axiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`;
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
