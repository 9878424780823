import React from 'react';
import styled from '@emotion/styled';

const Title = ({ text }) => {
  return <PageTitle>{text}</PageTitle>;
};
export default Title;

const PageTitle = styled.div`
  font-size: 2.5em;
  color: white;
  font-family: 'Signika Negative', sans-serif;
  letter-spacing: 0.05em;
  font-style: bold;
  font-weight: 600;

  @media (max-width: 960px) {
    font-size: 1.5em;
  }
`;
