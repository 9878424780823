import React from 'react';
import styled from '@emotion/styled';


const FooterContainer = () => {
  return (
    <ContainerFooter>
      <PrivacyLine>
        © 2023 TerraZero Technologies, Intraverse, and any associated logos are trademarks, service marks, and/or
        registered trademarks of TerraZero Technologies.
      </PrivacyLine>
      <FooterMenu>
        <FooterItems>
          <a
            style={{ textDecoration: 'none' }}
            href="https://terrazero.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <FooterItem>privacy notice</FooterItem>
          </a>
          <a
            style={{ textDecoration: 'none' }}
            href="https://terrazero.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <FooterItem>Terms of service</FooterItem>
          </a>
          <FooterItem>cookie preferences</FooterItem>
        </FooterItems>
      </FooterMenu>
    </ContainerFooter>
  );
};

const FooterMenu = styled.div`
  display: flex;
  align-items: center;
`;
const FooterItems = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding-bottom: 1rem;
  padding-top: 0rem;

  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

const FooterItem = styled.div`
  color: #fbfcff;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  justify-items: center;
  margin-right: 50px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 0.7rem;
    margin: 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 0.9rem;
    margin: 10px;
  }
`;


const PrivacyLine = styled.p`
  color: #ced1d9;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0.7rem;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 0rem 0.5rem;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px;
    margin: 0rem 2rem;
    width: 80%;
  }
`;

const ContainerFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding: 25px 30px;

  @media (max-width: 768px) {
    padding: 5px 5px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 1rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 20px 5px;
  }
`;

export default FooterContainer;
