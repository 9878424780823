import React from 'react';
import styled from '@emotion/styled';
import DiamondBackground from '../intraverseLandingPage/IntraverseHomeImages/Diamond_Checkered_Background.png';
import UserPortalHeader from '../userPortalIntraverse/UserPortalHeader';
import UserPortalGeneralProfile from '../userPortalIntraverse/UserPortalGeneralProfile';
import WalletPage from './WalletPage';

function UserConsoleWallet() {
  
  return (
    <UserConsoleContainer>
      <WalletPage />
    </UserConsoleContainer>
  );
}
export default UserConsoleWallet;

const UserConsoleContainer = styled.div`
  background-image: url(${DiamondBackground});
  background-color: rgba(32, 23, 41, 1);
  background-size: cover;
  background-position: center;
  background-size: 350px auto;
  /* height: 100vh; */
  width: 100vw;
  z-index: -500;
  /* @media (min-width: 768px) {
    height: 100%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 100vh;
  } */
`;
