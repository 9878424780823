const defaultState = {
  merchantName: '',
  merchantId: '',
  merchantAddress: {},
  merchantBusinessType: '',
  stripeClientId: '',
};

const MerchantReducer = (state, action) => {
  switch (action.type) {
    case 'MERCHANT_SAVE': {
      return {
        ...state,
        merchantName: action.payload.merchantName,
        merchantId: action.payload.merchantId,
        merchantAddress: action.payload.merchantAddress,
        merchantBusinessType: action.payload.merchantBusinessType,
        stripeClientId: action.payload.stripeClientId,
      };
    }

    case 'UPDATE_MERCHANT_STRIPE_ID': {
      return {
        ...state,
        stripeClientId: action.payload.stripeClientId,
      };
    }

    case 'DELETE_MERCHANT': {
      return defaultState;
    }

    default:
      if (state === undefined) {
        return defaultState;
      }
      return state;
  }
};

export default MerchantReducer;
