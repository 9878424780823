/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
// import calIcon from '../userPortalImages/Calendar.png';
import 'react-datepicker/dist/react-datepicker.css';
import X from '../../../assets/intraverse/X.png';
import { ReactComponent as GreenFeatureCheckmark } from '../../intraverseLandingPage/IntraverseHomeImages/GreenFeatureCheckmark.svg';
import { ReactComponent as RedCross } from '../../intraverseLandingPage/IntraverseHomeImages/redCross.svg';
import {
  getUserProfileData,
  UpdateUserEmail,
  ConfirmPasswordReset,
  currentPasswordChecker,
  UpdateUserBirthday,
  UpdateUserProfileData,
} from '../../../sections/auth/api/Controller';
import { updateEmail } from '../../../redux/actions/UserActions';
import { validateUsername } from '../../../utils/isValidEmail';

export function EditContentModal({
  handleClose,
  setCurrentBirthday,
  open,
  userEmail,
  isEmail,
  isPassword,
  isBirthday,
  isUserData,
}) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.user?.token);
  const userId = useSelector((state) => state?.user?.userId);

  // Email states
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');

  // Error message state
  const [confirmMessage, setConfirmMessage] = useState('');

  // User Details states
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [username, setUsername] = useState('');
  const [currentUsername, setCurrentUsername] = useState('');

  // Birthday states
  const [birthday, setBirthday] = useState(null);
  const [birthdayCounter, setBirthdayCounter] = useState(null);
  const [birthdaySupport, setBirthdaySupport] = useState(false);

  // Password states
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  // Password checker states
  const [lengthError, setLengthError] = useState(true);
  const [uppercaseError, setUppercaseError] = useState(true);
  const [numberError, setNumbersError] = useState(true);
  const [specialCharError, setSpecialCharError] = useState(true);

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleChangeEmail = async () => {
    // console.log('email changed', token);
    const newEmail = emailConfirm;
    console.log(emailConfirm);

    const result = await UpdateUserEmail(token, newEmail);
    if (result.success) {
      console.log('API call successful:', result.message);
      setConfirmMessage(`Email Succesfully Updated!`);
      dispatch(updateEmail(emailConfirm));
      setTimeout(() => handleClose(), 2000);
    } else {
      // const errorArray = result.message.error;
      // console.error('API call failed:', errorArray[0].msg);
      // setConfirmMessage(`Error: ${errorArray[0].msg}, Please try again or reach out to Support`);
      setConfirmMessage(`Error: Email already in use, Please try again or reach out to support`);
    }
  };

  const handleChangePassword = async () => {
    // console.log('password changed');
    const passwordCheck = await currentPasswordChecker(currentPassword, token, userId);
    // console.log(currentPassword, 'passwordCheck', passwordCheck);
    if (passwordCheck.status === 200) {
      const result = await ConfirmPasswordReset(token, newPassword);
      if (result.success) {
        // console.log('API call successful:', result.message);
        setConfirmMessage(`Password Succesfully Updated!`);
        setTimeout(() => handleClose(), 2000);
      } else {
        const errorArray = result;
        // console.error('API call failed:', errorArray);
        setConfirmMessage(`Error: ${errorArray}, Please try again or reach out to Support`);
      }
    } else {
      setConfirmMessage(`Error: Current password does not match our records, please try again or reset your password.`);
    }
  };

  const handleChangeBirthday = async () => {
    // console.log('birthday changed');
    const result = await UpdateUserBirthday(token, birthday, userId);
    // console.log('Birthday going to api', birthday);
    if (result.status === 200) {
      setConfirmMessage('Birthday updated successfully!');
      setCurrentBirthday(birthday);
      setTimeout(() => handleClose(), 3000);
    } else {
      setConfirmMessage(``);
      setBirthdaySupport(true);
    }
  };

  const handleChangeDetails = async () => {
    if (username === currentUsername) {
      const result = await UpdateUserProfileData(token, firstName, lastName, country);

      if (result.status === 200) {
        setConfirmMessage('Details updated successfully!');
        setTimeout(() => handleClose(), 3000);
      } else {
        setConfirmMessage(`Error: Display name already in use.`);
      }
    } else {
      const result = await UpdateUserProfileData(token, firstName, lastName, country, username);

      if (result.status === 200) {
        setConfirmMessage('Details updated successfully!');
        setTimeout(() => handleClose(), 3000);
      } else {
        setConfirmMessage(`Error: Display name already in use.`);
      }
    }
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    if (!validateUsername(username)) {
      setConfirmMessage('Error: Please choose a valid username');
    } else {
      setConfirmMessage('');
    }
  };

  const handlePageLoad = async () => {
    const userData = await getUserProfileData(token, userId);
    setBirthdaySupport(false);

    const { userBirthday, birthdayUpdateCount, displayName, firstName, lastName, userCountry } = userData.data;
    console.log('userBirthday', userBirthday);
    const changesLeft = 3 - birthdayUpdateCount;
    setBirthdayCounter(changesLeft);

    setUsername(displayName);
    setCurrentUsername(displayName);
    setFirstName(firstName);
    setLastName(lastName);
    setCountry(userCountry);

    if (userBirthday !== null && userBirthday !== undefined) {
      const apiDate = new Date(userBirthday);
      // apiDate.setDate(apiDate.getDate() + 1);
      console.log('new Date', apiDate);

      const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(apiDate);

      setBirthday(apiDate);
      console.log('formatted', formattedDate);
    } else {
      setBirthday(null);
    }
  };

  const handlePasswordChecking = (event) => {
    const minLengthRegex = /^.{8,}$/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    // eslint-disable-next-line no-useless-escape
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    setNewPassword(event.target.value);

    if (minLengthRegex.test(event.target.value)) {
      setLengthError(false);
    } else {
      setLengthError(true);
    }
    if (uppercaseRegex.test(event.target.value)) {
      setUppercaseError(false);
    } else {
      setUppercaseError(true);
    }
    if (numberRegex.test(event.target.value)) {
      setNumbersError(false);
    } else {
      setNumbersError(true);
    }
    if (specialCharacterRegex.test(event.target.value)) {
      setSpecialCharError(false);
    } else {
      setSpecialCharError(true);
    }
  };

  useEffect(() => {
    handlePageLoad();
  }, []);

  // eslint-disable-next-line react/prop-types
  const MyContainer = ({ className, children }) => (
    <div style={{ marginBottom: '-60px', width: '100%' }}>
      <BirthdayContainer className={className}>
        <div style={{ zIndex: 9999 }}>{children}</div>
      </BirthdayContainer>
    </div>
  );

  return (
    <div>
      {open && (
        <ModalContainer>
          <ModalContent>
            <TitleTextContent>
              {isEmail && 'Update Email'}
              {isPassword && 'Update Password'}
              {isBirthday && !birthdaySupport
                ? "When's Your Birthday"
                : isBirthday && birthdaySupport
                ? 'Contact Support'
                : null}
              {isUserData && 'Update Details'}
            </TitleTextContent>
            <SmallSubtitle>
              {isEmail && 'Change the email you use to login and receive Intraverse news.'}
              {isPassword && 'Please ensure your new password meets our criteria.'}
              {isUserData && 'Edit your profile details below.'}
            </SmallSubtitle>
            {isBirthday && !birthdaySupport ? (
              <SmallSubtitle style={{ margin: '1rem 3rem' }}>
                Add your birthday to receive a gift on your special day! You can change this information{' '}
                <span> {birthdayCounter} more times</span>.
              </SmallSubtitle>
            ) : isBirthday && birthdaySupport ? (
              <SmallSubtitle>
                You have hit the limit for changing your birthday. Please reach out to our support team to assist you
                with updating this information.
              </SmallSubtitle>
            ) : null}
            <ConfirmResponseMessage
              style={{ color: `${confirmMessage.indexOf('Error') !== -1 ? '#DD42CE' : 'green'}` }}
            >
              {confirmMessage}
            </ConfirmResponseMessage>
            {isEmail && (
              <>
                <LabelText> Current Email</LabelText>
                <Inputcontainer>
                  <Input type="email" placeholder="Current Email" readonly value={userEmail} />
                </Inputcontainer>
                <LabelText> New Email</LabelText>
                <Inputcontainer>
                  <Input
                    type="email"
                    placeholder="New Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Inputcontainer>
                <Inputcontainer
                  style={{ cursor: 'default', marginTop: '1rem', padding: '0.2rem 1rem', justifyContent: 'flex-start' }}
                >
                  <TextContent>
                    {validateEmail(email) !== true ? (
                      <>
                        {' '}
                        <RedCross style={{ marginRight: '5px', verticalAlign: 'bottom' }} /> Invalid Email
                      </>
                    ) : (
                      <>
                        {' '}
                        <GreenFeatureCheckmark height={'15px'} style={{ verticalAlign: 'bottom' }} />
                        {'  '} Valid Email
                      </>
                    )}
                  </TextContent>
                </Inputcontainer>
                <LabelText> Confirm New Email</LabelText>
                <Inputcontainer>
                  <Input
                    type="email"
                    placeholder="Confirm New Email"
                    value={emailConfirm}
                    onChange={(e) => setEmailConfirm(e.target.value)}
                  />
                </Inputcontainer>

                <Inputcontainer
                  style={{ cursor: 'default', marginTop: '1rem', padding: '0.2rem 1rem', justifyContent: 'flex-start' }}
                >
                  <TextContent>
                    {email === emailConfirm ? (
                      <>
                        <GreenFeatureCheckmark height={'15px'} style={{ verticalAlign: 'bottom' }} /> Emails Match!
                      </>
                    ) : (
                      <>
                        <RedCross style={{ marginRight: '5px', verticalAlign: 'bottom' }} /> Emails don't match
                      </>
                    )}
                  </TextContent>
                </Inputcontainer>
              </>
            )}
            {isPassword && (
              <>
                <LabelText> Current Password</LabelText>
                <Inputcontainer>
                  <Input
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    value={currentPassword}
                    type="password"
                    placeholder="Current Password"
                  />
                </Inputcontainer>
                <LabelText> New Password</LabelText>
                <Inputcontainer>
                  <Input
                    type="text"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(event) => {
                      handlePasswordChecking(event);
                    }}
                  />
                </Inputcontainer>
                {/* <Inputcontainer
                  style={{ cursor: 'default', marginTop: '1rem', padding: '0.2rem 1rem', justifyContent: 'flex-start' }}
                > */}
                <PasswordValidBox>
                  <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      {lengthError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      8 characters long
                    </p>
                    <p>
                      {uppercaseError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One uppercase
                    </p>
                  </PasswordValidText>
                  <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      {' '}
                      {numberError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One number
                    </p>
                    <p>
                      {specialCharError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One special character
                    </p>
                  </PasswordValidText>
                </PasswordValidBox>
                {/* </Inputcontainer> */}
                <LabelText> Confirm New Password</LabelText>
                <Inputcontainer>
                  <Input
                    type="text"
                    placeholder="Confirm New password"
                    value={newPasswordConfirm}
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                  />
                </Inputcontainer>
                <Inputcontainer
                  style={{ cursor: 'default', marginTop: '1rem', padding: '0.2rem 1rem', justifyContent: 'flex-start' }}
                >
                  <TextContent>
                    {newPassword === newPasswordConfirm ? (
                      <>
                        <GreenFeatureCheckmark height={'15px'} style={{ verticalAlign: 'bottom' }} /> Passwords Match!
                      </>
                    ) : (
                      <>
                        <RedCross style={{ marginRight: '5px', verticalAlign: 'bottom' }} /> Passwords do not match
                      </>
                    )}
                  </TextContent>
                </Inputcontainer>
              </>
            )}
            <ButtonDiv>
              {isEmail && (
                <PinkButton
                  disabled={
                    (validateEmail(email) !== true && validateEmail(emailConfirm) !== true) || email !== emailConfirm
                  }
                  onClick={() => handleChangeEmail()}
                >
                  <p>Update My Email</p>
                </PinkButton>
              )}
              {isPassword && (
                <PinkButton
                  disabled={
                    lengthError ||
                    uppercaseError ||
                    numberError ||
                    specialCharError ||
                    newPassword !== newPasswordConfirm
                  }
                  onClick={() => handleChangePassword()}
                >
                  <p>Update My Password</p>
                </PinkButton>
              )}
              {isBirthday && !birthdaySupport ? (
                <BirthdayContainer>
                  <StyledBirthdaySelector>
                    <DateInput
                      popperPlacement="top"
                      calendarContainer={MyContainer}
                      selected={birthday}
                      onChange={(date) => setBirthday(date)}
                      dateFormat="MM/dd/yyyy"
                      maxDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      fixedHeight
                      showTimeInput={false}
                      placeholderText="Click to select a date"
                    />
                  </StyledBirthdaySelector>
                  <PinkButton style={{ width: '100%' }} onClick={() => handleChangeBirthday()}>
                    <p>Add My Birthday</p>
                  </PinkButton>
                </BirthdayContainer>
              ) : isBirthday && birthdaySupport ? (
                <BirthdayContainer>
                  <Inputcontainer>
                    <Input style={{ color: 'white' }} type="email" value="hello@terrazero.com" readonly />
                  </Inputcontainer>
                  <PinkButton
                    style={{ width: '100%' }}
                    onClick={() => {
                      window.location = 'mailto:hello@terrazero.com';
                    }}
                  >
                    <p>Contact Support</p>
                  </PinkButton>
                </BirthdayContainer>
              ) : null}
              {isUserData && (
                <DetailModal>
                  <LabelText>Username *</LabelText>
                  <Inputcontainer>
                    <Input type="text" value={username} onChange={handleUsernameChange} />
                  </Inputcontainer>
                  <DetailDoubleRow>
                    <DetailDoubleColumn>
                      <LabelText> First Name *</LabelText>
                      <Inputcontainer>
                        <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                      </Inputcontainer>
                    </DetailDoubleColumn>
                    <DetailDoubleColumn>
                      <LabelText> Last Name *</LabelText>
                      <Inputcontainer>
                        <Input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </Inputcontainer>
                    </DetailDoubleColumn>
                  </DetailDoubleRow>
                  <LabelText>Country</LabelText>
                  <Inputcontainer>
                    <InputSelect id="country" value={country} onChange={(e) => setCountry(e.target.value)}>
                      <InputSelectOption value="">Select a country</InputSelectOption>
                      <InputSelectOption value="United States of America">United States of America</InputSelectOption>
                      <InputSelectOption value="Canada">Canada</InputSelectOption>
                    </InputSelect>
                  </Inputcontainer>
                  <PinkButton
                    disabled={!validateUsername(username) || firstName === '' || lastName === ''}
                    onClick={() => handleChangeDetails()}
                  >
                    <p>Update My Details</p>
                  </PinkButton>
                </DetailModal>
              )}
              <CloseIcon src={X} alt="Close" onClick={() => handleClose()} close />
            </ButtonDiv>
          </ModalContent>
        </ModalContainer>
      )}
    </div>
  );
}

EditContentModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  // currentBirthday: PropTypes.string,
  setCurrentBirthday: PropTypes.func,
  userEmail: PropTypes.string,
  isEmail: PropTypes.bool,
  isPassword: PropTypes.bool,
  isBirthday: PropTypes.bool,
  isUserData: PropTypes.bool,
};

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 126, 126, 0.3);
  opacity: 1;
  visibility: visible;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
`;
const DetailModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
const DetailDoubleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;
const DetailDoubleColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0rem 2rem;
  width: 600px;
  box-sizing: border-box;
  z-index: 1;
  border-radius: 10px;
  border: 2px solid #24242f;
  background: #1b1b23;
  overflow: auto;

  @media (max-width: 960px) {
    width: 80%;
    padding: 0rem 1rem;
  }

  @media (max-height: 742px) {
    overflow: auto;
    max-height: calc(100% - 90px);
    top: 56%;
  }

  @media (max-height: 412px) {
    overflow: auto;
    max-height: calc(100% - 30px);
    top: 60%;
  }
`;
const TitleTextContent = styled.h1`
  color: #beaeff;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin: 1rem 0rem;
  text-align: center;

  @media (max-width: 425px) {
    font-size: 24px;
    margin: 5px 0;
  }
`;
const TextContent = styled.p`
  color: #ced1d9;
  text-align: left;
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  margin: 5px;
`;

const SmallSubtitle = styled.p`
  text-align: center;
  color: #ced1d9;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0rem;
  margin-bottom: 5px;

  & > span {
    color: #ffe664;
    font-weight: 700;
  }

  @media (max-width: 425px) {
    font-size: 14px;
    margin: 5px 0;
  }
`;

const ConfirmResponseMessage = styled.p`
  text-align: center;
  color: green;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0rem;
  margin-bottom: 5px;

  & > span {
    color: #beaeff;
    font-weight: 700;
  }
`;
const LabelText = styled.p`
  text-align: left;
  color: #ced1d9;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 5px;

  @media (max-width: 425px) {
    font-size: 14px;
    margin-top: 5px;
  }

  @media (max-height: 742px) {
    margin-top: 5px;
  }
`;

const Input = styled.input`
  flex: 1;
  border: none;
  background: none;
  width: 90%;
  height: 35px;
  padding-left: 10px;
  color: white;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(35, 34, 48) inset !important;
    -webkit-text-fill-color: white !important;
  }
`;
const InputSelect = styled.select`
  flex: 1;
  border: none;
  background: none;
  width: 90%;
  height: 35px;
  padding-left: 10px;
  color: white;
`;
const InputSelectOption = styled.option`
  flex: 1;
  border: none;
  background: #1b1b23;
  width: 90%;
  height: 35px;
  padding-left: 10px;
  color: white;
`;

const Inputcontainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: 5px;
  justify-content: center;
  background: rgba(190, 174, 255, 0.1);
  cursor: pointer;
  height: 35px;
  align-items: center;
  border: 1px solid #504c5c;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
`;

const ButtonDiv = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: auto;
  cursor: pointer;

  @media (max-width: 960px) {
    height: 15px;
  }
`;

const PinkButton = styled.button`
  display: inline-flex;
  padding: 10px 45px;
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  background: ${(props) => (props.disabled ? 'grey' : 'linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%)')};
  cursor: pointer;
  margin: 1rem 0rem 2rem 0rem;

  & p {
    color: #fbfcff;
    font-family: 'Barlow', sans-serif;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.8px;
  }
`;

const PasswordValidBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  background: #2b2a38;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  padding: 0.2rem 0 0.5rem 1rem;
  margin-top: 0.5rem;

  @media (max-width: 320px) {
    /* width: 100vw; */
    padding: 5px;
    border-radius: 0px;
    margin-top: 5px;
    flex-direction: column;
  }
`;
const PasswordValidText = styled.div`
  flex-basis: 100%;
  & > p {
    color: #fbfcff;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
    margin-left: 5px;

    @media (max-width: 425px) {
      font-size: 12px;
    }
  }
  @media (max-width: 320px) {
    width: 120%;
  }
`;

const StyledBirthdaySelector = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4rem 0rem 0rem 0rem;
`;

const BirthdayContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DateInput = styled(DatePicker)`
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #504c5c;
  background-color: transparent;
  color: white;
  width: 100%;
`;
