import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import JumpInButton from './IntraverseHomeImages/Enter_CTA_Banner.png';
import JumpInButtonMobile from './IntraverseHomeImages/CTA-mobile-Banner.png';

const LandingTicker = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const navigate = useNavigate();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    const aspectRatio = innerWidth / innerHeight;
    return { innerWidth, innerHeight, aspectRatio };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (
    <TickerContainer>
      <TickerBorder>
        <TickerTextContainer>
          <TickerTitleText>Stay tuned for our upcoming releases</TickerTitleText>
        </TickerTextContainer>
        <TickerItemContainer>
          <TickerItem>Upcoming activations from your favorite artists</TickerItem>
          <TickerItem>Intraverse Live! Public Alpha release</TickerItem>
          <TickerItem>Play Intraverse: Native App</TickerItem>
          <TickerItem>Exclusive Seasonal Events</TickerItem>
          <TickerItem>+ More</TickerItem>
          <TickerItem>Upcoming activations from your favorite artists</TickerItem>
          <TickerItem>Intraverse Live! Public Alpha release</TickerItem>
          <TickerItem>Play Intraverse: Native App</TickerItem>
          <TickerItem>Exclusive Seasonal Events</TickerItem>
        </TickerItemContainer>
      </TickerBorder>
      <TickerButton onClick={() => navigate('/login')}>
        {windowSize.innerWidth > 700 ? (
            <img src={JumpInButton} alt="Jump in button" />
        ) : (
            <img src={JumpInButtonMobile} alt="Jump in button" />
        )}
      </TickerButton>
    </TickerContainer>
  );
};

export default LandingTicker;

const TickerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(27, 27, 35, 1);
  margin-top: -2px;
  margin-bottom: -2px;
`;

const TickerBorder = styled.div`
  width: 96.7%;
  margin: 0 1.65%;
  padding: 2rem 0;
  display: flex;
  align-items: left;
  flex-direction: column;
  border-bottom: 3px solid #3c384e;
  overflow: hidden;
`;
const TickerTextContainer = styled.div`
  width: 100%;
  margin: 1rem;
  display: flex;
  align-items: left;
  flex-direction: column;

  @media (max-width: 768px) {
    margin: 1rem 0 2rem 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin: 1rem 0 2rem 0;
  }
`;

const TickerItem = styled.div`
  border-radius: 100px;
  background: rgba(190, 174, 255, 0.2);
  display: flex;
  padding: 20px 50px;
  margin: 0 20px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  white-space: nowrap;

  color: #fbfcff;
  font-size: 22px;
  font-weight: 600;
  font-family: 'Barlow', sans-serif;

  @media (max-width: 768px) {
    font-size: 16px;
    margin: 0 10px;
    padding: 15px 30px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 18px;
    padding: 15px 30px;
  }
`;

const scroll = keyframes`
  0% {
    transform: translateX(80%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const TickerItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  animation: ${scroll} 20s linear infinite;
  margin: 0 0 1rem 0;

  @media (max-width: 768px) {
    animation: ${scroll} 10s linear infinite;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    animation: ${scroll} 15s linear infinite;
  }
`;

const TickerButton = styled.button`
  cursor: pointer;
  height: auto;
  width: 100%;
  border: none;
  padding: 0 2rem;
  background: none;
`;
const JumpInButtonImg = styled.button`
  cursor: pointer;
  height: auto;
  width: 100%;
  border: none;
  padding: 0 2rem;
  background: none;
`;

const TickerTitleText = styled.p`
  color: #beaeff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 2px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;
