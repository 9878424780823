import { useState } from 'react'
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
// import { deleteMerchant } from '../../../api/MerchantsController';
import Alert from "../../../components/common/Alert/Alert";
import Iconify from '../../../components/Iconify';  

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

MerchantListToolbar.propTypes = {
  selected: PropTypes.any,
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function MerchantListToolbar({ selected, numSelected, filterName, onFilterName }) {
  // const token = useSelector((state) => state?.user?.token);
  const [deleteMerchantMsg, setDeleteMerchantMsg] = useState(null);
  const [deleteMerchantStatus, setDeleteMerchantStatus] = useState(null);

  async function myAsyncOperation(merchantId) {
      // TODO
      // const result = await deleteMerchant(token, merchantId);
      // setDeleteMerchantStatus(result.status);
      // if(result.status === 200){
      //   let arrNameDel = [];
      //   selected.forEach(element => {
      //       arrNameDel = selected.map(element => element.firstName);
      //   });
      //   setDeleteMerchantMsg(`${arrNameDel} deleted successfully.`);
      // }else{
      //   setDeleteMerchantMsg(result.message);
      // }
  }

  async function handleAsyncClick() {
    alert('TODO -> Remove collaborator');
    try {
      const promises = [];
      for (let i = 0; i < selected.length; i += 1) {
        promises.push(myAsyncOperation(selected[i].id, selected[i].status, '', selected[i].firstName));
      }
      await Promise.all(promises);
    } catch (errorBlock) {
      console.error(errorBlock);
    }
  }

  if (deleteMerchantStatus === 404 || deleteMerchantStatus === 200 || 
    deleteMerchantStatus === 500 || deleteMerchantStatus === 400) {
    setTimeout(() => {
      setDeleteMerchantStatus(null);
      setDeleteMerchantMsg(null);
      window.location.reload();
    }, 3000);
  }

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search user..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {
        numSelected > 0 && (
          <div>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  handleAsyncClick();
                }}
              >
                <Iconify icon="eva:trash-2-fill" />
              </IconButton>
            </Tooltip>
            
            {deleteMerchantStatus === 404 && (
              <Alert type="error" message='No data found for merchant or already deleted.'/>
            )}
            {deleteMerchantStatus === 200 && (
              <Alert type="success" message={deleteMerchantMsg} />
            )}
            {deleteMerchantStatus === 400 || deleteMerchantStatus === 500 && (
              <Alert type="error" message={deleteMerchantMsg}/>
            )}
          </div>
        )
      }
    </RootStyle>
  );
}
